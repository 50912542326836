import React, { useEffect, useState } from 'react'
import Layout from '../../layouts/Layout'
import { Box, CircularProgress, TablePaginationProps, Typography } from '@material-ui/core'
import { WithApolloClient, withApollo } from 'react-apollo'
import { adminGetDemands_adminGetDemands } from '../../gql/__generated__'
import adminGetDemandsQuery from '../../gql/queries/adminGetDemands'
import adminGetDemandsCountQuery from '../../gql/queries/adminGetDemandsCount'
import { GenericTable } from '../../components'
import { RouterLink } from '../../components'
import { adminGetTopicsCourses, adminGetTopicsCourses_getAllTranslatedCourses, adminGetTopicsCourses_getTranslatedTopics } from '../../gql/__generated__'
import adminGetTopicsCoursesQuery from '../../gql/queries/adminGetTopicsCourses'
import Link from '@material-ui/core/Link'

type DemandsListPageProps = WithApolloClient<{}>

const DemandsListPage = ({ client }: DemandsListPageProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [demands, setDemands] = useState<adminGetDemands_adminGetDemands[]>([])
  const [numberOfDemands, setNumberOfDemands] = useState<number>(0)
  const [courses, setCourses] = useState<adminGetTopicsCourses_getAllTranslatedCourses[]>([])
  const [topics, setTopics] = useState<adminGetTopicsCourses_getTranslatedTopics[]>([])

    const getDemands = async (first: number, offset = 0) => {
    const result = await client.query({
      query: adminGetDemandsQuery,
      variables: {
        pageInfo: {
          first,
          offset
        }
      }
    })
    const resultCnt = await client.query({
      query: adminGetDemandsCountQuery,
      variables: {
        pageInfo: {
          first: 9999,
          offset
        }
      }
    })

    setNumberOfDemands(resultCnt.data.adminGetDemands.length)
    return result.data.adminGetDemands
  }

  const handleChangeOfRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    setIsLoaded(false)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: 0 })
    await onUpdate(rowsPerPage)
  }

  const handleChangePage = async (events: any, page: number) => {
    setIsLoaded(false)
    setPagination({ ...pagination, page: page })
    await onUpdate(pagination.rowsPerPage, page)
  }

  const onUpdate = async (rowsPerPage: number, page = 0) => {
    const demands = await getDemands(rowsPerPage, page * rowsPerPage)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: page })
    setDemands(demands)
    setIsLoaded(true)
  }

  const [pagination, setPagination] = useState<TablePaginationProps>({
    count: 1000,
    page: 0,
    rowsPerPage: 100,
    rowsPerPageOptions: [30, 50, 100],
    onChangeRowsPerPage: handleChangeOfRowsPerPage,
    onChangePage: handleChangePage
  })

  useEffect(() => {
    onUpdate(pagination.rowsPerPage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.rowsPerPage])

  useEffect(() => {
    const fetchCoursesTopics = async () => {
      const result = await client.query<adminGetTopicsCourses>({
        query: adminGetTopicsCoursesQuery
      })
      setCourses(result.data.getAllTranslatedCourses)
      setTopics(result.data.getTranslatedTopics)
    }
    fetchCoursesTopics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const packageLink = (item: adminGetDemands_adminGetDemands) => {
    if (item.packageId) return <RouterLink passHref to={`package/${item.packageId}`}><Link>{item.packageId}</Link></RouterLink>
    return null
  }

  const formatTopic = (topicId: string | null) => {
    if(!topicId) return '-'
    const topic = topics?.find(topic => topic.id === topicId)
    return topic ? topic.name : topicId
  }

  const formatCourse = (courseId: string | null) => {
    if(!courseId) return '-'
    const course = courses?.find(course => course.id === courseId)
    return course ? course.name : courseId
  }

  const columns = [
    { Header: 'ID', accessor: 'id', link: (item: adminGetDemands_adminGetDemands) => `demands/${item.id}`},
    { Header: 'User', accessor: 'user.overrideName', link: (item: adminGetDemands_adminGetDemands) => item.user?.students?.[0].id ? `profile/${item.user?.students?.[0].id}` : null },
    { Header: 'Subject', accessor: (item: adminGetDemands_adminGetDemands) => <Typography variant='body2' style={{ fontSize: '0.75rem' }}>
      Course: {formatCourse(item.courseId)} <br />
      Topic: {formatTopic(item.topicId)} <br />      
      Package: {packageLink(item)}
    </Typography> },
    { Header: 'Tutor', accessor: 'tutorId', link: (item: adminGetDemands_adminGetDemands) => item.tutorId ? `profile/${item.tutorId}` : null },
    { Header: 'Note', accessor: 'note'},
    { Header: 'Status', accessor: 'status'},
    { Header: 'Created At', accessor: 'createdAt'},
    { Header: 'Updated At', accessor: 'updatedAt'}
  ]

  return (
    <Layout>
      <Typography variant='h4'>Demands by students</Typography>
      {!isLoaded ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <Box display='flex' flexDirection='column' style={{ gap: '0.5rem'}} mt={2}>
          <Typography variant='body1'>Demand Status Explained</Typography>
          <Typography variant='body2' style={{ fontSize: '0.75rem' }}>            
            - <code>OneTutor</code> - Demand addressed directly to one tutor<br />
            - <code>New</code> - New demand created by student, not processed by system yet<br />
            - <code>TutorsSent</code> - Demand was processed and sent to tutors<br />
            - <code>RequestedAgain</code> - Admin requested other round of sending to tutors, new tutors will be addressed. State will switch to <code>TutorsSent</code> after.<br />
            - <code>Archived</code> - Admin archived the demand and it will be ignored / hidden from tutors. Not shown in the list below.<br />

          </Typography>
          <Typography variant='body1'>Current Active Demands</Typography>
          <GenericTable
            data={demands}
            pagination={pagination}
            columns={columns}
            numberOfItems={numberOfDemands}
          />
        </Box>
      )}
    </Layout>
  )
}

export default withApollo(DemandsListPage)