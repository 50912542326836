import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import adminGetTutorInviteQuery from '../gql/queries/adminGetTutorInvite'

import Layout from '../layouts/Layout'
import { GenericForm, RouterLink } from '../components'
import { removeTypenameFromData } from '../api/GraphQLUtils'
import NotificationService, { MessageType } from '../services/NotificationService'
import Button from '@material-ui/core/Button'
import tutorCreateStudent from '../gql/mutations/tutorCreateStudent'
import TutorInviteSchema from './formSchemas/TutorInviteSchema'

interface Data {
  adminGetTutor: any
}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    tutorId: string
  }>

const TutorInvitePage = ({ client, history, match }: Props) => {
  const getInitialValues = () => {
    return client
      .query<Data>({
        query: adminGetTutorInviteQuery,
        variables: { id: match.params.tutorId }
      })
      .then(({ data }) => removeTypenameFromData(data.adminGetTutor))
  }

  const [inviteUrl, setInviteUrl] = useState('')
  const handleSubmit = (values: any) => {
    return client
      .mutate({
        mutation: tutorCreateStudent,
        variables: {
          email: values.email,
          message: values.message,
          tutorId: values.id,
          tutorUserId: values.user.id,
          nameOverride: values.user.overrideName
        }
      })
      .then((data) => {
        NotificationService.show(MessageType.success, 'Saved successfully')
        setInviteUrl(`${process.env.REACT_APP_BASE_URL}invite/${data.data.invite.slug}/${data.data.invite.hash}`)

        return data
      })
  }

  const backLink = `/profile/${match.params.tutorId}`

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h1'>
        Invite student
        <RouterLink to={backLink}>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to tutor
          </Button>
        </RouterLink>
      </Typography>
      {inviteUrl ? (
        <div>
          <b>Invite link:</b>{' '}
          <a href={inviteUrl} target='_blank' rel='noreferrer'>
            {inviteUrl}
          </a>
        </div>
      ) : (
        <GenericForm getInitialValues={getInitialValues} schema={TutorInviteSchema} onSubmit={handleSubmit} />
      )}
    </Layout>
  )
}

export default withApollo(TutorInvitePage)
