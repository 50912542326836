import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { ApolloProvider } from 'react-apollo'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GraphQLClient } from './Api'
import LoginPage from './pages/LoginPage'
import OAuthGooglePage from './pages/OAuthGooglePage'
import TutorCreatePage from './pages/TutorCreatePage'
import TutorListPage from './pages/TutorListPage'
import TutorUpdatePage from './pages/TutorUpdatePage'
import TutorInvitePage from './pages/TutorInvitePage'
import VerifyUsersPage from './pages/VerifyUsersPage'
import theme from './Theme'
import StudentsListPage from './pages/StudentListPage'
import ProfileDetailPage from './pages/ProfileDetailPage'
import ProfileCalls from './pages/ProfileCalls'
import WalletDetailPage from './pages/WalletDetailPage'
import RatingsPage from './pages/RatingsPage'
import AwaitingVerificationListPage from './pages/AwaitingVerificationListPage'
import GenerateTokenPage from './pages/GenerateTokenPage'
import DashboardPage from './pages/DashboardPage'
import TopicsPage from './pages/topicsPage/TopicsPage'
import PreviewsPage from './pages/PreviewsPage'
import VoucherPage from './pages/VoucherPage'
import RatingDetailPage from './pages/RatingDetailPage'
import ConversationsListPage from './pages/ConversationsListPage'
import ConversationDetailPage from './pages/ConversationDetailPage'
import BookingsListPage from './pages/BookingsListPage'
import ContactsPage from './pages/ContactsPage'
import OnlineStatusHistoryPage from './pages/OnlineStatusHistoryPage'
import SubscriptionsPage from './pages/SubscriptionsPage'
import OnlineTutorsPage from './pages/OnlineTutorsPage'
import BanPage from './pages/BanPage'
import PackagesPage from './pages/PackagesPage'
import PackageUpdatePage from './pages/PackageUpdatePage'
import ReferralsPage from './pages/ReferralsPage'
import QuizPage from './pages/QuizzesPage'
import PayoutsPage from './pages/PayoutsPage'
import PackageCreatePage from './pages/PackageCreatePage'
import ShiftsPage from './pages/ShiftsPage'
import Cookie from 'js-cookie'
import 'react-big-calendar/lib/sass/styles.scss'
import GroupListPage from './pages/GroupListPage'
import GroupCreatePage from './pages/GroupCreatePage'
import GroupDetailPage from './pages/GroupDetailPage'
import GroupAddMemberPage from './pages/GroupAddMemberPage'
import CoursesPage from './pages/coursesPage/CoursesPage'
import CourseCreatePage from './pages/coursesPage/CourseCreatePage'
import CourseEditPage from './pages/coursesPage/CourseEditPage'
import CoursePackages from './pages/coursesPage/CoursePackages'
import ActionsPage from './pages/ActionsPage'
import ReservationsPage from './pages/ReservationsPage'
import AiConfigsPage from './pages/aiConfigsPage/AiConfigsPage'
import AiPricelistsPage from './pages/aiPricelistsPage/AiPricelistsPage'
import DemandsListPage from './pages/demandsPage/DemandsListPage'
import DemandDetailPage from './pages/demandsPage/DemandDetailPage'

const client = GraphQLClient()
const muiTheme = createMuiTheme(theme)
const routes = ['/login', '/_oauth/google']
class App extends Component {
  render() {
    if (!Cookie.get('admin_token') && !routes.includes(window.location.pathname)) {
      window.location.href = '/login'
      return
    }
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Switch>
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/' component={DashboardPage} />
            <Route exact path='/tutors' component={TutorListPage} />
            <Route exact path='/students' component={StudentsListPage} />
            <Route exact path='/tutor/create' component={TutorCreatePage} />
            <Route exact path='/tutor/:tutorId' component={TutorUpdatePage} />
            <Route exact path='/invite/:tutorId' component={TutorInvitePage} />
            <Route exact path='/profile/:profileId' component={ProfileDetailPage} />
            <Route exact path='/calls/:profileId' component={ProfileCalls} />
            <Route exact path='/verify-users' component={VerifyUsersPage} />
            <Route exact path='/awaiting-verification' component={AwaitingVerificationListPage} />
            <Route exact path='/_oauth/google' component={OAuthGooglePage} />
            <Route exact path='/wallet/:profileId' component={WalletDetailPage} />
            <Route exact path='/ratings/:profileId' component={RatingsPage} />
            <Route exact path='/awaiting-verification/:identifier' component={GenerateTokenPage} />
            <Route exact path='/topics' component={TopicsPage} />
            <Route exact path='/previews/:profileId' component={PreviewsPage} />
            <Route exact path='/voucher' component={VoucherPage} />
            <Route exact path='/rating/:ratingId' component={RatingDetailPage} />
            <Route exact path='/conversations/:profileId' component={ConversationsListPage} />
            <Route exact path='/bookings/:profileId' component={BookingsListPage} />
            <Route exact path='/conversation/:conversationId' component={ConversationDetailPage} />
            <Route exact path='/contacts/:profileId' component={ContactsPage} />
            <Route exact path='/status-history/:profileId' component={OnlineStatusHistoryPage} />
            <Route exact path='/subscriptions/:profileId' component={SubscriptionsPage} />
            <Route exact path='/tutors/online' component={OnlineTutorsPage} />
            <Route exact path='/ban/:profileId' component={BanPage} />
            <Route exact path='/packages/create' component={PackageCreatePage} />
            <Route exact path='/packages/:profileId' component={PackagesPage} />
            <Route exact path='/package/:packageId' component={PackageUpdatePage} />
            <Route exact path='/referrals/:profileId' component={ReferralsPage} />
            <Route exact path='/quizzes/:profileId' component={QuizPage} />
            <Route exact path='/payouts/:profileId' component={PayoutsPage} />
            <Route exact path='/shifts' component={ShiftsPage} />
            <Route exact path='/groups' component={GroupListPage} />
            <Route exact path='/groups/create' component={GroupCreatePage} />
            <Route exact path='/groups/:groupId' component={GroupDetailPage} />
            <Route exact path='/groups/:groupId/add' component={GroupAddMemberPage} />
            <Route exact path='/courses' component={CoursesPage} />
            <Route exact path='/courses/create/:topicL1/:topicL2' component={CourseCreatePage} />
            <Route exact path='/courses/edit/:courseId' component={CourseEditPage} />
            <Route exact path='/courses/packages/:courseId' component={CoursePackages} />
            <Route exact path='/actions' component={ActionsPage} />
            <Route exact path='/reservations' component={ReservationsPage} />
            <Route exact path='/ai-configs' component={AiConfigsPage} />
            <Route exact path='/ai-pricelists' component={AiPricelistsPage} />
            <Route exact path='/demands' component={DemandsListPage} />
            <Route exact path='/demands/:demandId' component={DemandDetailPage} />
          </Switch>
        </MuiThemeProvider>
      </ApolloProvider>
    )
  }
}

export default App
