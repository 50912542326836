import React from 'react'
import { WithApolloClient, withApollo } from 'react-apollo'
import Layout from '../layouts/Layout'
import UsersStats from '../components/UsersStats'
import CallsStats from '../components/CallsStats'
import InviteStats from '../components/InviteStats'
import PaymentStats from '../components/PaymentStats'

type Props = WithApolloClient<{}>

class DashboardPage extends React.Component<Props> {
  render() {
    return (
      <Layout>
        <UsersStats client={this.props.client} />
        <CallsStats client={this.props.client} />
        <InviteStats client={this.props.client} />
        <PaymentStats client={this.props.client} />
      </Layout>
    )
  }
}

export default withApollo(DashboardPage)
