import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import { GenericForm, GenericTable } from '../../components'
import { adminGetTopics_adminGetTopics, WebLanguage } from '../../gql/__generated__'
import Layout from '../../layouts/Layout'
import { useTopicsPage } from './useTopicsPageHook'
import { getTopicFormSchema, getTranslation } from './util'

export interface FormSubmitValues {
  id?: string
  name: string
  parentId: string
  slug: string
  enName: string
  enHeadline: string
  enSubheadline: string
  csName: string
  csHeadline: string
  csSubheadline: string
}

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

export type Operation = 'create' | 'update'

const TopicsPage = ({ client }: Props) => {
  const { topics, initialValues, operation, onEdit, handleSubmit } = useTopicsPage(client)

  const EditBtn = ({ topicId }: { topicId: string }) => {
    return <Button onClick={() => onEdit(topicId)}>Edit</Button>
  }

  const getParentName = (parent: string) => {
    return topics.find((item) => item.topic.id === parent)?.topic.name
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'topic.name'
    },
    {
      Header: 'Parent',
      accessor: (item: any) => getParentName(item.topic.parentId)
    },
    {
      Header: 'Slug',
      accessor: 'topic.slug'
    },
    {
      Header: 'English Name',
      accessor: (item: adminGetTopics_adminGetTopics) => getTranslation(item.translations, WebLanguage.en, 'name')
    },
    {
      Header: 'English Headline',
      accessor: (item: adminGetTopics_adminGetTopics) => getTranslation(item.translations, WebLanguage.en, 'headline')
    },
    {
      Header: 'English Subheadline',
      accessor: (item: adminGetTopics_adminGetTopics) =>
        getTranslation(item.translations, WebLanguage.en, 'subheadline')
    },
    {
      Header: 'Czech Name',
      accessor: (item: adminGetTopics_adminGetTopics) => getTranslation(item.translations, WebLanguage.cs, 'name')
    },
    {
      Header: 'Czech Headline',
      accessor: (item: adminGetTopics_adminGetTopics) => getTranslation(item.translations, WebLanguage.cs, 'headline')
    },
    {
      Header: 'Czech Subheadline',
      accessor: (item: adminGetTopics_adminGetTopics) =>
        getTranslation(item.translations, WebLanguage.cs, 'subheadline')
    },
    {
      Header: 'Edit',
      accessor: (item: adminGetTopics_adminGetTopics) => <EditBtn topicId={item.topic.id} />
    }
  ]

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Topics
      </Typography>
      <GenericForm
        getInitialValues={initialValues}
        schema={getTopicFormSchema(
          operation,
          topics.filter((item) => item.topic.parentId === null)
        )}
        onSubmit={handleSubmit}
        submitBtnTitle={operation === 'create' ? 'Create' : 'Update'}
      />
      <GenericTable data={topics} columns={columns} />
    </Layout>
  )
}

export default withApollo(TopicsPage)
