import gql from 'graphql-tag'

export default gql`
  mutation setTutorActiveState2($tutorId: String!, $isActive: Boolean!) {
    setTutorActiveState2(tutorId: $tutorId, isActive: $isActive) {
      id
      isActive
    }
  }
`
