import { FormSchema } from '../../components'

const initialValues = {
  email: '',
  message: ''
}

const TutorInviteSchema: FormSchema = {
  fieldsets: [
    {
      fields: [
        {
          label: 'E-mail',
          accessor: 'email',
          fieldProps: {
            type: 'email',
            required: true
          }
        },
        {
          label: 'Message',
          accessor: 'message',
          fieldProps: {
            multiline: true
          }
        }
      ]
    }
  ]
}

export default TutorInviteSchema

export { initialValues }
