import gql from 'graphql-tag'

export default gql`
  query adminGetPackagesForCourseId($courseId: String!) {
    adminGetPackagesForCourseId(courseId: $courseId) {
      id
      name
      tutorId
      active
      globalVisibility
      score          
    }
  }
`