import gql from 'graphql-tag'

export default gql`
  query adminGetCourse($id: String!) {
    adminGetCourse(courseId: $id) {
      course {
          id
          slug
          topicL1
          topicL2
          showAsMain
          flagCode
          priority
        }
        translations {
          name
          lang
          seoTitle
          seoDescription
          seoImage
        }
        tags {
          tag {
            slug
            type
          }
          translations {
            name
            lang
          }
        }
    }
  }
`