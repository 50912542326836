import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Button } from '@material-ui/core'
import setTutorActiveStateMutation from '../gql/mutations/setTutorActiveState'
import NotificationService from '../services/NotificationService'
import { useMutation } from '@apollo/react-hooks'
import { setTutorActiveState2Variables } from '../gql/__generated__'

interface Props
  extends RouteComponentProps<{
      profileId: string
    }>,
    WithApolloClient<{}>,
    setTutorActiveState2Variables {}

const ActivateBtn = ({ client, tutorId, isActive }: Props) => {
  const [activeButton] = useMutation(setTutorActiveStateMutation, { client })
  const handleClick = async () => {
    await activeButton({
      variables: {
        tutorId: tutorId,
        isActive: !isActive
      }
    })
    NotificationService.showSuccess('Tutor active status successfully changed')
  }

  return (
    <Button color={isActive ? 'primary' : 'secondary'} variant='outlined' onClick={handleClick}>
      {isActive ? 'Deactivate' : 'Activate'}
    </Button>
  )
}

export default withApollo(withRouter(ActivateBtn))
