import React, { ReactElement } from 'react'
import { RouterLink } from './RouterLink'
import { Tooltip, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  item: {
    marginRight: 10,
    cursor: 'pointer'
  }
}

type Props = WithStyles<typeof styles> & {
  link: string
  title: string
  icon: ReactElement
}

const PageItem = (props: Props) => {
  const { link, title, icon, classes } = props
  return (
    <RouterLink to={link}>
      <Tooltip title={title} className={classes.item}>
        {icon}
      </Tooltip>
    </RouterLink>
  )
}

export default withStyles(styles)(PageItem)
