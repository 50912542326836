import { FormikUploadField, FormSchema } from '../../components'
import { countries, timeZones } from '../../api/Data'

import RichTextField from '../../components/FormikRichText'

const initialValues = {
  id: '',
  language: 'en',
  introduction: '',
  rating: '',
  topicIds: [],
  user: {
    id: '',
    firstName: '',
    lastName: '',
    countryCode: '',
    timezone: '',
    image: '',
    credential: {
      identifier: '',
      hash: ''
    }
  }
}

const isRichTextIntroduction = false

const TutorFormSchema: FormSchema = {
  normalize: (tutor) => {
    // We don't read hash in query. It's not safe.
    // But form needs hash in initial values
    if (tutor && tutor.user && tutor.user.credential) {
      tutor.user.credential.hash = ''
    }

    return tutor
  },
  fieldsets: [
    {
      title: 'User',
      fields: [
        {
          label: 'First name',
          accessor: 'user.firstName'
        },
        {
          label: 'Last name',
          accessor: 'user.lastName'
        },
        {
          label: 'Country',
          accessor: 'user.countryCode',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: '(choose)', value: '' },
              ...countries.map((country) => {
                return { value: country['alpha-3'].toLowerCase(), label: country.name }
              })
            ],
            default: 'cze'
          }
        },
        /*{
          label: 'Gender',
          accessor: 'user.gender',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: '(choose)', value: '' },
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
            ],
          },
        },*/
        {
          label: 'Timezone',
          accessor: 'user.timezone',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: '(choose)', value: '' },
              ...timeZones.map(({ text, utc }) => {
                return { value: utc[0], label: text }
              })
            ]
          }
        },
        {
          label: 'Image',
          accessor: 'user.image',
          component: FormikUploadField,
          fieldProps: {}
        }
      ]
    },
    {
      title: 'Tutor profile',
      size: 6,
      fields: [
        {
          label: 'Language',
          accessor: 'language',
          fieldProps: {
            select: true,
            selectOptions: [
              { label: '(choose)', value: '' },
              { label: 'English', value: 'en' }
            ],
            defaultValue: 'en'
          }
        },
        {
          label: 'Introduction',
          accessor: 'introduction',
          fieldProps: {
            placeholder: 'Type something here...'
          },
          component: isRichTextIntroduction ? RichTextField : undefined
        },
        {
          label: 'Rating',
          accessor: 'rating',
          fieldProps: {
            min: 0,
            step: 0.1,
            type: 'number'
          }
        },
        {
          label: 'Score',
          accessor: 'score',
          fieldProps: {
            type: 'number'
          }
        }
      ]
    },
    {
      title: 'Reservation blocking time',
      fields: [
        {
          label: 'Blocking time (minutes)',
          accessor: 'blockingTime',
          fieldProps: {
            type: 'number',
            min: 0,
            step: 1,            
          }
        }
      ]
    },
    {
      title: 'Credential',
      shouldDisable: (tutor) => !Boolean(tutor?.user?.credentials?.length) && !Boolean(tutor?.user?.credential),
      fields: [
        {
          label: 'E-mail',
          accessor: 'user.credentials[0].identifier'
        },
        {
          label: 'Password',
          accessor: 'user.credentials[0].hash',
          fieldProps: {
            type: 'password'
          }
        }
      ]
    }
  ]
}

export default TutorFormSchema

export { initialValues }
