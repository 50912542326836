import gql from 'graphql-tag'

export default gql`
  query adminGetAllTutorProps {    
    adminGetAllTutorProps {
      tutorId
      completeProfile
      oneCompletePackage
      connectedStripeAccount
      completeBilling
    }
  }
`