import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import getReferralsByUserId from '../gql/queries/getReferralsByUserId'
import { formatDate } from '../Utils'
import { Typography } from '@material-ui/core'
import { getProfile_getProfileFixed } from '../gql/__generated__'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profile: getProfile_getProfileFixed
  referrals: any[]
  isLoaded: boolean
}

class ReferralsPage extends React.Component<Props, State> {
  state = {
    referrals: [],
    isLoaded: false,
    profile: {} as getProfile_getProfileFixed
  }

  componentDidMount = async () => {
    const { match } = this.props
    try {
      const profile = await this.getProfile(match.params.profileId)
      this.setState({ profile })
      const referrals = await this.getReferrals()
      this.setState({ isLoaded: true, referrals })
    } catch (err) {
      NotificationService.showError('Cannot get referrals')
    }
  }

  getProfile = async (id: string): Promise<getProfile_getProfileFixed> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  getReferrals = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getReferralsByUserId,
      variables: { userId: this.state.profile.user.id }
    })
    this.setState({ referrals: result.data.getReferralsByUserId })
    return result.data.getReferralsByUserId
  }

  render() {
    const columns = [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'Referral createdAt',
        accessor: (item: any) => formatDate(item.referee.createdAt)
      },
      {
        Header: 'hadCall',
        accessor: (item: any) => item.referee.hadCall.toString()
      },
      {
        Header: 'rewardedAt',
        accessor: (item: any) => formatDate(item.referee.rewardedAt)
      },
      {
        Header: 'User',
        accessor: (item: any) => `${item.firstName} ${item.lastName}`,
        link: (item: any) => `/profile/${item.students[0].id}`
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>Profile detail - list of referrals</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Referrals} />
        <div>
          <ProfileDetail profile={this.state.profile} profileId={profileId} />
        </div>
        <div>
          <GenericTable
            title={`List of users referred by ${this.state.profile.user.displayName}`}
            data={this.state.referrals}
            columns={columns}
          />
        </div>
      </Layout>
    )
  }
}

export default withApollo(withRouter(ReferralsPage))
