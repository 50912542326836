import React, { useEffect, useRef, useState } from 'react'
import { Field, FormikConfig, FieldProps, Form, Formik, FormikProps, FormikHelpers, FormikContextType } from 'formik'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import MenuItem from '@material-ui/core/MenuItem'

import { Alert, FormikField, FormMessage } from './'
import { errorToMessage } from '../api/GraphQLUtils'
import { Topic } from '../types'
import { Box, Grid } from '@material-ui/core'

interface getInitialValuesFn<T> {
  (): Promise<T>
}

export interface FormSchema {
  denormalize?: (item: any) => any
  fieldsets: Array<{
    title?: string
    size?: number
    shouldDisable?: (item: any) => boolean
    fields: Array<{
      accessor: string
      component?: string | React.ComponentType<FieldProps<any>> | React.ComponentType<void>
      label: string
      fieldProps?: {
        [prop: string]: any
      }
      default?: any
    }>
  }>
  normalize?: (item: any) => any
}

interface GenericFormProps<Values = any> extends WithStyles<typeof styles> {
  submitBtnTitle?: string
  getInitialValues: Values | getInitialValuesFn<Values>
  schema: FormSchema
  onSubmit: (values: Values, actions: FormikHelpers<Values>) => Promise<Values>
  validationSchema?: FormikConfig<any>['validationSchema']
}

const styles: StyleRulesCallback<any, any> = (theme) => ({
  fieldset: {
    marginBottom: theme.spacing(3)
  },
  select: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})

const GenericFormImpl = ({
  getInitialValues,
  schema,
  onSubmit,
  classes,
  submitBtnTitle,
  validationSchema
}: GenericFormProps) => {
  const [loading, setLoading] = useState(true)
  const [selects, setSelects] = useState([])
  const [initialValues, setInitialValues] = useState<any>()
  const [error, setError] = useState('')
  const formikRef = useRef<FormikContextType<any>>(null)

  useEffect(() => {
    formikRef.current?.resetForm()

    const { normalize } = schema
    const $initialValues = getInitialValues instanceof Function ? getInitialValues() : getInitialValues
    // Async initial values
    // E.g. used in update forms, where we read data from API first
    if ($initialValues.then) {
      $initialValues
        .then((initialValues: any) => {
          // formikRef.current?.resetForm()
          setLoading(false)
          setInitialValues(normalize ? normalize(initialValues) : initialValues)
          formikRef.current?.setValues(initialValues)
        })
        .catch(() => {
          setLoading(false)
          setError('An error occurred when loading form')
        })
      // Sync initial values
      // E.g. used in create forms where we have empty initial state
    } else {
      const initialValues = $initialValues
      setLoading(false)
      setInitialValues(normalize ? normalize(initialValues) : initialValues)
      formikRef.current?.setValues(initialValues)
    }
  }, [getInitialValues, schema])

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    const { denormalize } = schema
    const denormalizedValues = denormalize ? denormalize(values) : values

    actions.setSubmitting(true)
    onSubmit(denormalizedValues, actions)
      .then((result) => {
        actions.setSubmitting(false)
        if (result) {
          actions.resetForm(getInitialValues())
        }
      })
      .catch((error) => {
        const message = errorToMessage(error)
        actions.setSubmitting(false)
        actions.setStatus(message)
      })
  }
  const { fieldsets } = schema
  const buttonDefaultText = submitBtnTitle ? submitBtnTitle : 'Save'

  return (
    <div className='mb-5'>
      {loading && <CircularProgress size={20} thickness={5} />}
      {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
      {!loading && initialValues && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={(formikBag: FormikProps<any>) => {
            const { status, isSubmitting } = formikBag

            const submitButton = (
              <p>
                <Button color='primary' disabled={isSubmitting} type='submit' variant='contained'>
                  &nbsp;{isSubmitting ? 'Saving...' : buttonDefaultText}&nbsp;
                </Button>
              </p>
            )
            return (
              <Form>
                {status && (
                  <Box mb={2}>
                    <FormMessage>
                      {status === 'Error: GraphQL error: User already assigned' ? 'User already assigned' : status}
                    </FormMessage>
                  </Box>
                )}
                <Grid
                  container
                  direction='row'
                  //justify="flex-start"
                  alignItems='flex-start'
                  spacing={3}
                  key='grid'
                >
                  {fieldsets.map((fieldset, s) => {
                    const isDisabled = fieldset.shouldDisable?.(initialValues)

                    return (
                      <Grid key={s} item xs={(fieldset.size ? fieldset.size : true) as any}>
                      <Card className={classes.fieldset} style={{ overflow: 'visible' }}>
                        <CardContent>
                          {fieldset.title && <Typography variant='h5'>{fieldset.title}</Typography>}
                          {fieldset.fields.map((field, f) => {
                            const fieldProps = field.fieldProps || {}

                            if (fieldProps.multiple) {
                              const Option = (props: any) => (
                                <MenuItem
                                  buttonRef={props.innerRef}
                                  selected={props.isFocused}
                                  component='div'
                                  {...props.innerProps}
                                >
                                  {props.children}
                                </MenuItem>
                              )
                              const comps = {
                                Option
                              }
                              return (
                                <div className={classes.select} key={f}>
                                  <Select
                                    label={field.label}
                                    placeholder={field.label}
                                    components={comps}
                                    onChange={(evt: any) => {
                                      formikBag.setFieldValue(field.accessor, evt && evt.map((e: any) => e.value))
                                      setSelects(evt)
                                      setInitialValues({ ...initialValues, topics: [] })
                                    }}
                                    styles={{
                                      menu: (styles: any) => {
                                        return { ...styles, zIndex: 1000 }
                                      }
                                    }}
                                    options={fieldProps.selectOptions}
                                    value={
                                      (selects && selects.length && selects) ||
                                      (initialValues.topics &&
                                        initialValues.topics.length &&
                                        initialValues.topics.map((topic: Topic) => {
                                          return {
                                            value: topic.id,
                                            label: topic.name
                                          }
                                        }))
                                    }
                                    isMulti={true}
                                    {...fieldProps}
                                  />
                                </div>
                              )
                            }
                            return (
                              <Field
                                key={f}
                                name={field.accessor}
                                component={field.component || FormikField}
                                label={field.label}
                                disabled={isDisabled}
                                {...fieldProps}
                              />
                            )
                          })}
                          {fieldsets.length === 1 && submitButton}
                        </CardContent>
                      </Card>
                    </Grid>
                    )
                  })}
                </Grid>
                {fieldsets.length > 1 && submitButton}
              </Form>
            )
          }}
        />
      )}
    </div>
  )
}

const GenericForm = withStyles(styles)(GenericFormImpl)

export { GenericForm }
