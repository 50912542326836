import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import Layout from '../layouts/Layout'
import { Typography } from '@material-ui/core'
import { GenericForm, RouterLink } from '../components'
import GroupSchema, { initialValues } from './formSchemas/GroupSchema'
import { useMutation } from '@apollo/react-hooks'
import adminCreateGroupMutation from '../gql/mutations/adminCreateGroup'
import Button from '@material-ui/core/Button'
import NotificationService, { MessageType } from '../services/NotificationService'
import { RouteComponentProps } from 'react-router-dom'

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const GroupCreatePage = ({ client, history }: Props) => {
  const [adminCreateGroup] = useMutation(adminCreateGroupMutation, { client })
  const getInitialValues = () => initialValues
  const handleSubmit = async (values: any) => {
    try {
      await adminCreateGroup({
        variables: values
      })
      NotificationService.show(MessageType.success, 'Saved successfully')
      history.push('/groups')
    } catch (err) {
      NotificationService.showError(err.message)
    }
  }
  return (
    <Layout>
      <Typography variant='h4'>
        Create Group
        <RouterLink to='/groups'>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to groups
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm getInitialValues={getInitialValues} schema={GroupSchema} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withApollo(GroupCreatePage)
