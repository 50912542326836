import React from 'react'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { User } from '../types'
import { withApollo, WithApolloClient } from 'react-apollo'
import { Button } from '@material-ui/core'
import sendNewPasswordMutation from '../gql/mutations/sendNewPassword'
import Typography from '@material-ui/core/Typography'
import NotificationService from '../services/NotificationService'

type Props = WithApolloClient<{}> &
  WithStyles<typeof styles> & {
    user: User
  }

const styles = {
  card: {
    marginBottom: 20
  }
}

class SendNewPassword extends React.Component<Props> {
  state = {
    sent: false
  }

  handleClick = async (identifier: string) => {
    const { client } = this.props
    try {
      await client.mutate({
        mutation: sendNewPasswordMutation,
        variables: {
          identifier
        }
      })
      this.setState({ sent: true })
    } catch (err) {
      NotificationService.showError('Send new password failed')
    }
  }

  render() {
    const { user } = this.props
    const sentComponent = () => {
      if (this.state.sent) {
        return <Typography variant='body1'>New password has been e-mailed to the user</Typography>
      }
    }

    return (
      <>
        <Button variant='contained' color='secondary' onClick={() => this.handleClick(user.credentials[0].identifier)}>
          Send new password
        </Button>
        {sentComponent()}
      </>
    )
  }
}

export default withStyles(styles)(withApollo(SendNewPassword))
