import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Button } from '@material-ui/core'
import setTutorPublishState from '../gql/mutations/setTutorPublishState'
import NotificationService from '../services/NotificationService'
import { useMutation } from '@apollo/react-hooks'
import { setTutorPublishStateVariables } from '../gql/__generated__'

interface Props
  extends RouteComponentProps<{
      profileId: string
    }>,
    WithApolloClient<{}>,
    setTutorPublishStateVariables {}

const PublishBtn = ({ client, tutorId, publish }: Props) => {
  const [publishButton] = useMutation(setTutorPublishState, { client })
  const handleClickPublish = async () => {
    await publishButton({
      variables: {
        tutorId: tutorId,
        publish: !publish
      }
    })
    publish && NotificationService.showSuccess("Tutor's profile published")
    !publish && NotificationService.showSuccess("Tutor's profile unpublished")
  }
  return (
    <Button color={publish ? 'primary' : 'secondary'} variant='outlined' onClick={handleClickPublish}>
      {publish ? 'Unpublish' : 'Publish'}
    </Button>
  )
}

export default withApollo(withRouter(PublishBtn))
