import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from 'react-apollo'

import Layout from '../layouts/Layout'
import { Alert, GenericTable } from '../components'
import getAwaitingVerificationCredentialsQuery from '../gql/queries/getAwaitingVerificationCredentials'

interface Data {
  getAwaitingVerificationCredentials: Array<any>
}

class AwaitingVerificationQuery extends Query<Data> {}

class AwaitingVerificationListPage extends React.Component {
  render() {
    const columns = [
      {
        Header: 'identifier',
        accessor: (item: any) => item.identifier
      },
      {
        Header: 'Generate new link',
        accessor: (item: any) => `Generate new link`,
        link: (item: any) => `awaiting-verification/${item.identifier}`
      }
    ]
    return (
      <Layout>
        <AwaitingVerificationQuery query={getAwaitingVerificationCredentialsQuery}>
          {({ data, loading, error }) => {
            return (
              <div>
                {loading && <CircularProgress size={20} thickness={5} />}
                {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
                {!loading && data && (
                  <div>
                    <GenericTable data={data.getAwaitingVerificationCredentials} columns={columns} />
                  </div>
                )}
              </div>
            )
          }}
        </AwaitingVerificationQuery>
      </Layout>
    )
  }
}

export default AwaitingVerificationListPage
