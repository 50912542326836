import gql from 'graphql-tag'

export default gql`
  mutation tutorCreateStudent(
    $email: String!
    $message: String!
    $tutorId: String!
    $tutorUserId: String!
    $nameOverride: String
  ) {
    invite: tutorCreateStudent(
      email: $email
      message: $message
      tutorId: $tutorId
      tutorUserId: $tutorUserId
      nameOverride: $nameOverride
    ) {
      newUser
      hash
      slug
      user {
        id
        students {
          id
        }
      }
    }
  }
`
