import gql from 'graphql-tag'

export default gql`
  query adminGetPurchasedPackages($studentId: String!) {
    adminGetPurchasedPackages(studentId: $studentId) {
      id
      tutorId
      package {
        id
        numOfLessons
        lessons {
          id
          no
        }
        name
        courseId
        createdAt
      }
      createdAt
      usages {
        usedAt
        bookedAt
      }
      status
    }
  }
`
