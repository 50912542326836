import gql from 'graphql-tag'

export default gql`
  mutation setTutorPublishState($tutorId: String!, $publish: Boolean!) {
    setTutorPublishState(tutorId: $tutorId, publish: $publish) {
      id
      publish
    }
  }
`
