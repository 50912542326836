import gql from 'graphql-tag'

export default gql`
  mutation adminUpdateAiPricelist($id: String!, $input: AiPricelistInput!) {
    adminUpdateAiPricelist(id: $id, input: $input) {
      id
      minutes
      price
      currency
    }
  }
`