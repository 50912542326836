export enum WalletType {
  UserPromo = 'UserPromo',
  User = 'User'
}

export enum UserPagesTitles {
  Ban = 'Ban&Delete',
  Overview = 'Overview',
  Calls = 'Calls',
  Bookings = 'Bookings',
  Conversations = 'Conversations',
  Ratings = 'Ratings',
  Contacts = 'Contacts',
  Previews = 'Previews',
  StatusHistory = 'Status History',
  WalletDetail = 'Wallet Detail',
  TutorNotifications = 'Tutor notifications',
  Packages = 'Packages',
  Referrals = 'Referrals',
  Quizzes = 'Quizzes',
  Payouts = 'Payouts'
}
