import gql from 'graphql-tag'

export default gql`
  mutation adminRefreshCourseSearchIndex {
    adminRefreshCourseSearchIndex {
      activeTutorCount
      inactiveTutorCount
      totalCourses
      removedTutors    
    }
  }
`