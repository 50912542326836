import React, { ReactElement } from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericForm, GenericTable } from '../components'
import { RouteComponentProps } from 'react-router'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import adminGetConversation from '../gql/queries/adminGetConversation'
import NotificationService from '../services/NotificationService'
import CircularProgress from '@material-ui/core/CircularProgress'
import CreateSystemMessageSchema, { initialValues } from './formSchemas/CreateSystemMessageSchema'
import createSystemMessage from '../gql/mutations/createSystemMessage'
import {
  adminGetConversation_adminGetConversation as Conversation,  
  adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery as AiAttachmentQuery,  
  adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiReset as AiAttachmentReset,
  adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiStatus as AiAttachmentStatus,
  adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiResponse as AiAttachmentResponse,
  adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery_aiSettings as AiSettings,
  getProfile_getProfileFixed_Tutor,
  getUser_getUser,
  resolveUsers_resolveUsers
} from '../gql/__generated__'
import { Typography } from '@material-ui/core'
import resolveUsers from '../gql/queries/resolveUsers'

type AiAttachment = AiAttachmentQuery | AiAttachmentReset | AiAttachmentStatus | AiAttachmentResponse

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    conversationId: string
  }>

type TableData = {
  from: string
  to: string
  content: ReactElement | null
  sent: Date
  roomId: string
  read: Date
  attachment: ReactElement | null
}

interface State {
  users: getUser_getUser[]
  isLoaded: boolean
  conversation: Conversation
}

const JSON_REGEXES = [/```json\s*({.*?})\s*```/s, /\s*({.*?})\s*/s]

const extractAiMessageJSON = (text: string) => {
  for (let i = 0; i < JSON_REGEXES.length; i += 1) {
    const regex = JSON_REGEXES[i]
    const matches = text.match(regex)
    if (matches && matches[1]) {
      try {
        return JSON.parse(matches[1])
      } catch (error) {
        // do nothing
      }
    }
  }
  return null
}

class ConversationDetailPage extends React.Component<Props, State> {
  state = {
    conversation: {} as Conversation,
    users: [] as getUser_getUser[],
    isLoaded: false
  }

  componentDidMount = async () => {
    const { match } = this.props

    try {
      await this.getConversation(match.params.conversationId)
    } catch (err: any) {
      NotificationService.showError(err.message)
    }

    const userIds = this.state.conversation.members.map((member) => member.userId)

    const users = await this.resolveUsers(userIds).catch((err) => NotificationService.showError(err.message))
    if (users && users.length) {
      this.setState({ users, isLoaded: true })
    }
  }

  getConversation = async (id: string): Promise<Conversation> => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetConversation,
      variables: {
        id
      }
    })
    this.setState({ conversation: result.data.adminGetConversation })
    return result.data.adminGetConversation
  }

  resolveUsers = async (ids: string[]): Promise<resolveUsers_resolveUsers[]> => {
    const { client } = this.props
    const result = await client.query({
      query: resolveUsers,
      variables: {
        ids
      }
    })
    return result.data.resolveUsers
  }

  getInitialValues = () => initialValues

  handleSubmit = async (values: any) => {
    const { client, match } = this.props
    await client.mutate({
      mutation: createSystemMessage,
      variables: {
        message: {
          toUserId: values.toUserId,
          content: values.content,
          conversationId: match.params.conversationId
        }
      }
    })
    await this.getConversation(match.params.conversationId)
    return true
  }

  render() {
    const columns = [
      {
        Header: 'from',
        accessor: 'from'
      },
      {
        Header: 'to',
        accessor: 'to'
      },
      {
        Header: 'content',
        accessor: 'content'
      },
      {
        Header: 'sent',
        accessor: (item: any) => {
          return formatDate(item.sent)
        }
      },
      {
        Header: 'read',
        accessor: (item: any) => {
          return formatDate(item.read)
        }
      },
      {
        Header: 'roomId',
        accessor: 'roomId'
      },
      {
        Header: 'AI attachment',
        accessor: 'attachment'
      }

    ]

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    if (CreateSystemMessageSchema.fieldsets[0].fields.length === 1) {
      CreateSystemMessageSchema.fieldsets[0].fields.unshift({
        label: 'To',
        accessor: 'toUserId',
        fieldProps: {
          select: true,
          selectOptions: [
            { label: '(choose)', value: '' },
            ...this.state.users.map((user) => {
              return {
                value: user.id,
                label: `${user.credentials && user.credentials[0].identifier}: ${user.firstName} ${user.lastName}`
              }
            })
          ]
        }
      })
    }

    const getValueString = (value: any): string => {      
      if (typeof value === 'string') return (value === '' ? "''" : value)
      if (typeof value === 'object') return JSON.stringify(value)            
      if (typeof value === 'boolean') return value ? 'true' : 'false'
      if (typeof value === 'number') return value.toString()
      if (value === null) return 'null'
      return ''
    }

    const getAttachmentValue = (attachment: AiAttachment): ReactElement | null => {
      if(!attachment?.type) return null

      return (<div style={{fontSize: '0.9em'}}>
        <h3 style={{ marginBottom: 0 }}>{attachment.type}</h3>
        
        {attachment.type === 'AiQuery' && (<div>
            Reservation ID: {getValueString((attachment as AiAttachmentQuery).aiReservationId)}
            <h4 style={{ marginTop: 0, marginBottom: 0 }}>AI settings:</h4>
            <ul style={{ marginTop: 0, marginBottom: 0 }}>
              {['languageComplexity', 'grammarFocus', 'conversationTheme'].map((key) => (
                <li key={key}>{key}: {getValueString((attachment as AiAttachmentQuery).aiSettings?.[key as keyof AiSettings])}</li>              
              ))}
            </ul>
          </div>)}
        
        {attachment.type === 'AiReset' && (<div>
            Reservation ID: {getValueString((attachment as AiAttachmentReset).aiReservationId)}            
          </div>)}

        {attachment.type === 'AiStatus' && (<div>
            Status message type: <strong>{getValueString((attachment as AiAttachmentStatus).statusMessageType)}</strong>
            <h4 style={{ marginTop: 0, marginBottom: 0 }}>AI settings props changes:</h4>
            <ul style={{ marginTop: 0, marginBottom: 0 }}>
              {['languageComplexity', 'grammarFocus', 'conversationTheme'].map((key) => (
                <li key={key}>{key}: {getValueString((attachment as AiAttachmentStatus).aiSettingsPropsChanges?.[key as keyof AiSettings])}</li>              
              ))}
            </ul>
            AI settings props affected: {getValueString((attachment as AiAttachmentStatus).aiSettingsPropsAffected)}<br />
            Minutes: {getValueString((attachment as AiAttachmentStatus).minutes)}
          </div>)}

        {attachment.type === 'AiResponse' && (<div>
            Reservation ID: {getValueString((attachment as AiAttachmentResponse).aiReservationId)}<br />
            ResponseToId: {getValueString((attachment as AiAttachmentResponse).responseToId)}<br />
            Tokens prompt (in): {getValueString((attachment as AiAttachmentResponse).tokensPrompt)}<br />
            Tokens completion (out): {getValueString((attachment as AiAttachmentResponse).tokensCompletion)}<br />
            AI error: {getValueString((attachment as AiAttachmentResponse).AiError)}
          </div>)}
      </div>)
    }    

    const getMessageData = (message: string): ReactElement | null => {
      const jsonData = extractAiMessageJSON(message)
      if(jsonData) {
        return (
          <>
            <div style={{ marginBottom: '0.5em' }}><span style={{ color: 'blue' }}>Message: </span> {jsonData.message}</div>
            <div style={{ marginBottom: '0.5em' }}><span style={{ color: 'blue' }}>Grammar:</span> {jsonData.grammar}</div>
            <div style={{ marginBottom: '0.5em' }}><span style={{ color: 'blue' }}>Grammar correct:</span> {getValueString(jsonData.grammar_correct)}</div>
          </>
        )
      }
      return <>{message}</>
    }

    const makeTableData = (): TableData[] => {
      if (!this.state.conversation.messages) {
        return []
      }

      const data = this.state.conversation.messages.map((message: any): TableData | null => {
        const senderUser = this.state.users.find((user: getUser_getUser) => user.id === message.fromUserId)
        const targetUser = this.state.users.find((user: getUser_getUser) => user.id === message.toUserId)
        return {
          from: senderUser ? `${senderUser.firstName} ${senderUser.lastName}` : 'SYSTEM',
          to: targetUser ? `${targetUser.firstName} ${targetUser.lastName}` : '',
          content: getMessageData(message.content),
          sent: message.sent,
          roomId: message.roomId,
          read: message.read,
          attachment: getAttachmentValue(message.attachment as AiAttachment)
        }
      })
      return data.filter((d) => d) as TableData[]
    }
    const profileA = {
      ...((this.state.users[0].tutors && this.state.users[0].tutors[0]) ||
        (this.state.users[0].students && this.state.users[0].students[0])),
      user: this.state.users[0] as any
    } as getProfile_getProfileFixed_Tutor
    const profileB = {
      ...((this.state.users[1].tutors && this.state.users[1].tutors[0]) ||
        (this.state.users[1].students && this.state.users[1].students[0])),
      user: this.state.users[1] as any
    } as getProfile_getProfileFixed_Tutor
    return (
      <Layout>
        <Typography variant='h4'>Conversation detail - list of messages</Typography>
        <ProfileDetail profile={profileA} profileId={profileA.id} />
        <ProfileDetail profile={profileB} profileId={profileB.id} />
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={CreateSystemMessageSchema}
          onSubmit={this.handleSubmit}
        />
        <GenericTable data={makeTableData()} columns={columns} />
      </Layout>
    )
  }
}

export default withApollo(withRouter(ConversationDetailPage))
