import { ApolloClient } from 'apollo-boost'
import { useState, useEffect } from 'react'
import adminCreateCourseWithTranslationsMutation from '../../gql/mutations/adminCreateCourseWithTranslations'
import adminGetCourseQuery from '../../gql/queries/adminGetCourse'
import adminUpdateCourseQuery from '../../gql/mutations/adminUpdateCourse'
import { 
  adminCreateCourseWithTranslations, 
  adminCreateCourseWithTranslationsVariables,
  CourseWithTagsAndTranslationsInput,  
  adminGetCourse,
  adminGetCourseVariables,
  adminUpdateCourse,
  adminUpdateCourseVariables,
} from '../../gql/__generated__'
import NotificationService, { MessageType } from '../../services/NotificationService'
import { LANGUAGE_MAP } from './courseConfig'

export function useCourseForm(client: ApolloClient<any>, courseId?: string) {
  const [progress, setProgress] = useState(courseId ? 'loading' : 'idle')
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [courseData, setCourseData] = useState<CourseWithTagsAndTranslationsInput|null>(null)

  const handleSubmit = async (values: CourseWithTagsAndTranslationsInput, imageData?: any) => {
    setProgress('waiting')    
    
    // setup textual variables
    const variables = {
      input: { ...values }
    } as any

    if(!variables.input.course.flagCode) {
      variables.input.course.flagCode = null
    }

    // add image data as image_${lang} fields if we have any
    if (imageData) {
      LANGUAGE_MAP.forEach(lang => {
        if(imageData[`image_${lang}`]) {
          variables[`image_${lang}`] = imageData[`image_${lang}`]
        }
      })
    }
    try {
      if (courseId) {
        // add course id because updating
        variables.id = courseId
                
        // update existing course
        await client.mutate<adminUpdateCourse, adminUpdateCourseVariables>({
          mutation: adminUpdateCourseQuery,
          variables
        })
      } else {
        // create new course
        await client.mutate<adminCreateCourseWithTranslations, adminCreateCourseWithTranslationsVariables>({
          mutation: adminCreateCourseWithTranslationsMutation,
          variables
        })
      }      
      NotificationService.show(MessageType.success, 'Topic added successfully')
      setProgress('success')  
      setShouldRedirect(true)
    } catch (err: any) {      
      NotificationService.showError('Create or update of course failed', err)
      setProgress('error')
    }
  }

  const fetchCourse = async () => {
    try {
      const course = await client.query<adminGetCourse, adminGetCourseVariables>({
        query: adminGetCourseQuery, 
        variables: {
          id: courseId!
        }
      })      
      const cData = course.data.adminGetCourse
      setCourseData({
        course: {
          slug: cData.course.slug,
          topicL1: cData.course.topicL1,
          topicL2: cData.course.topicL2,
          flagCode: cData.course.flagCode ?? '',
          showAsMain: cData.course.showAsMain,
          priority: cData.course.priority
        },
        translations: cData.translations,
        tags: cData.tags.map(t => {
          return {
            slug: t.tag.slug,
            type: t.tag.type,
            translations: t.translations         
          }
        })
      })  
      setProgress('idle')      
    } catch (err: any) {
      setProgress('error')  
      NotificationService.showError('Fetch of course failed', err)
    }
  }

  
  useEffect(() => {
    if (courseId) {
      fetchCourse()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId])

  return {
    handleSubmit,
    progress,
    courseData,
    shouldRedirect
  }
}