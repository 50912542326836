import gql from 'graphql-tag'

export default gql`
  query PackageUpdateQuery($id: String!) {
    adminGetPackage(id: $id) {
      id
      tutorId
      tutorUserId
      active
      name
      duration
      numOfLessons
      defaultPrice
      defaultCurrency
      description
      prerequisites
      level
      score
      topicIds
      courseId
      firstLessonFree
    }
  }
`
