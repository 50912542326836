import moment from 'moment'
const CDN_URL = process.env.REACT_APP_CDN_URL

export const getUserImageUrl = (fileName: string) => `${CDN_URL}user-image/${fileName}`

export const formatDate = (date: Date): string => {
  if (!date) {
    return ''
  }
  date = new Date(date)
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

type Range = {
  from: string
  to: string
}

export const generateDateRanges = (weeks: number): Range[] => {
  let date = new Date()
  const daysSinceMonday = date.getDay() - 1
  date.setDate(date.getDate() - daysSinceMonday)

  let ranges = []

  for (let i = 0; i < weeks; i++) {
    const to = moment(date).add(6, 'days').format('YYYY-MM-DD')
    const from = moment(date).format('YYYY-MM-DD')
    ranges.push({ from, to })
    date.setDate(date.getDate() - 7)
  }

  return ranges
}

type DateRange = {
  from: Date
  to: Date
}

export const makeDateRange = (): DateRange => {
  let date = new Date()
  const daysSinceMonday = date.getDay() - 1
  date.setDate(date.getDate() - daysSinceMonday)
  const to = moment(date).add(6, 'days').toDate()

  return {
    from: makeStartUTCDate(date),
    to: makeEndUTCDate(to)
  }
}

export const makeStartUTCDate = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
}

export const makeEndUTCDate = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
}

export const isStudent = (profileId: string) => {
  return profileId.startsWith('ST')
}
