import React from 'react'
import { withApollo } from 'react-apollo'
import Typography from '@material-ui/core/Typography'
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import SimpleSelect from '../components/SimpleSelect'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { generateDateRanges } from '../Utils'
import { DateRange, PaymentStatsType } from '../types'
import { withStyles } from '@material-ui/core/styles'
import paymentStatsQuery from '../gql/queries/paymentStatsQuery'
import randomColor from 'randomcolor'
const styles = {
  root: {
    marginBottom: 20
  }
}

interface State {
  isLoaded: boolean
  paymentStats: PaymentStatsType[]
  ranges: DateRange[]
  selectedRange: string
  paymentStates: Set<string>
  data: any
}

class PaymentStats extends React.Component<any, State> {
  state: State = {
    isLoaded: false,
    paymentStats: [],
    ranges: [],
    selectedRange: '2019-05-03',
    paymentStates: new Set<string>(),
    data: []
  }

  count = async (from: string, to: string) => {
    const { client } = this.props
    const paymentStatsPromise = client.query({
      query: paymentStatsQuery,
      variables: {
        dateRangeInput: {
          from,
          to
        }
      }
    })
    const [paymentStatsRes] = await Promise.all([paymentStatsPromise])

    this.setState({
      paymentStats: paymentStatsRes.data.adminPaymentStats,
      isLoaded: true
    })
    const data = this.generateData()
    this.setState({ ...data })
  }
  componentDidMount = async () => {
    const ranges = generateDateRanges(4)
    await this.setState({ ranges, selectedRange: ranges[0].from })
    await this.count(ranges[0].from, ranges[0].to)
  }
  generateData = () => {
    const data: any = []
    const { paymentStats, paymentStates } = this.state

    const defaultRange: any = {}
    let from = moment(this.state.selectedRange)
    const to = moment(this.state.selectedRange).add(6, 'days')

    while (from <= to) {
      defaultRange[from.format('YYYY-MM-DD')] = {}
      from = moment(from).add(1, 'days')
    }
    const joinedPayments = paymentStats.reduce((acc: any, curr) => {
      curr.date = curr.date.slice(0, 10)
      if (!acc[curr.date]) {
        acc[curr.date] = {}
      }
      paymentStates.add(curr.blabuState)
      acc[curr.date][curr.paymentType + '_' + curr.blabuState] = curr.amount
      return acc
    }, defaultRange)

    Object.keys(joinedPayments).map((el) => {
      data.push({ name: el, ...joinedPayments[el] })
      return data
    })

    return { paymentStats: data, paymentStates }
  }

  handleChange = async (e: any) => {
    await this.setState({ selectedRange: e.target.value })
    const to = moment(this.state.selectedRange).add(6, 'days').format('YYYY-MM-DD')
    await this.count(this.state.selectedRange, to)
  }

  render() {
    const { classes } = this.props

    if (!this.state.isLoaded) {
      return <CircularProgress size={20} thickness={5} />
    }

    const menuItems = () => {
      return this.state.ranges.map((r) => {
        return (
          <MenuItem key={r.from} value={r.from}>
            {`${r.from} - ${r.to}`}
          </MenuItem>
        )
      })
    }

    const chartTypes: any = []
    this.state.paymentStates.forEach((state) =>
      ['paypal', 'csob', 'sodexo', 'mollie', 'payu', 'gallery-beta'].forEach((payment) => {
        chartTypes.push({ state, key: payment + '_' + state })
      })
    )

    const successStates = ['success']
    const columnByType = (state: string) => {
      if (successStates.includes(state)) return 'b'
      if (['created', 'in_progress'].includes(state)) return 'a'
      return 'c'
    }
    const colorByType = (state: string) => {
      if (successStates.includes(state)) return randomColor({ luminosity: 'bright', hue: 'green' })
      if (['created', 'in_progress'].includes(state)) return randomColor({ luminosity: 'light', hue: 'ping' })
      return randomColor({ luminosity: 'dark', hue: 'red' })
    }
    return (
      <Card classes={{ root: classes.root }}>
        <CardContent>
          <Typography variant='h4'>Income by type</Typography>
          <SimpleSelect handleChange={this.handleChange} value={this.state.selectedRange} menuItems={menuItems()} />
          <BarChart width={800} height={350} data={this.state.paymentStats}>
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            {chartTypes.map((type: any) => (
              <Bar
                key={type.key}
                stackId={columnByType(type.state)}
                dataKey={type.key}
                fill={colorByType(type.state)}
              />
            ))}
          </BarChart>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(withApollo(PaymentStats))
