import gql from 'graphql-tag'

export default gql`
  query getProfile($id: String!) {
    getProfileFixed(id: $id) {
      ... on Tutor {
        id
        stripeAccountId
        enabledPayments
        slug
        publish
        isActive
        user {
          id
          firstName
          lastName
          displayName
          image
          createdAt
          credentials {
            identifier
          }
          ban {
            id
          }
          contacts {
            id
            ... on PaymentOptions {
              otherMethodsEnabled
              stripeEnabled
            }
            ... on Email {
              verifiedAt
              email
            }
            ... on Phone {
              phone
            }
            ... on Billing {
              name
              street
              city
              zip
              countryCode
              taxId
              companyId
            }
            ... on Fee {
              fee
            }
          }
        }
      }
      ... on Student {
        id
        freeAiUsage
        user {
          id
          firstName
          lastName
          displayName
          image
          createdAt
          credentials {
            identifier
          }
          ban {
            id
          }
          contacts {
            id
            ... on PaymentOptions {
              otherMethodsEnabled
              stripeEnabled
            }
            ... on Email {
              verifiedAt
              email
            }
            ... on Phone {
              phone
            }
            ... on Billing {
              name
              street
              city
              zip
              countryCode
            }
          }
        }
      }
    }
  }
`
