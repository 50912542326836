import { Dialog, Paper } from '@material-ui/core'
import React, { ReactNode } from 'react'
import DialogTitle from './DialogTitle'
import { makeStyles } from '@material-ui/styles'

type Props = {
  onClose: () => void
  children: ReactNode
  open: boolean
  title: string
}

const useStyles = makeStyles({
  paper: {
    width: 500,
    height: 300,
    outline: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const CustomDialog = ({ children, onClose, open, title }: Props) => {
  const classes = useStyles({})
  return (
    <Dialog className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <DialogTitle onClose={onClose} title={title} />
        {children}
      </Paper>
    </Dialog>
  )
}

export default CustomDialog
