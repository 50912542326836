import cx from 'classnames'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'

const styles: StyleRulesCallback<any, any> = (theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'inline-block',
    minWidth: 1000
  },
  alignLeft: {
    marginLeft: theme.spacing(3),
    marginRight: 'auto'
  },
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  alignRight: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3)
  }
})

type Props = WithStyles<typeof styles> & {
  align?: 'center' | 'left' | 'right'
  children: ReactNode
}

const ContainerImpl = ({ align = 'center', classes, children }: Props) => (
  <div
    className={cx(classes.root, {
      alignCenter: align === 'center',
      alignLeft: align === 'left',
      alignRight: align === 'right'
    })}
  >
    {children}
  </div>
)

export const Container = withStyles(styles)(ContainerImpl)
