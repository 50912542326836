import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { WithStyles, withStyles } from '@material-ui/core/styles'

type Props = WithStyles<typeof styles> & {
  text: string
}

const styles = {
  card: {
    marginBottom: 20
  }
}

const SimpleCardWithText = (props: Props) => {
  const { classes, text } = props
  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        <Typography variant='h5' component='h5'>
          {text}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(SimpleCardWithText)
