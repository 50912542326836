import React, { useEffect, useState } from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import CircularProgress from '@material-ui/core/CircularProgress'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import findStudentsQuery from '../gql/queries/findStudents'
import getWalletBalancesQuery from '../gql/queries/adminGetWalletBalances'
import { RouteComponentProps } from 'react-router-dom'
import { Student, StudentWithBalance, WalletBalance } from '../types'
import PhoneIcon from '@material-ui/icons/Phone'
import PageItem from '../components/PageItem'
import MoneyIcon from '@material-ui/icons/Money'
import StarIcon from '@material-ui/icons/Star'
import MessageIcon from '@material-ui/icons/Message'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { TablePaginationProps } from '@material-ui/core/TablePagination'
import ContactsIcon from '@material-ui/icons/Contacts'
import EmailIcon from '@material-ui/icons/Email'
import BlockIcon from '@material-ui/icons/Block'
import PeopleIcon from '@material-ui/icons/People'
import PackageIcon from '@material-ui/icons/ViewList'
import QuizIcon from '@material-ui/icons/QuestionAnswer'
import { Typography } from '@material-ui/core'

const styles = {
  pages: {
    display: 'flex'
  }
}

type Props = WithApolloClient<{}> & RouteComponentProps<{}> & WithStyles<typeof styles>

const StudentsListPage = ({ client, classes }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [studentsWithBalances, setStudentsWithBalances] = useState<StudentWithBalance[]>([])
  const [numberOfStudents, setNumberOfStudents] = useState<number>(0)

  const findStudents = async (first: number, offset = 0) => {
    const result = await client.query({
      query: findStudentsQuery,
      variables: {
        first,
        offset
      }
    })

    const getStudents = await client.query({
      query: findStudentsQuery,
      variables: {
        pageInfo: {
          first: 9999,
          offset
        }
      }
    })

    setNumberOfStudents(getStudents.data.findStudents.length)

    return result.data.findStudents
  }

  const getBalances = async (userIds: string[]) => {
    const result = await client.query({ query: getWalletBalancesQuery, variables: { userIds } })
    return result.data.adminGetWalletBalances
  }

  const getStudentsWithBalances = (students: Student[], walletBalances: WalletBalance[]): StudentWithBalance[] => {
    const merged = []

    for (let student of students) {
      const walletBalance = walletBalances.find((wb) => wb.userId === student.user.id)
      merged.push({ ...student, balance: (walletBalance && walletBalance.balance) || 0 })
    }

    return merged
  }

  const onUpdate = async (rowsPerPage: number, page = 0) => {
    const students = await findStudents(rowsPerPage, page * rowsPerPage)
    const userIds = students.map((s: any) => s.user.id)
    const balances = await getBalances(userIds)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: page })
    const result = getStudentsWithBalances(students, balances)
    setStudentsWithBalances(result)
    setIsLoaded(true)
  }

  const handleChangeOfRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    setIsLoaded(false)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: 0 })
    await onUpdate(rowsPerPage)
  }

  const handleChangePage = async (events: any, page: number) => {
    setIsLoaded(false)
    setPagination({ ...pagination, page: page })
    await onUpdate(pagination.rowsPerPage, page)
  }

  const [pagination, setPagination] = useState<TablePaginationProps>({
    count: 1000,
    page: 0,
    rowsPerPage: 100,
    rowsPerPageOptions: [30, 50, 100],
    onChangeRowsPerPage: handleChangeOfRowsPerPage,
    onChangePage: handleChangePage
  })

  useEffect(() => {
    onUpdate(pagination.rowsPerPage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.rowsPerPage])

  const columns = [
    {
      Header: '#'
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'User',
      accessor: (item: any) => {
        const fullName = `${item.user.firstName} ${item.user.lastName}`
        return fullName.substring(0, 23)
      },
      link: (item: any) => `profile/${item.id}`
    },
    {
      Header: 'Email',
      accessor: (item: any) => {
        if (item.user && item.user.credentials && item.user.credentials.length) {
          return item.user.credentials[0].identifier
        } else {
          return ''
        }
      }
    },
    {
      Header: 'Balance',
      accessor: 'balance'
    },
    {
      Header: 'pages',
      accessor: (item: any) => {
        return (
          <div className={classes.pages}>
            <PageItem icon={<MoneyIcon />} title='Wallets' link={`wallet/${item.id}`} />
            <PageItem icon={<PhoneIcon />} title='Calls' link={`profile/${item.id}`} />
            <PageItem icon={<StarIcon />} title='Ratings' link={`ratings/${item.id}`} />
            <PageItem icon={<MessageIcon />} title='Messages' link={`conversations/${item.id}`} />
            <PageItem icon={<ContactsIcon />} title='Contacts' link={`contacts/${item.id}`} />
            <PageItem icon={<EmailIcon />} title='Subscriptions' link={`subscriptions/${item.id}`} />
            <PageItem icon={<BlockIcon />} title='Ban&Delete' link={`ban/${item.id}`} />
            <PageItem icon={<PeopleIcon />} title='Referrals' link={`referrals/${item.id}`} />
            <PageItem icon={<PackageIcon />} title='Packages' link={`packages/${item.id}`} />
            <PageItem icon={<QuizIcon />} title='Quizzes' link={`quizzes/${item.id}`} />
          </div>
        )
      }
    }
  ]

  return (
    <Layout>
      <Typography variant='h4'>Students</Typography>
      {!isLoaded ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <GenericTable
          data={studentsWithBalances}
          pagination={pagination}
          columns={columns}
          numberOfItems={numberOfStudents}
        />
      )}
    </Layout>
  )
}

export default withStyles(styles)(withApollo(StudentsListPage))
