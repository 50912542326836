import gql from 'graphql-tag'

export default gql`
  query adminGetDemand($id: String!) {
    adminGetDemand(id: $id) {
      id
      status  
      createdAt
      updatedAt
      demandFormTutors {
        id
        tutorId
        status
        createdAt
        updatedAt
      }      
    }
  }
`