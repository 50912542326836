import gql from 'graphql-tag'

export default gql`
  query adminGetPayouts($profileId: String!) {
    adminGetPayouts(profileId: $profileId) {
      id
      amount
      currency
      createdAt
      from
      to
      type
      status
    }
  }
`
