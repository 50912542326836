import React, { useEffect, useState } from 'react'
import { WithApolloClient, withApollo } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Layout from '../../layouts/Layout'
import { Box, Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import adminGetDemandQuery from '../../gql/queries/adminGetDemand'
import { 
  adminGetDemand,
  adminGetDemandVariables,
  adminGetDemand_adminGetDemand,
  adminGetDemand_adminGetDemand_demandFormTutors as dfTutor,
  adminUpdateDemandStatus,
  adminUpdateDemandStatusVariables
} from '../../gql/__generated__'
import NotificationService from '../../services/NotificationService'
import { GenericForm, GenericTable } from '../../components'
import DemandStatusFormSchema, { initialValues, validationSchema, DemandStatusFormValues} from '../formSchemas/DemandStatusSchema'
import adminUpdateDemandStatusMutation from '../../gql/mutations/adminUpdateDemandStatus'
import { RouterLink } from '../../components'

type DemandDetailPageProps = WithApolloClient<RouteComponentProps<{ demandId: string }>>

const DemandDetailPage = ({ client, match }: DemandDetailPageProps) => {
  const [ demand, setDemand ] = useState<adminGetDemand_adminGetDemand | null>(null)
  const matchParams = match.params
  const demandId = matchParams.demandId
   
  const getDemand = async () => {
    if(!demandId) return null
    try {
      const result = await client.query<adminGetDemand, adminGetDemandVariables>({
        query: adminGetDemandQuery,
        variables: {
          id: demandId
        }
      })
      setDemand(result.data.adminGetDemand)
    } catch (error) {
      NotificationService.showError('Cannot get demand info')
      console.error(error)
    }
  }

  useEffect(() => {
    getDemand()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values: DemandStatusFormValues) => {     
    try {
      const result = await client.mutate<adminUpdateDemandStatus, adminUpdateDemandStatusVariables>({
        mutation: adminUpdateDemandStatusMutation,
        variables: {
          id: demandId,
          status: values.status
        }
      })
      if(result.data && result.data.adminUpdateDemandStatus) {
        NotificationService.showSuccess('Demand status updated')
        getDemand()
      }
    } catch(err) {
      NotificationService.showError('Cannot update demand status')
      console.error(err)
    }

  }

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Tutor', accessor: (item: dfTutor) => item.tutorId, link: (item: dfTutor) => `/profile/${item.tutorId}`},
    { Header: 'Status', accessor: 'status' },
    { Header: 'Created (by system)', accessor: 'createdAt' },
    { Header: 'Updated (only tutor can)', accessor: (item: dfTutor)=> item.updatedAt !== item.createdAt ? item.updatedAt : null }
  ]

  return (
    <Layout>                        
      <Grid container>
        <Grid item md={6}>
          <Typography variant='h4'>Demand detail</Typography> 
          { !demand && <CircularProgress size={20} thickness={5} />}     
          { demand && (
          <Box display='flex' flexDirection='column' style={{ gap: '1rem'}} mt={2}>                      
            <Typography variant='h6'>ID: {demandId}</Typography>          
            <Typography variant='body1'>CreatedAt:{demand.createdAt}</Typography>          
            <Typography variant='body1'>UpdatedAt: {demand.updatedAt}</Typography>          
            <Typography variant='body1'>Status: {demand.status}</Typography>                    
          </Box>
          )}
        </Grid>
        <Grid item md={6}>
          <Box mb={4} textAlign='right'>
            <RouterLink to={`/demands`}>
              <Button variant='contained'>Back to demands</Button>
            </RouterLink>
          </Box>
          { !demand && <CircularProgress size={20} thickness={5} />}     
          { demand && (
          <GenericForm 
            schema={DemandStatusFormSchema}
            getInitialValues={initialValues}
            validationSchema={validationSchema}
            submitBtnTitle='Update status'              
            onSubmit={onSubmit}
          />)}
        </Grid>
        <Grid item md={12}>
          { !demand && <CircularProgress size={20} thickness={5} />}     
          { demand?.demandFormTutors &&  demand.demandFormTutors.length > 0 && (
            <GenericTable data={demand.demandFormTutors}  columns={columns} />
          )}
        </Grid>
      </Grid>      
    </Layout>
  )
}

export default withApollo(withRouter(DemandDetailPage))