import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Rating, User } from '../types'

type Props = WithStyles<typeof styles> & {
  rating: Rating
  givingUser: User
  ratedUser: User
}

const styles = {
  card: {
    marginBottom: 20
  }
}

const RatingDetail = (props: Props) => {
  const { classes, rating, givingUser, ratedUser } = props
  const givingLink = `/profile/${rating.givingProfileId}`
  const ratedLink = `/profile/${rating.ratedProfileId}`
  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        <Typography variant='h5' component='h5'>
          Giving user: <a href={givingLink}> {`${givingUser.firstName} ${givingUser.lastName}`}</a>
        </Typography>
        <Typography variant='h5' component='h5'>
          Rated user: <a href={ratedLink}> {`${ratedUser.firstName} ${ratedUser.lastName}`}</a>
        </Typography>
        <Typography variant='h5' component='h5'>
          Rating total: {rating.ratingTotal}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(RatingDetail)
