import React from 'react'
import { withRouter } from 'react-router-dom'
import { round } from 'mathjs'
import Layout from '../layouts/Layout'
import { FormikDatePicker, GenericForm, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { withApollo, WithApolloClient } from 'react-apollo'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import Typography from '@material-ui/core/Typography'
import {
  adminGetPayouts_adminGetPayouts as PayoutInfo,
  adminGetPayouts_adminGetPayouts as Payout,
  getProfile_getProfileFixed
} from '../gql/__generated__'
import adminGetPayouts from '../gql/queries/adminGetPayouts'
import { Box, Grid, Paper } from '@material-ui/core'
import TutorPayoutSchema, { initialValues as tutorPayoutValues } from './formSchemas/TutorPayoutSchema'
import adminTutorPayoutMutation from '../gql/mutations/adminTutorPayout'
import NotificationService, { MessageType } from '../services/NotificationService'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import Button from '@material-ui/core/Button'
import GiftTutorSchema, { initialValues as giftInitialValues } from './formSchemas/GiftTutorSchema'
import { formatDate } from '../Utils'
import getTutorPayoutInfoQuery from '../gql/queries/getTutorPayoutInfo'
import insertTransaction from '../gql/mutations/insertTransaction'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
  }>

interface State {
  isLoaded: boolean
  profile: getProfile_getProfileFixed
  payouts: Payout[]
  payoutData: PayoutInfo[]
  totalPerRange: number
  from: string
  to: string
}

class PayoutsPage extends React.Component<Props, State> {
  state: State = {
    payouts: [],
    isLoaded: false,
    profile: {} as getProfile_getProfileFixed,
    payoutData: [],
    totalPerRange: 0,
    from: '',
    to: ''
  }

  componentDidMount = async () => {
    const { match } = this.props
    try {
      await this.getProfile(match.params.profileId)
      await this.getPayouts()
    } catch (err) {
      NotificationService.showError('Error loading data')
    }

    await this.setState({ isLoaded: true })
  }

  getPayouts = async () => {
    const { client, match } = this.props
    const result = await client.query({
      query: adminGetPayouts,
      variables: {
        profileId: match.params.profileId
      }
    })
    this.setState({ payouts: result.data.adminGetPayouts })
  }

  getProfile = async (id: string): Promise<getProfile_getProfileFixed> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    this.setState({ profile: result.data.getProfileFixed })
    return result.data.getProfileFixed
  }

  getTutorPayoutInfo = async (from: Date, until: Date) => {
    const { client } = this.props
    const result = await client.query({
      query: getTutorPayoutInfoQuery,
      variables: {
        from: new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())),
        until: new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate(), 23, 59, 59)),
        tutorUserId: this.state.profile.user.id
      }
    })
    this.setState({ payoutData: result.data.getTutorPayoutInfo })
    this.calculateTotal()
    return result.data.getTutorInvoiceData
  }

  handleTutorPayout = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props

    const fromDate = new Date(this.state.from)
    const toDate = new Date(this.state.to)
    const from = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()))
    const to = new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59))

    try {
      await client.mutate({
        mutation: adminTutorPayoutMutation,
        variables: {
          data: {
            userId: this.state.profile.user.id,
            tutorId: profileId,
            amount: Number(values.amount),
            from,
            to,
            comment: values.comment
          }
        }
      })
      NotificationService.show(MessageType.success, 'Tutor payout successful')
      await this.getTutorPayoutInfo(new Date(this.state.from), new Date(this.state.to))
      await this.getPayouts()
    } catch (err) {
      NotificationService.showError('Tutor payout failed')
    }
  }

  handleSubmitGift = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props

    const toDate = new Date(this.state.to)
    try {
      await client.mutate({
        mutation: insertTransaction,
        variables: {
          data: {
            userId: this.state.profile.user.id,
            profileId,
            amount: Number(values.amount),
            transactionType: 'TutorGift',
            comment: values.comment ? values.comment : null,
            createdAt: new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59))
          }
        }
      })
      NotificationService.show(MessageType.success, 'User gifted successfully')
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Gift tutor failed')
    }
  }

  handleRangeSubmit = async (values: any, actions: FormikHelpers<any>) => {
    await this.getTutorPayoutInfo(new Date(values.from), new Date(values.to))
    this.setState({ from: values.from, to: values.to })
    actions.setSubmitting(false)
  }

  calculateTotal = () => {
    const total = this.state.payoutData.length && this.state.payoutData.reduce((acc, cur) => acc + cur.amount, 0)
    this.setState({ totalPerRange: round(total, 2) })
  }

  getPayoutInitialValues = () => tutorPayoutValues

  getGiftInitialValues = () => giftInitialValues

  render() {
    const columns = [
      {
        Header: 'Amount',
        accessor: 'amount'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'groupId',
        accessor: 'groupId'
      },
      {
        Header: 'Created date',
        accessor: (item: any) => formatDate(item.createdAt)
      }
    ]

    const payoutColumns = [
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Amount',
        accessor: 'amount'
      },
      {
        Header: 'Currency',
        accessor: 'currency'
      },
      {
        Header: 'From',
        accessor: (item: any) => formatDate(item.from)
      },
      {
        Header: 'To',
        accessor: (item: any) => formatDate(item.to)
      },
      {
        Header: 'Created date',
        accessor: (item: any) => formatDate(item.createdAt)
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>Payouts</Typography>
        <UserPagesNavigation title={UserPagesTitles.Payouts} profileId={profileId} />
        <ProfileDetail profile={this.state.profile} profileId={profileId} />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <GenericTable data={this.state.payouts} columns={payoutColumns} title='Payouts' />
            <Paper>
              <Formik
                onSubmit={this.handleRangeSubmit}
                initialValues={{ from: '', to: '' }}
                render={({ isSubmitting }) => (
                  <Form style={{ marginTop: 20 }}>
                    <Box display='flex' justifyContent='flex-start'>
                      <Box mx={2} my='auto'>
                        <Field
                          component={FormikDatePicker}
                          name='from'
                          label='From'
                          fieldProps={{ disableFuture: true, openTo: 'month' }}
                        />
                      </Box>
                      <Box mx={2} my='auto'>
                        <Field
                          component={FormikDatePicker}
                          name='to'
                          label='Until'
                          fieldProps={{ disableFuture: false, openTo: 'month' }}
                        />
                      </Box>
                      <Box my='auto' ml='auto' mr={2}>
                        <Button color='primary' disabled={isSubmitting} type='submit' variant='contained'>
                          &nbsp;{isSubmitting ? 'Saving...' : 'Show list'}&nbsp;
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              />
              <GenericTable
                data={this.state.payoutData}
                columns={columns}
                title='Transactions'
                subtitle={this.state.from ? `Total amount per time period: $${this.state.totalPerRange}` : undefined}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <GenericForm
              getInitialValues={this.getGiftInitialValues}
              schema={GiftTutorSchema}
              onSubmit={this.handleSubmitGift}
            />
            <GenericForm
              getInitialValues={() => this.getPayoutInitialValues}
              schema={TutorPayoutSchema}
              onSubmit={this.handleTutorPayout}
            />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withApollo(withRouter(PayoutsPage))
