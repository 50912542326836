import gql from 'graphql-tag'

export default gql`
  query getAiPricelists {
    getAiPricelists {
      id
      price
      currency
      minutes
      createdAt
      updatedAt    
    }
  }
`