import { WithApolloClient, withApollo } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import Layout from '../layouts/Layout'
import React, { useState } from 'react'
import { TextareaAutosize, Button, Grid, Typography, FormLabel } from '@material-ui/core'
import adminRefreshCourseSearchIndexMutation from '../gql/mutations/adminRefreshCourseSearchIndex'
import adminRefreshCustomSearchIndexMutation from '../gql/mutations/adminRefreshCustomSearchIndex'
import adminGetThumbStatsQuery from '../gql/queries/adminGetThumbStats'
import adminGePackageThumbStatsQuery from '../gql/queries/adminGetPackageThumbStats'

const DEFAULT_PROMPT = '=== Ready: press one of the buttons... ==='

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const appendOutputText = (text: string, previous: string) => { 
  const time = new Date().toLocaleTimeString()
  const date = new Date().toLocaleDateString()
  return previous + (previous !== '' ? '\n' : '') + `[${date} ${time}] ${text}`
}

const getStatsFromResult = (result: any) => {
  const keys = Object.keys(result)
  const stats = ['Returned:', ...keys.filter(key => key !== '__typename').map((key) => ` * ${key}: ${result[key]}`)]
  return stats.join('\n')
}

const ActionsPage = ({ client }: Props) => { 

  const [output, setOutput] = useState<string>(appendOutputText(DEFAULT_PROMPT, ''))
  const [blocked, setBlocked] = useState<boolean>(false)

  const updateOutput = (text: string) => {
    setOutput((oldOutput) => appendOutputText(text, oldOutput))
  }

  const handleCourseIndexRefresh = async () => {
    setBlocked(true)

    updateOutput('Refreshing course index...')
    try {
      const result = await client.mutate({
        mutation: adminRefreshCourseSearchIndexMutation          
      })                  
      setBlocked(false)
      updateOutput('Course index refreshed successfully')
      updateOutput(getStatsFromResult(result.data.adminRefreshCourseSearchIndex))
      updateOutput(DEFAULT_PROMPT)
    } catch (err: any) {
      updateOutput('Failed to refresh course index - consult devs:')
      updateOutput(err.message)
    }        
  }

  const handleCustomIndexRefresh = async () => {
    setBlocked(true)

    updateOutput('Refreshing custom index...')
    try {
      const result = await client.mutate({
        mutation: adminRefreshCustomSearchIndexMutation          
      })                  
      setBlocked(false)
      updateOutput('Custom index refreshed successfully')
      updateOutput(getStatsFromResult(result.data.adminRefreshCustomSearchIndex))
      updateOutput(DEFAULT_PROMPT)
    } catch (err: any) {
      updateOutput('Failed to refresh custom index - consult devs:')
      updateOutput(err.message)
    }
        
  }

  const handleGetUserThumbStats = async () => {
    setBlocked(true)

    updateOutput('Getting user thumb stats...')

    try {
      const result = await client.query({
        query: adminGetThumbStatsQuery          
      })      
      setBlocked(false)
      updateOutput('User Thumb stats retrieved successfully')
      updateOutput(getStatsFromResult(result.data.adminGetThumbStats))
      updateOutput(DEFAULT_PROMPT)
    } catch (err: any) {
      updateOutput('Failed to get user thumb stats - consult devs:')
      updateOutput(err.message)
    }
  }

  const handleGetPackageThumbStats = async () => {
    setBlocked(true)

    updateOutput('Getting package thumb stats...')

    try {
      const result = await client.query({
        query: adminGePackageThumbStatsQuery          
      })      
      setBlocked(false)
      updateOutput('Package Thumb stats retrieved successfully')
      updateOutput(getStatsFromResult(result.data.adminGetPackageThumbStats))
      updateOutput(DEFAULT_PROMPT)
    } catch (err: any) {
      updateOutput('Failed to get package thumb stats - consult devs:')
      updateOutput(err.message)
    }
  }

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Actions
      </Typography>    
      <Grid container spacing={4} alignItems='center'>
        <Grid item xs={12} md={2}>
          <Typography variant='subtitle2'>
            Algolia Search
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button variant='contained' color='primary' disabled={blocked} fullWidth={true} onClick={() => handleCourseIndexRefresh()}>
            Refresh Course Index
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button variant='contained' color='primary' disabled={blocked} fullWidth={true} onClick={() => handleCustomIndexRefresh()}>
            Refresh Custom Index
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems='center'>
        <Grid item xs={12} md={2}>
          <Typography variant='subtitle2'>
            Thumbnails stats
          </Typography>
        </Grid>  
        <Grid item xs={12} md={4}>
          <Button variant='contained' color='primary' disabled={blocked} fullWidth={true} onClick={() => handleGetUserThumbStats()}>
            Get User Thumb Stats
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button variant='contained' color='primary' disabled={blocked} fullWidth={true} onClick={() => handleGetPackageThumbStats()}>
            Get Package Thumb Stats
          </Button>
        </Grid>
      </Grid>
      <FormLabel  style={{ marginTop: '3rem', marginBottom: '1rem', display: 'block' }}>Output</FormLabel>
      <TextareaAutosize rowsMin={10} rowsMax={100} style={{ width: '100%' }} readOnly={true} value={output} />
    </Layout>
  )
}

export default withApollo(ActionsPage)