import { WithApolloClient, withApollo } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { formatDate } from '../Utils'
import React from 'react'
import { Typography } from '@material-ui/core'
import adminGetFutureScheduledCallsQuery from '../gql/queries/adminGetFutureScheduledCalls'
import { TablePaginationProps } from '@material-ui/core/TablePagination'
import NotificationService from '../services/NotificationService'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GenericTable } from '../components'

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

interface State {
  reservations: any[]
  isLoaded: boolean
  pagination: TablePaginationProps
}

class ReservationsPage extends React.Component<Props, State> {
  handleChangeRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
    await this.onUpdate(rowsPerPage, this.state.pagination.page)
  }

  handleChangePage = async (events: any, page: number) => {
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
    await this.onUpdate(this.state.pagination.rowsPerPage, page)
  }

  state = {
    reservations: [],
    isLoaded: false,    
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 15,
      rowsPerPageOptions: [15, 30, 50],
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangePage: this.handleChangePage
    }
  }

  onUpdate = async (rowsPerPage: number, page = 0) => {
    const reservations = await this.getReservations(rowsPerPage, rowsPerPage * page)       
    this.setState({ isLoaded: true, reservations })
    if (reservations.length < rowsPerPage) {
      this.setState({ pagination: { ...this.state.pagination, count: rowsPerPage * page + reservations.length } })
    }
  }

  componentDidMount = async () => {      
    try {      
      await this.onUpdate(this.state.pagination.rowsPerPage)  
    } catch (err) {
      NotificationService.showError('Cannot get reservations')
    }
  }

  getReservations = async (first: number, offset: number) => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetFutureScheduledCallsQuery,
      variables: {
        pageInfo: {
          first,
          offset
        }
      }
    })    
    this.setState({ reservations: result.data.adminGetFutureScheduledCalls })
    return result.data.adminGetFutureScheduledCalls
  }
  
  render() {

    const columns = [
      {
        Header: 'schedule call id',
        accessor: 'id'
      },
      {
        Header: 'tutor',
        accessor: (item:any) => { 
          const tutor = item.participants.find((p:any) => p.role === 'Tutor')
          return tutor?.profileId          
        },
        link: (item:any) => {
          const tutor = item.participants.find((p:any) => p.role === 'Tutor')
          return tutor ? `/profile/${tutor.profileId}` : null
        }
      },
      {
        Header: 'created at',
        accessor: (item:any) => formatDate(item.createdAt)
      },
      {
        Header: 'call start',
        accessor: (item:any) => formatDate(item.start)
      },
      {
        Header: 'call end',
        accessor: (item:any) => formatDate(item.end)
      },
      {
        Header: 'cancelled at',
        accessor: (item:any) => item.cancelledAt ? formatDate(item.cancelledAt) : null
      },
      {
        Header: 'confirmed at',
        accessor: (item:any) => item.confirmedAt ? formatDate(item.confirmedAt) : null
      },
      {
        Header: 'confirmed at',
        accessor: (item:any) => item.confirmedAt ? formatDate(item.confirmedAt) : null
      },
      {
        Header: 'invite message',
        accessor: (item:any) => item.inviteMessage && item.inviteMessage !== '' ? item.inviteMessage : null
      },
      {
        Header: 'tutor\'s cancel reason',
        accessor: (item:any) => {
          const tutor = item.participants.find((p:any) => p.role === 'Tutor')
          if(tutor && tutor.cancellationReason) {
            return tutor.cancellationReason
          } else {
            return null
          }
        }
      },
      {
        Header: 'student\'s reject reason',
        accessor: (item:any) => {
          const student = item.participants.find((p:any) => p.role === 'Student')
          if(student && student.rejectMessage) {
            return student.rejectMessage
          } else {
            return null
          }
        }
      },
      {
        Header: 'student\'s purchaseId',
        accessor: (item:any) => {
          const student = item.participants.find((p:any) => p.role === 'Student')
          if(student && student.purchaseId) {
            return student.purchaseId
          } else {
            return null
          }
        }
      },
      {
        Header: 'package id',
        accessor: (item:any) => item.packageId,
        link: (item:any) => `/package/${item.packageId}`,
      },
      {
        Header: 'student',
        accessor: (item:any) => { 
          const student = item.participants.find((p:any) => p.role === 'Student')
          return student?.profileId         
        },
        link: (item:any) => {
          const student = item.participants.find((p:any) => p.role === 'Student')
          return student ? `/profile/${student.profileId}` : null
        }
      }
    ]

    return (
      <Layout>
        <Typography gutterBottom={true} variant='h4'>
          Reservations
        </Typography>
        <Typography gutterBottom={true} variant='body2'>
          All scheduled calls with start date in the future.
        </Typography>
        {this.state.isLoaded && (
          <div>
            <GenericTable data={this.state.reservations} columns={columns} pagination={this.state.pagination} />
          </div>
        )}
        {!this.state.isLoaded && <CircularProgress size={20} thickness={5} />}
      </Layout>
    )
  }
}

export default withApollo(withRouter(ReservationsPage))