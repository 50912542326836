import Typography from '@material-ui/core/Typography'
import { withStyles, StyleRulesCallback, WithStyles, Theme } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'

const styles: StyleRulesCallback<any, any> = ({ palette, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.error.main,
    borderRadius: 5,
    color: 'white',
    padding: spacing(2),
    marginBottom: spacing(2)
  }
})

type Props = WithStyles<typeof styles> & {
  children: ReactNode
}

const AlertImpl = ({ classes, children }: Props) => (
  <div className={classes.root}>
    <Typography color='inherit' variant='h6'>
      {children}
    </Typography>
  </div>
)

const Alert = withStyles(styles)(AlertImpl)

export { Alert }
