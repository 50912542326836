import gql from "graphql-tag";

export default gql`
  mutation adminUpdateAiConfig($id: String!, $input: AdminAiConfigInput!) {
    adminUpdateAiConfig(id: $id, input: $input) {
      title,
      id,
      config {
        systemPrompt
        firstMessages
        messagesBack
      }
    }
  }
`