import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService from '../services/NotificationService'
import adminGetScheduledCallsByParticipant from '../gql/queries/adminGetScheduledCallsByParticipant'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { getProfile_getProfileFixed } from '../gql/__generated__'
import { TablePaginationProps } from '@material-ui/core/TablePagination'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profile: getProfile_getProfileFixed
  bookings: any[]
  isLoaded: boolean
  pagination: TablePaginationProps
}

class BookingsListPage extends React.Component<Props, State> {
  handleChangeRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
    await this.onUpdate(rowsPerPage, this.state.pagination.page)
  }

  handleChangePage = async (events: any, page: number) => {
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
    await this.onUpdate(this.state.pagination.rowsPerPage, page)
  }
  state = {
    bookings: [],
    isLoaded: false,
    profile: {} as getProfile_getProfileFixed,
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 15,
      rowsPerPageOptions: [15, 30, 50],
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangePage: this.handleChangePage
    }
  }

  onUpdate = async (rowsPerPage: number, page = 0) => {
    const bookings = await this.getBookings(rowsPerPage, rowsPerPage * page)
    this.setState({ isLoaded: true, bookings })
    if (bookings.length < rowsPerPage) {
      this.setState({ pagination: { ...this.state.pagination, count: rowsPerPage * page + bookings.length } })
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    try {
      const profile = await this.getProfile(match.params.profileId)
      this.setState({ profile })
      await this.onUpdate(this.state.pagination.rowsPerPage)
    } catch (err) {
      NotificationService.showError('Cannot get bookings')
    }
  }

  getProfile = async (id: string): Promise<getProfile_getProfileFixed> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  getBookings = async (first: number, offset: number) => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetScheduledCallsByParticipant,
      variables: {
        profileId: this.state.profile.id,
        pageInfo: {
          first,
          offset
        }
      }
    })
    this.setState({ bookings: result.data.adminGetScheduledCallsByParticipant })
    return result.data.adminGetScheduledCallsByParticipant
  }

  render() {
    const profileId = this.props.match.params.profileId
    let profileType = 'Student'
    if (profileId.startsWith('TU')) {
      profileType = 'Tutor'
    }
    const columns = [
      {
        Header: 'schedule call id',
        accessor: 'id'
      },
      {
        Header: 'created at',
        accessor: (item:any) => formatDate(item.createdAt)
      },
      {
        Header: 'call start',
        accessor: (item:any) => formatDate(item.start)
      },
      {
        Header: 'call end',
        accessor: (item:any) => formatDate(item.end)
      },
      {
        Header: 'cancelled at',
        accessor: (item:any) => item.cancelledAt ? formatDate(item.cancelledAt) : null
      },
      {
        Header: 'confirmed at',
        accessor: (item:any) => item.confirmedAt ? formatDate(item.confirmedAt) : null
      },
      {
        Header: 'confirmed at',
        accessor: (item:any) => item.confirmedAt ? formatDate(item.confirmedAt) : null
      },
      {
        Header: 'invite message',
        accessor: (item:any) => item.inviteMessage && item.inviteMessage !== '' ? item.inviteMessage : null
      },
      {
        Header: 'tutor\'s cancel reason',
        accessor: (item:any) => {
          const tutor = item.participants.find((p:any) => p.role === 'Tutor')
          if(tutor && tutor.cancellationReason) {
            return tutor.cancellationReason
          } else {
            return null
          }
        }
      },
      {
        Header: 'student\'s reject reason',
        accessor: (item:any) => {
          const student = item.participants.find((p:any) => p.role === 'Student')
          if(student && student.rejectMessage) {
            return student.rejectMessage
          } else {
            return null
          }
        }
      },
      {
        Header: 'student\'s purchaseId',
        accessor: (item:any) => {
          const student = item.participants.find((p:any) => p.role === 'Student')
          if(student && student.purchaseId) {
            return student.purchaseId
          } else {
            return null
          }
        }
      },
      {
        Header: 'package',
        accessor: (item:any) => item.package?.name || item.packageId,
        link: (item:any) => `/package/${item.packageId}`,
      },
      {
        Header: 'participant',
        accessor: (item:any) => { 
          if(profileType === 'Tutor') {
            const student = item.participants.find((p:any) => p.role === 'Student')
            return student.profile?.user?.overrideName || student.profileId
          } else {
            const tutor = item.participants.find((p:any) => p.role === 'Tutor')
            return tutor.profile?.user?.overrideName || tutor.profileId
          }
        },
        link: (item:any) => {
          if(profileType === 'Tutor') {
            const student = item.participants.find((p:any) => p.role === 'Student')
            return `/profile/${student.profileId}`
          } else {  
            const tutor = item.participants.find((p:any) => p.role === 'Tutor')
            return `/profile/${tutor.profileId}`
          }
        }
      }
    ]

    return (
      <Layout>
        <Typography variant='h4'>Profile detail - list of bookings</Typography>
        {this.state.profile.user && <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Bookings} />}
        {this.state.profile.user && (
          <div>
            <ProfileDetail profile={this.state.profile} profileId={profileId} />
          </div>
        )}
        {!this.state.profile.user && <CircularProgress size={20} thickness={5} />}
        {this.state.isLoaded && (
          <div>
            <GenericTable data={this.state.bookings} columns={columns} pagination={this.state.pagination} />
          </div>
        )}
        {!this.state.isLoaded && <CircularProgress size={20} thickness={5} />}
      </Layout>
    )
  }
}

export default withApollo(withRouter(BookingsListPage))
