import gql from 'graphql-tag'

export default gql`
  mutation adminRefreshCustomSearchIndex {
    adminRefreshCustomSearchIndex {
      activePackageCount
      tutorsWithCustomPackages
      packagesRemoved  
    }
  }
`