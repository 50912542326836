import ButtonLink from './ButtonLink'
import React from 'react'
import { UserPagesTitles } from '../enums'

type Props = {
  profileId: string
  title: UserPagesTitles
}

type Link = {
  link: string
  title: UserPagesTitles
  roles: Role[]
}

type Role = 'student' | 'tutor'

const UserPagesNavigation = (props: Props) => {
  const { profileId, title } = props

  const links: Link[] = [
    { link: `/profile/${profileId}`, title: UserPagesTitles.Overview, roles: ['student', 'tutor'] },
    { link: `/calls/${profileId}`, title: UserPagesTitles.Calls, roles: ['student', 'tutor'] },
    { link: `/bookings/${profileId}`, title: UserPagesTitles.Bookings, roles: ['student', 'tutor'] },
    { link: `/conversations/${profileId}`, title: UserPagesTitles.Conversations, roles: ['student', 'tutor'] },    
    { link: `/ratings/${profileId}`, title: UserPagesTitles.Ratings, roles: ['student', 'tutor'] },
    { link: `/contacts/${profileId}`, title: UserPagesTitles.Contacts, roles: ['student', 'tutor'] },
    { link: `/previews/${profileId}`, title: UserPagesTitles.Previews, roles: ['tutor'] },
    { link: `/status-history/${profileId}`, title: UserPagesTitles.StatusHistory, roles: ['tutor'] },
    { link: `/wallet/${profileId}`, title: UserPagesTitles.WalletDetail, roles: ['student', 'tutor'] },
    { link: `/subscriptions/${profileId}`, title: UserPagesTitles.TutorNotifications, roles: ['student'] },
    { link: `/ban/${profileId}`, title: UserPagesTitles.Ban, roles: ['student'] },
    { link: `/packages/${profileId}`, title: UserPagesTitles.Packages, roles: ['student', 'tutor'] },
    { link: `/referrals/${profileId}`, title: UserPagesTitles.Referrals, roles: ['student'] },
    { link: `/quizzes/${profileId}`, title: UserPagesTitles.Quizzes, roles: ['student'] },
    { link: `/payouts/${profileId}`, title: UserPagesTitles.Payouts, roles: ['tutor'] }
  ]

  const role: Role = profileId.startsWith('TU') ? 'tutor' : 'student'

  const suitableLinks = links.filter((link) => {
    if (role === 'student' && !link.roles.includes('student')) {
      return false
    }
    if (role === 'tutor' && !link.roles.includes('tutor')) {
      return false
    }
    return true
  })

  return (
    <>
      {suitableLinks.map((link) => {
        return (
          <ButtonLink key={link.title} link={link.link} disabled={link.title === title}>
            {link.title}
          </ButtonLink>
        )
      })}
    </>
  )
}

export default UserPagesNavigation
