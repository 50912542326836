import gql from 'graphql-tag'

export default gql`
  query adminFindAllPaymentsByProfileId($profileId: String!) {
    adminFindAllPaymentsByProfileId(profileId: $profileId) {
      id
      amount
      blabuState
      createdAt
      type
      tutorProfileId
      tutor {
        stripeAccountId
      }
      ... on CsobPayment {
        csobGatewayData: gatewayData {
          orderNumber
          paymentId
        }
      }
      ... on PaypalPayment {
        paypalGatewayData: gatewayData {
          captureId
          orderId
          issue
          issueDescription
        }
      }
      ... on SodexoPayment {
        sodexoGatewayData: gatewayData {
          orderNumber
          companyId
          companyOrderId
        }
      }
      ... on PayUPayment {
        payuGatewayData: gatewayData {
          orderId
          refundId
          payMethod
        }
      }
      ... on GalleryBetaPayment {
        gbGatewayData: gatewayData {
          code
        }
      }
      ... on MolliePayment {
        mollieGatewayData: gatewayData {
          method
          id
        }
      }
      ... on StripePayment {
        stripeGatewayData: gatewayData {
          paymentIntentId
        }
      }
    }
  }
`
