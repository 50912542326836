import gql from 'graphql-tag'

export default gql`
  query resolveUsers($ids: [String!]!) {
    resolveUsers(ids: $ids) {
      id
      firstName
      lastName
      credentials {
        identifier
      }
      ban {
        id
      }
      contacts {
        id
        ... on PaymentOptions {
          otherMethodsEnabled
          stripeEnabled
        }
        ... on Email {
          verifiedAt
          email
        }
        ... on Phone {
          phone
        }
        ... on Billing {
          name
          street
          city
          zip
          countryCode
        }
      }
      tutors {
        id
      }
      students {
        id
      }
    }
  }
`
