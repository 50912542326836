import gql from 'graphql-tag'

export default gql`
  query adminGetScheduledCallsByParticipant($profileId: String!, $pageInfo: PageInfo!) {
    adminGetScheduledCallsByParticipant(profileId: $profileId, pageInfo: $pageInfo) {
      id    
      createdAt
      start
      end
      cancelledAt   
      confirmedAt
      packageId
      package(simple: true) {
        name
      }
      inviteMessage              
      participants {
        userId
        role
        profileId
        cancellationReason
        rejectMessage
        purchaseId
        profile {
          __typename
          ... on Tutor {
            user {
              overrideName
            }
          }
          ... on Student {
            user {
              overrideName
            }
          }
        }
      }
    }
  }
`
