import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import React from 'react'
import Layout from '../../layouts/Layout'
import { Box, Button, Typography } from '@material-ui/core'
import { GenericForm, GenericTable } from '../../components'
import { useAiConfigsPage } from './useAiConfigsPageHook'
import { DEFAULT_FIRST_MESSAGES, DEFAULT_SYSTEM_PROMPT, getAiConfigFormSchema } from './util'
import { adminGetAiConfigs_adminGetAiConfigs } from '../../gql/__generated__'

export interface FormSubmitValues {
  id?: string
  title: string
  firstMessage0: string
  firstMessage1: string
  firstMessage2: string
  systemPrompt: string
  messagesBack: number
}

const WARNING_MESSAGE = 'Are you sure you want to delete this AI Config? Check first if it\'s not used in current build!'
const preStyle: React.CSSProperties =  { whiteSpace: 'pre-wrap', background: 'white', padding: '1rem' }

export type Operation = 'create' | 'update'

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const AiConfigsPage = ({ client }: Props) => {
  const { aiConfigs, handleSubmit, handleDelete, initialValues, onEdit, operation } = useAiConfigsPage(client)
  
  const EditBtn = ({ aiConfigId }: { aiConfigId: string }) => {
    return <Button onClick={() => onEdit(aiConfigId)}>Edit</Button>
  }

  const DeleteBtn = ({ aiConfigId }: { aiConfigId: string }) => {
    return <Button onClick={() => window.confirm(WARNING_MESSAGE) && handleDelete(aiConfigId)}>Delete</Button>
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Title',
      accessor: 'title'
    },
    {
      Header: 'First Messages',
      accessor: (item: adminGetAiConfigs_adminGetAiConfigs) => item.config.firstMessages.map((firstMessage, index) => <>#{index+1}: {firstMessage}<br /></>)
    },
    {
      Header: 'System Prompt',
      accessor: 'config.systemPrompt'
    },
    {
      Header: 'Messages Back',
      accessor: 'config.messagesBack'
    },
    {
      Header: 'Edit',      
      accessor: (item: adminGetAiConfigs_adminGetAiConfigs) => <EditBtn aiConfigId={item.id} />
    },
    {
      Header: 'Delete',      
      accessor: (item: adminGetAiConfigs_adminGetAiConfigs) => <DeleteBtn aiConfigId={item.id} />
    }
  ]

  return (
    <Layout>      
      <Typography gutterBottom={true} variant='h4'>
        AI Configs
      </Typography>
      <GenericForm 
        getInitialValues={initialValues}
        schema={
          getAiConfigFormSchema(operation)
        }
        onSubmit={handleSubmit}
        submitBtnTitle={operation === 'create' ? 'Create' : 'Update'}
      />
      <GenericTable data={aiConfigs} columns={columns} />      
      <Box mt={4} >
        <Typography gutterBottom={true} variant='h5'>
          Sample values
        </Typography>
        <Typography gutterBottom={true} variant='body1'>
          Here are some sample values we have used from the start.
        </Typography>
        <Box mt={2}>
          <strong>First messages:</strong>
          <pre style={preStyle}>{DEFAULT_FIRST_MESSAGES.map((message, index) => `#${index+1}: ${message}\n`)}</pre>
        </Box>
        <Box mt={2}>
          <strong>System prompt:</strong>
          <pre style={preStyle}>{DEFAULT_SYSTEM_PROMPT}</pre>
          In System Prompt, pay extra attention to the JSON appendix, it is <strong>SUPER IMPORTANT!</strong>
        </Box>
      </Box>
    </Layout>
  )
}

export default withApollo(AiConfigsPage)
