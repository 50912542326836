import gql from 'graphql-tag'

export default gql`
  query adminGetTransactions($profileId: String!, $pageInfo: PageInfo) {
    adminGetTransactions(profileId: $profileId, pageInfo: $pageInfo) {
      amount
      type
      createdAt
      expiresAt
      sourceId
      comment
    }
  }
`
