import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericForm, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import ProfileDetail from '../components/ProfileDetail'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService from '../services/NotificationService'
import getProfile from '../gql/queries/getProfile'
import AddContactSchema, { initialValues } from './formSchemas/AddContactSchema'
import adminCreateContact from '../gql/mutations/adminCreateContact'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import Typography from '@material-ui/core/Typography'
import { ContactType, getProfile_getProfileFixed as Profile } from '../gql/__generated__'
import Button from '@material-ui/core/Button'
import { formatDate } from '../Utils'
import verifyEmailContact from '../gql/mutations/verifyEmailContact'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
  }>

type State = {
  profile: Profile
  isLoaded: boolean
}

class ContactsPage extends React.Component<Props, State> {
  state = {
    isLoaded: false,
    profile: {} as Profile
  }

  componentDidMount = async () => {
    try {
      await this.getProfile()
      this.setState({ isLoaded: true })
    } catch (err) {
      NotificationService.showError('Cannot get user info')
    }
  }

  getProfile = async () => {
    const { client, match } = this.props
    const result = await client.query({
      query: getProfile,
      variables: { id: match.params.profileId }
    })
    this.setState({ profile: result.data.getProfileFixed })
    return result.data.getProfileFixed
  }

  getInitialValues = () => initialValues

  handleSubmit = async (values: any) => {
    const { client } = this.props
    await client.mutate({
      mutation: adminCreateContact,
      variables: { contactInput: { ...values, userId: this.state.profile.user.id } }
    })
    await this.getProfile()
  }

  omitTypeName = ({ __typename, ...rest }: any) => rest

  handleClick = async (email: string) => {
    const { client } = this.props
    try {
      await client.mutate({
        mutation: verifyEmailContact,
        variables: {
          email,
          userId: this.state.profile.user.id
        }
      })
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Cannot verify contact')
    }
  }

  render() {
    const columns = [
      {
        Header: 'type',
        accessor: '__typename'
      },
      {
        Header: 'contact',
        accessor: (item: any) => JSON.stringify(this.omitTypeName(item))
      },
      {
        Header: 'verified',
        accessor: (item: any) => {
          if (item.__typename === ContactType.Email && !item.verifiedAt) {
            return (
              <Button onClick={() => this.handleClick(item.email)} color='primary' variant='contained' size='small'>
                Verify
              </Button>
            )
          }

          return formatDate(item.verifiedAt)
        }
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    if (this.state.profile.user.contacts) {
      return (
        <Layout>
          <Typography variant='h4'>Profile detail - contacts</Typography>
          <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Contacts} />
          <div>
            <GenericForm
              getInitialValues={this.getInitialValues}
              schema={AddContactSchema}
              onSubmit={this.handleSubmit}
            />
            <ProfileDetail profile={this.state.profile} profileId={profileId} />
            <GenericTable data={this.state.profile.user.contacts} columns={columns} />
          </div>
        </Layout>
      )
    }
  }
}

export default withApollo(withRouter(ContactsPage))
