import { 
  adminGetTopics_adminGetTopics, 
  adminGetAllCourses_adminGetAllCourses,
  CourseTagType,   
  CourseWithTagsAndTranslationsInput
} from "../gql/__generated__";
import { OrganizedTopic } from "../pages/coursesPage/useCoursesPage";
import { LANGUAGE_MAP } from '../pages/coursesPage/courseConfig'


export type FormData = {
  slug: string
  showAsMain: boolean
  flagCode: string | null
  priority: number
  translations_cs_name: string
  translations_en_name: string
  translations_cs_seoTitle?: string
  translations_en_seoTitle?: string
  translations_cs_seoDescription?: string
  translations_en_seoDescription?: string
  tags: {
    slug: string
    type: CourseTagType
    translations_cs_name: string
    translations_en_name: string
  }[]
}

export type ImagesData = {
  image_cs?: string | null
  image_en?: string | null
}

export const emptyFormData:FormData = {
  slug: '',
  translations_cs_name: '',
  translations_en_name: '',
  tags: [],
  priority: 0,
  showAsMain: false,
  flagCode: null
}

export const emptyImagesData:ImagesData = {
  image_cs: '',
  image_en: ''
}

export const emptyCourseData = {
  course: {
    slug: '',
    topicL1: '',
    topicL2: '',
  },
  translations: [],
  tags: []
}

export const organizeTopics = (topics: adminGetTopics_adminGetTopics[]) => {    
  const organized = topics.filter(t => t.topic.parentId !== null).map((t) => {
    const parent = topics.find(pt => pt.topic.id === t.topic.parentId)
    if(!parent) {
      return null
    }
    return {
      topicL2: t.topic.id,
      topicL1: parent.topic.id,
      topicL2Name: t.translations.filter(tr => tr.lang === 'cs')[0].name || '-',
      topicL1Name: parent.translations.filter(tr => tr.lang === 'cs')[0].name || '-',
      hasCourse: false
    }
  }).filter(n => n) as OrganizedTopic[]   
  organized.sort((a, b) => a.topicL1.localeCompare(b.topicL1))
  return organized
}

export const enrichTopicsByCourses = (topics: OrganizedTopic[], courses: adminGetAllCourses_adminGetAllCourses[]) => {
  return topics.map(t => {
    const course = courses.find(({course}) => course.topicL2 === t.topicL2 && course.topicL1 === t.topicL1)
    if(!course) {
      return t
    }
    return {
      ...t,
      hasCourse: true,
      courseId: course.course.id,
      courseSlug: course.course.slug,
      courseName: course.translations.filter(tr => tr.lang === 'cs')[0].name,
      courseLevels: course.tags.filter(t => t.tag.type === 'Level').map(t => t.translations.filter(tr => tr.lang === 'cs')[0].name),
      courseFlags: course.tags.filter(t => t.tag.type === 'Flag').map(t => t.translations.filter(tr => tr.lang === 'cs')[0].name),
      courseShowAsMain: course.course.showAsMain,
      courseFlagCode: course.course.flagCode,
      coursePriority: course.course.priority
    }    
  })
}

const updateTranslationSet = (sourceTranslations: any, fieldName: string, value: string | null, lang: string) => {
  if(sourceTranslations.find((t: any) => t.lang === lang)) {
    return sourceTranslations.map((t: any) => {
      if(t.lang === lang) {
        return {
          ...t,
          [fieldName]: value
        }
      }
      return {...t}
    })
  } else {
    return [
      ...sourceTranslations,
      {
        lang,
        [fieldName]: value
      }
    ]
  }
}

export const convertFormDataToQueryObject = (formData: FormData, topicL1: string, topicL2: string):CourseWithTagsAndTranslationsInput => {
  const transformTranslateKeys = (object: any) => {
    const regex = /translations_(\w+)_(\w+)/
    const result = {
      translations: []
    } as any
    for (const key in object) {
      let value = object[key]
      if(Array.isArray(value)) { 
        value = value.map((item: any) => transformTranslateKeys(item))
      }
      const matches = regex.exec(key);
      if (matches && matches.length > 2) {
        const lang = matches[1]
        const fieldName = matches[2]                
        result.translations = updateTranslationSet(result.translations, fieldName, value, lang)
      } else {
        result[key] = value
      }      
    }
    return result
  }     
  const transformed = transformTranslateKeys(formData)  
  return {
    course: {      
      slug: transformed.slug,
      topicL1,
      topicL2,
      showAsMain: transformed.showAsMain,
      flagCode: transformed.flagCode,
      priority: transformed.priority
    },
    translations: transformed.translations,
    tags: transformed.tags
  }
}

export const convertQueryObjectToFormData = (courseData: CourseWithTagsAndTranslationsInput):FormData => {
  const createTranslateKeys = (object: any[]) => {
    const result = {} as any
    object.forEach((item: any) => {
      if(item.lang) {
        for (const key in item) {
          if(!['lang', '__typename'].includes(key)) {
            result[`translations_${item.lang}_${key}`] = item[key]
          }
        }      
      }
    })    
    return result
  }  
  return {
    slug: courseData.course.slug,
    showAsMain: courseData.course.showAsMain,
    flagCode: courseData.course.flagCode,
    priority: courseData.course.priority,
    ...createTranslateKeys(courseData.translations),
    tags: courseData.tags.map((t) => {
      return {
        slug: t.slug,
        type: t.type,
        ...createTranslateKeys(t.translations)
      }
    })
  }  
}

export const getImageData = (fileValues: any) => {
  const outputData = {} as any
  if(fileValues) {      
    LANGUAGE_MAP.forEach((lang) => {
      if(fileValues) {
        const fieldName = `image_${lang}` as any
        const image = fileValues[fieldName]
        if(image && image[0]) {
          outputData[fieldName] = image[0]
        }
      }      
    })      
  }    
  return outputData
}
