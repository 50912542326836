import React, { useEffect, useState, useRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withApollo, WithApolloClient } from 'react-apollo'

import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import getTutorsQuery from '../gql/queries/getTutors'
import adminGetAllTutorProps from '../gql/queries/adminGetAllTutorProps'
import { Tutor, TutorProps } from '../types'
import PhoneIcon from '@material-ui/icons/Phone'
import MoneyIcon from '@material-ui/icons/Money'
import StarIcon from '@material-ui/icons/Star'
import MessageIcon from '@material-ui/icons/Message'
import VideoIcon from '@material-ui/icons/PersonalVideo'
import ContactsIcon from '@material-ui/icons/Contacts'
import HistoryIcon from '@material-ui/icons/History'
import PackageIcon from '@material-ui/icons/ViewList'
import DollarIcon from '@material-ui/icons/AttachMoney'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import PageItem from '../components/PageItem'
import ButtonLink from '../components/ButtonLink'
import { Tooltip, Typography } from '@material-ui/core'
import { TablePaginationProps } from '@material-ui/core/TablePagination'

const styles = {
  pages: {
    display: 'flex'
  },
  filter: {
    cursor: 'pointer'
  },
  activeFilter: {
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline'
  }
}

type Props = WithApolloClient<{}> & WithStyles

const TutorListPage = ({ client, classes }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [tutors, setTutors] = useState<Tutor[]>()
  const [numberOfTutors, setNumberOfTutors] = useState<number>(0)

  const tutorPropsRef = useRef<TutorProps[]>([])

  const handleChangeOfRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    setIsLoaded(false)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: 0 })
    await onUpdate(rowsPerPage)
  }

  const handleChangePage = async (events: any, page: number) => {
    setIsLoaded(false)
    setPagination({ ...pagination, page: page })
    await onUpdate(pagination.rowsPerPage, page)
  }

  const [pagination, setPagination] = useState<TablePaginationProps>({
    count: 500,
    page: 0,
    rowsPerPage: 100,
    rowsPerPageOptions: [15, 50, 100],
    onChangeRowsPerPage: handleChangeOfRowsPerPage,
    onChangePage: handleChangePage
  })

  const onUpdate = async (rowsPerPage: number, page = 0) => {    
    await getTutors(rowsPerPage, page * rowsPerPage)
    setIsLoaded(true)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: page })
  }

  const loadAllTutorProps = async () => {
    const result = await client.query({
      query: adminGetAllTutorProps      
    })    
    tutorPropsRef.current = result.data.adminGetAllTutorProps
    onUpdate(pagination.rowsPerPage)    
  }

  useEffect(() => {    
    loadAllTutorProps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTutors = async (first: number, offset: number) => {
    setIsLoaded(false)
    const result = await client.query({
      query: getTutorsQuery,
      variables: {
        pageInfo: {
          first,
          offset
        }
      }
    })

    setNumberOfTutors(result.data.adminGetTutorTotalCount)

    const tutors: Tutor[] = result.data.getTutors

    const tutorsWithOnboarding = tutors.map((item: Tutor) => { 
      const tutorProps = tutorPropsRef.current.find((tutorProps: TutorProps) => tutorProps.tutorId === item.id)  
      return {
        ...item,
        TutorProps: tutorProps
      } 
    })

    setTutors(tutorsWithOnboarding)
    // setIsLoaded(true)
  }

  const filter = async (filter: string) => {
    setIsLoaded(false)

    const getTutors = await client.query({
      query: getTutorsQuery,
      variables: {
        pageInfo: {
          first: 500
        }
      }
    })

    const tutors: Tutor[] = getTutors.data.getTutors
    
    const tutorsWithOnboarding = tutors.map((item: Tutor) => { 
      const tutorProps = tutorPropsRef.current.find((tutorProps: TutorProps) => tutorProps.tutorId === item.id)  
      return {
        ...item,
        TutorProps: tutorProps
      } 
    })

    const filtered = tutorsWithOnboarding.filter((item) => item.TutorProps?.[filter as keyof TutorProps] === true)

    setNumberOfTutors(filtered.length)
    setTutors(filtered)
    setIsLoaded(true)
  }

  const [activeP, setActiveP] = useState(false)
  const [activeL, setActiveL] = useState(false)
  const [activeS, setActiveS] = useState(false)
  const [activeB, setActiveB] = useState(false)

  const deactiveFilter = () => {
    setActiveP(false)
    setActiveL(false)
    setActiveS(false)
    setActiveB(false)
  }

  const columns = [
    {
      Header: '#'
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'User',
      accessor: (item: any) => `${item.user.firstName} ${item.user.lastName}`,
      link: (item: any) => `/profile/${item.id}`
    },
    {
      Header: (
        <div
          className={activeP ? classes.activeFilter : classes.filter}
          onClick={() => {
            !activeP && deactiveFilter()
            setActiveP(!activeP)
            activeP ? onUpdate(pagination.rowsPerPage) : filter('completeProfile')
          }}
        >
          P
        </div>
      ),
      accessor: (item: any) => {
        if (item.TutorProps.completeProfile) {
          return (
            <Tooltip title='Profile'>
              <CheckIcon />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Profile'>
              <CloseIcon color='error' />
            </Tooltip>
          )
        }
      }
    },
    {
      Header: (
        <div
          className={activeL ? classes.activeFilter : classes.filter}
          onClick={() => {
            !activeL && deactiveFilter()
            setActiveL(!activeL)
            activeL ? onUpdate(pagination.rowsPerPage) : filter('oneCompletePackage')
          }}
        >
          L
        </div>
      ),
      accessor: (item: any) => {
        if (item.TutorProps.oneCompletePackage) {
          return (
            <Tooltip title='Lesson'>
              <CheckIcon />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Lesson'>
              <CloseIcon color='error' />
            </Tooltip>
          )
        }
      }
    },
    {
      Header: (
        <div
          className={activeS ? classes.activeFilter : classes.filter}
          onClick={() => {
            !activeS && deactiveFilter()
            setActiveS(!activeS)
            activeS ? onUpdate(pagination.rowsPerPage) : filter('connectedStripeAccount')
          }}
        >
          S
        </div>
      ),
      accessor: (item: any) => {
        if (item.TutorProps.connectedStripeAccount) {
          return (
            <Tooltip title='Stripe'>
              <CheckIcon />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Stripe'>
              <CloseIcon color='error' />
            </Tooltip>
          )
        }
      }
    },
    {
      Header: (
        <div
          className={activeB ? classes.activeFilter : classes.filter}
          onClick={() => {
            !activeB && deactiveFilter()
            setActiveB(!activeB)
            activeB ? onUpdate(pagination.rowsPerPage) : filter('completeBilling')
          }}
        >
          B
        </div>
      ),
      accessor: (item: any) => {
        if (item.TutorProps.completeBilling) {
          return (
            <Tooltip title='Billing'>
              <CheckIcon />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Billing'>
              <CloseIcon color='error' />
            </Tooltip>
          )
        }
      }
    },    
    {
      Header: 'Url',
      accessor: (item: any) => <a href={`${process.env.REACT_APP_BASE_URL}u/${item.slug}`}>Url</a>
    },
    {
      Header: 'Email',
      accessor: (item: any) => {
        const passwordCredential = item.user.credentials.find((credential: any) => credential.type === 'Password')
        return passwordCredential ? passwordCredential.identifier : ''
      }
    },
    {
      Header: 'pages',
      accessor: (item: any) => {
        return (
          <div className={classes.pages}>
            <PageItem icon={<MoneyIcon />} title='Wallets' link={`wallet/${item.id}`} />
            <PageItem icon={<PhoneIcon />} title='Calls' link={`profile/${item.id}`} />
            <PageItem icon={<StarIcon />} title='Ratings' link={`ratings/${item.id}`} />
            <PageItem icon={<MessageIcon />} title='Messages' link={`conversations/${item.id}`} />
            <PageItem icon={<VideoIcon />} title='Previews' link={`previews/${item.id}`} />
            <PageItem icon={<ContactsIcon />} title='Contacts' link={`contacts/${item.id}`} />
            <PageItem icon={<HistoryIcon />} title='Online status history' link={`status-history/${item.id}`} />
            <PageItem icon={<PackageIcon />} title='Packages' link={`packages/${item.id}`} />
            <PageItem icon={<DollarIcon />} title='Payouts' link={`payouts/${item.id}`} />
          </div>
        )
      }
    }
  ]

  return (
    <Layout>
      <Typography variant='h4'>Tutors</Typography>
      <p>
        <ButtonLink link='/tutor/create'>Create Tutor</ButtonLink>
        <ButtonLink link='/tutors/online'>Online Tutors</ButtonLink>
      </p>
      {!isLoaded ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <GenericTable data={tutors} columns={columns} pagination={pagination} numberOfItems={numberOfTutors} />
      )}
    </Layout>
  )
}

export default withStyles(styles)(withApollo(TutorListPage))
