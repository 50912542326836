import React from 'react'
import { FieldProps, getIn } from 'formik'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

type FieldInputProps = FieldProps &
  TextFieldProps & {
    selectOptions?: Array<{ value: string; label: string }>
  }

const FormikField = ({
  // Formik field props
  field: fieldProps,
  form: { touched, errors },
  // Text field props
  children,
  label,
  helperText,
  variant,
  select,
  selectOptions,
  type,
  ...props
}: FieldInputProps) => {
  const fieldTouched = getIn(touched, fieldProps.name)
  const error = getIn(errors, fieldProps.name)

  if (select) {
    if (!selectOptions || !selectOptions.length) {
      throw new Error('You have to pass selectOptions to FormikField with select=true')
    }

    children = selectOptions.map(({ label, value }, key) => (
      <MenuItem key={key} value={value}>
        {label}
      </MenuItem>
    ))
  }

  const textFieldProps = {
    children,
    error: Boolean(fieldTouched && error),
    helperText: fieldTouched && error ? error : helperText,
    fullWidth: true,
    margin: 'normal',
    label: label,
    type: type,
    select: select,
    variant: variant || 'outlined',
    ...fieldProps,
    ...props
  }

  return <TextField {...(textFieldProps as TextFieldProps)} />
}

export { FormikField }
