import { FormSchema } from '../../components'

const initialValues = {
  amount: 0.01,
  expiresAt: null,
  comment: ''
}

const GiftTutorSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Give bonus',
      fields: [
        {
          label: 'Amount($)',
          accessor: 'amount'
        },
        {
          label: 'comment',
          accessor: 'comment'
        }
      ]
    }
  ]
}

export default GiftTutorSchema

export { initialValues }
