import gql from 'graphql-tag'

export default gql`
  query adminGetConversations($userId: String!, $pageInfo: PageInfo) {
    adminGetConversations(userId: $userId, pageInfo: $pageInfo) {
      id
      lastMessageAt
      user {
        firstName
        lastName
        displayName
        credentials {
          identifier
        }
        tutors {
          id
        }
        students {
          id
        }
      }
      createdAt      
    }
  }
`
