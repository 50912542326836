import { FormSchema } from '../../components'
import * as Yup from 'yup'

const initialValues = {
  fee: ''  
}

const FeeFormSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Custom fee setting',
      fields: [
        {
          label: 'Fee (percent)',
          accessor: 'fee',
          fieldProps: {
            type: 'number',
            min: 0,
            max: 100,
          }
        },        
      ]
    }
  ]
}

export default FeeFormSchema

const validationSchema = Yup.object().shape({
  fee: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return String(originalValue).trim() === '' ? null : value
    })
    .min(0, 'Fee must be greater than or equal to 0')
    .max(100, 'Fee must be less than or equal to 100')
    .notRequired()
})

export { initialValues, validationSchema }
