import gql from 'graphql-tag'

export default gql`
  query resolveId($id: String!) {
    resolveId(id: $id) {
      id
      type
      errorMessage
    }
  }
`
