import { RouteComponentProps, withRouter } from 'react-router-dom'
import { WithApolloClient, withApollo } from 'react-apollo'

import React from 'react'
import cookie from 'cookie'
import generateGoogleAuthUrl from '../gql/queries/generateGoogleAuthUrl'
import googleLogin from '../gql/queries/googleLogin'
import queryString from 'query-string'

const isLocal = process.env.REACT_APP_LOCAL === 'true' ? true : null

class OAuthGooglePage extends React.Component<WithApolloClient<{}> & RouteComponentProps, { error: string }> {
  constructor(props: any) {
    super(props)

    this.state = {
      error: ''
    }
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)

    if (params.code) {
      this.props.client
        .query({
          query: googleLogin,
          variables: {
            code: params.code,
            isLocal
          }
        })
        .then((response) => {
          if (response.errors) {
            let errorString = ''
            response.errors.map((error) => (errorString += error.message))
            this.setState({ error: errorString })
          }

          if (response.data.googleLogin) {
            document.cookie = cookie.serialize('admin_token', response.data.googleLogin.token, {
              path: '/',
              expires: new Date(response.data.googleLogin.expiresAt)
            })
            window.location.href = '/'
          }
        })
        .catch((err) => {
          this.setState({ error: err.message })
        })
    } else {
      this.props.client
        .query({
          query: generateGoogleAuthUrl,
          variables: {
            isLocal
          }
        })
        .then((response) => {
          window.location.href = response.data.generateGoogleAuthUrl
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
  }

  render() {
    return this.state.error
  }
}

export default withApollo(withRouter(OAuthGooglePage))
