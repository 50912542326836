import gql from 'graphql-tag'

export default gql`
  query getTutorPayoutInfo($from: DateTime!, $until: DateTime!, $tutorUserId: String!) {
    getTutorPayoutInfo(from: $from, until: $until, tutorUserId: $tutorUserId) {
      id
      amount
      groupId
      type
      createdAt
    }
  }
`
