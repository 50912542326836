import React from 'react'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { GenericForm } from '../components'
import ProfileDetail from '../components/ProfileDetail'
import Layout from '../layouts/Layout'
import { RouteComponentProps, withRouter } from 'react-router'
import BanFormSchema, { initialValues } from './formSchemas/BanFormSchema'
import { withApollo, WithApolloClient } from 'react-apollo'
import banUserMutation from '../gql/mutations/banUser'
import deleteUserMutation from '../gql/mutations/deleteUser'
import NotificationService from '../services/NotificationService'
import CircularProgress from '@material-ui/core/CircularProgress'
import SimpleCardWithText from '../components/SimpleCardWithText'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { CardActions, WithStyles, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { getProfile_getProfileFixed } from '../gql/__generated__'
import getProfile from '../gql/queries/getProfile'

const styles = {
  card: {
    padding: 16
  }
}

type Props = WithApolloClient<{}> & WithStyles & RouteComponentProps<{ profileId: string }> & {}

interface State {
  profile: getProfile_getProfileFixed
  isLoaded: boolean
}

class BanPage extends React.Component<Props, State> {
  state = {
    profile: {} as getProfile_getProfileFixed,
    isLoaded: false
  }

  componentDidMount = async () => {
    try {
      await this.getProfile()
      this.setState({ isLoaded: true })
    } catch (err) {
      NotificationService.showError('Cannot get user info')
    }
  }

  getProfile = async () => {
    const { client, match } = this.props
    const result = await client.query({
      query: getProfile,
      variables: { id: match.params.profileId }
    })
    this.setState({ profile: result.data.getProfileFixed })
    return result.data.getProfileFixed
  }

  handleBan = async (values: any) => {
    const { client } = this.props
    try {
      await client.mutate({
        mutation: banUserMutation,
        variables: {
          banInput: {
            userId: this.state.profile.user.id,
            comment: values.comment ? values.comment : null
          }
        }
      })
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Error when banning user')
    }
  }

  handleDelete = async () => {
    const { client, history } = this.props
    try {
      await client.mutate({
        mutation: deleteUserMutation,
        variables: {
          id: this.state.profile.user.id
        }
      })
      history.push('/')
      NotificationService.showSuccess('User successfully deleted')
    } catch (err) {
      NotificationService.showError('Error when deleting user')
    }
  }

  getInitialValues = () => initialValues

  render() {
    const { match, classes } = this.props
    const profileId = match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    const form = () => (
      <div>
        <GenericForm
          submitBtnTitle='Ban'
          getInitialValues={this.getInitialValues}
          schema={BanFormSchema}
          onSubmit={this.handleBan}
        />
      </div>
    )

    return (
      <Layout>
        <Typography variant='h4'>Ban user</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Ban} />
        <ProfileDetail profile={this.state.profile} profileId={profileId} />
        {this.state.profile.user.ban ? <SimpleCardWithText text={'This user is already banned.'} /> : form()}
        <Card>
          <CardContent>
            <Typography variant='h3'>Delete user</Typography>
            <Typography variant='body1'>Also automatically bans user. This operation is irreversible.</Typography>
          </CardContent>
          <CardActions classes={{ root: classes.card }}>
            <Button onClick={this.handleDelete} color='primary' variant='contained'>
              Delete
            </Button>
          </CardActions>
        </Card>
      </Layout>
    )
  }
}

export default withStyles(styles)(withApollo(withRouter(BanPage)))
