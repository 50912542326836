import { DemandStatus } from "../../gql/__generated__";
import * as Yup from 'yup'

export type DemandStatusFormValues = {
  status: DemandStatus
}

export const initialValues: DemandStatusFormValues = {
  status: DemandStatus.Archived
}

const DemandStatusFormSchema = {
  fieldsets: [
    {
      title: 'Status settings',
      fields: [
        {
          label: 'Change status',
          accessor: 'status',
          fieldProps: {            
            select: true,
            selectOptions: [
              { value: DemandStatus.RequestedAgain, label: 'RequestedAgain (request on new tutors)' },
              { value: DemandStatus.Archived, label: 'Archive (hide)' },              
            ]
          }
        }
      ]
    }
  ]
}

export default DemandStatusFormSchema

export const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required('Status is required')
    .oneOf([DemandStatus.RequestedAgain, DemandStatus.Archived], 'Invalid status')
})