import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService from '../services/NotificationService'
import adminGetConversations from '../gql/queries/adminGetConversations'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { getProfile_getProfileFixed } from '../gql/__generated__'
import { TablePaginationProps } from '@material-ui/core/TablePagination'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profile: getProfile_getProfileFixed
  conversations: any[]
  isLoaded: boolean
  pagination: TablePaginationProps
}

class ConversationsListPage extends React.Component<Props, State> {
  handleChangeRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
    await this.onUpdate(rowsPerPage, this.state.pagination.page)
  }

  handleChangePage = async (events: any, page: number) => {
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
    await this.onUpdate(this.state.pagination.rowsPerPage, page)
  }
  state = {
    conversations: [],
    isLoaded: false,
    profile: {} as getProfile_getProfileFixed,
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 15,
      rowsPerPageOptions: [15, 30, 50],
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangePage: this.handleChangePage
    }
  }

  onUpdate = async (rowsPerPage: number, page = 0) => {
    const conversations = await this.getConversations(rowsPerPage, rowsPerPage * page)
    this.setState({ isLoaded: true, conversations })
    if (conversations.length < rowsPerPage) {
      this.setState({ pagination: { ...this.state.pagination, count: rowsPerPage * page + conversations.length } })
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    try {
      const profile = await this.getProfile(match.params.profileId)
      this.setState({ profile })
      await this.onUpdate(this.state.pagination.rowsPerPage)
    } catch (err) {
      NotificationService.showError('Cannot get conversations')
    }
  }

  getProfile = async (id: string): Promise<getProfile_getProfileFixed> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  getConversations = async (first: number, offset: number) => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetConversations,
      variables: {
        userId: this.state.profile.user.id,
        pageInfo: {
          first,
          offset
        }
      }
    })
    this.setState({ conversations: result.data.adminGetConversations })
    return result.data.adminGetConversations
  }

  render() {
    const profileId = this.props.match.params.profileId
    let profileType = 'Student'
    if (profileId.startsWith('ST')) {
      profileType = 'Tutor'
    }
    const columns = [
      {
        Header: 'conversationId',
        accessor: 'id'
      },
      {
        Header: profileType,
        accessor: (item: any) => `${item.user.displayName ?? item.user.credentials.identifier}`,
        link: (item: any) => {
          if (item.user.tutors.length) {
            return `/profile/${item.user.tutors[0].id}`
          }
          return `/profile/${item.user.students[0].id}`
        }
      },
      {
        Header: 'lastMessageAt',
        accessor: (item: any) => {
          return formatDate(item.lastMessageAt)
        }
      },
      {
        Header: 'messages',
        accessor: (item: any) => `Messages`,
        link: (item: any) => `/conversation/${item.id}`
      }
    ]

    return (
      <Layout>
        <Typography variant='h4'>Profile detail - list of conversations</Typography>
        {this.state.profile.user && <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Conversations} />}
        {this.state.profile.user && (
          <div>
            <ProfileDetail profile={this.state.profile} profileId={profileId} />
          </div>
        )}
        {!this.state.profile.user && <CircularProgress size={20} thickness={5} />}
        {this.state.isLoaded && (
          <div>
            <GenericTable data={this.state.conversations} columns={columns} pagination={this.state.pagination} />
          </div>
        )}
        {!this.state.isLoaded && <CircularProgress size={20} thickness={5} />}
      </Layout>
    )
  }
}

export default withApollo(withRouter(ConversationsListPage))
