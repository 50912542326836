import { GenericTable } from './GenericTable'
import React, { useEffect, useState } from 'react'
import getPackagesQuery from '../gql/queries/getPackagesByTutorId'
import { withApollo, WithApolloClient } from 'react-apollo'
import { getPackagesByTutorId_getPackagesByTutorId as Package, adminGetAllCourses_adminGetAllCourses as Course } from '../gql/__generated__'
import Button from '@material-ui/core/Button'
import { RouterLink } from './RouterLink'
import { TablePaginationProps } from '@material-ui/core/TablePagination'

type Props = WithApolloClient<{}> & {
  profileId: string
  allCourses: Course[]
}

const TutorPackagesContent = ({ client, profileId, allCourses }: Props) => {
  const [packages, setPackages] = useState<Package[]>()
  const [isLoaded, setIsLoaded] = useState(false)

  const handleChangeOfRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    setIsLoaded(false)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: 0 })
    await onUpdate(rowsPerPage)
  }

  const handleChangePage = async (events: any, page: number) => {
    setIsLoaded(false)
    setPagination({ ...pagination, page: page })
    await onUpdate(pagination.rowsPerPage, page)
  }

  const [pagination, setPagination] = useState<TablePaginationProps>({
    count: 500,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 50, 100],
    onChangeRowsPerPage: handleChangeOfRowsPerPage,
    onChangePage: handleChangePage
  })

  const onUpdate = async (rowsPerPage: number, page = 0) => {
    await getPackages(rowsPerPage, page * rowsPerPage)
    setIsLoaded(true)
    setPagination({ ...pagination, rowsPerPage: rowsPerPage, page: page })
  }

  useEffect(() => {
    onUpdate(pagination.rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.rowsPerPage])

  const getPackages = async (first: number, offset: number) => {
    const result = await client.query({
      query: getPackagesQuery,
      variables: {
        tutorId: profileId,
        pageInfo: {
          first,
          offset
        }
      }
    })
    setPackages(result.data.getPackagesByTutorId)
  }

  const EditBtn = ({ packageId }: { packageId: string }) => {
    return (
      <RouterLink to={'/package/' + packageId}>
        <Button>Edit</Button>
      </RouterLink>
    )
  }

  const columns = [
    {
      Header: 'id',
      accessor: (item: any) => (
        <a href={`${process.env.REACT_APP_BASE_URL}lessons/${item.id}`} target='_blank' rel='noreferrer'>
          {item.id}
        </a>
      )
    },
    {
      Header: 'name',
      accessor: 'name'
    },
    {
      Header: 'description',
      accessor: 'description'
    },
    {
      Header: 'prerequisites',
      accessor: 'prerequisites'
    },
    {
      Header: 'topics',
      accessor: (item: any) => item.topics.map((topic: any) => topic.name)
    },
    {
      Header: 'course',
      accessor: (item: any) => { 
        if(item.courseId) {
          const courseName = allCourses.find((course: Course) => course.course.id === item.courseId)
            ?.translations.filter((tr) => tr.lang === 'cs')[0]?.name
          return (
            <a href={`/courses/edit/${item.courseId}`} target='_blank' rel='noreferrer'>
              {courseName}
            </a>
          )
        } else {
          return '-'
        }
      }
    },
    {
      Header: 'lessons',
      accessor: (item: any) => {
        const lessonCount = item.lessons.length
        const lessonDuration = item.lessons[0]?.units

        return `${lessonCount} x ${lessonDuration}`
      }
    },
    {
      Header: 'global visibility',
      accessor: 'globalVisibility'
    },
    {
      Header: 'price',
      accessor: 'defaultPrice'
    },
    {
      Header: 'currency',
      accessor: 'defaultCurrency'
    },
    {
      Header: 'active',
      accessor: (item: any) => item.active ? 'true' : null
    },
    {
      Header: 'firstLessonFree',
      accessor: (item: any) => item.firstLessonFree ? 'true' : null
    },
    {
      Header: 'Score',
      accessor: (item: any) => String(item.score)
    },
    {
      Header: 'Edit',
      accessor: (item: any) => <EditBtn packageId={item.id} />
    }
  ]

  if (!isLoaded) {
    return null
  }

  return <GenericTable data={packages} columns={columns} pagination={pagination} />
}

export default withApollo(TutorPackagesContent)
