import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import { Subscription } from '../types'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import adminGetActiveSubscriptionsQuery from '../gql/queries/adminGetActiveSubscriptions'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import adminDeleteSubscriptionMutation from '../gql/mutations/adminDeleteSubscription'
import Typography from '@material-ui/core/Typography'
import { getProfile_getProfileFixed } from '../gql/__generated__'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profileId: string
  profile: getProfile_getProfileFixed
  isLoaded: boolean
  activeSubscriptions: Subscription[]
}

class SubscriptionsPage extends React.Component<Props, State> {
  state = {
    profileId: '',
    profile: {} as getProfile_getProfileFixed,
    activeSubscriptions: [],
    isLoaded: false
  }

  componentDidMount = async () => {
    await this.setState({ profileId: this.props.match.params.profileId })
    try {
      await this.getProfile()
      await this.getActiveSubscriptions()
    } catch (err) {
      NotificationService.showError(err)
    }
    this.setState({ isLoaded: true })
  }

  getProfile = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getProfile,
      variables: {
        id: this.state.profileId
      }
    })
    this.setState({ profile: result.data.getProfileFixed })
  }

  getActiveSubscriptions = async () => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetActiveSubscriptionsQuery,
      variables: {
        userId: this.state.profile.user.id
      }
    })
    this.setState({ activeSubscriptions: result.data.adminGetActiveSubscriptions })
  }

  handleDelete = async (subscriptionId: string) => {
    const { client } = this.props
    try {
      await client.mutate({
        mutation: adminDeleteSubscriptionMutation,
        variables: {
          id: subscriptionId
        }
      })
    } catch (err) {
      NotificationService.showError(err)
    }
    await this.getActiveSubscriptions()
    NotificationService.showSuccess('Subscription deleted')
  }

  render() {
    const columns = [
      {
        Header: 'Subscribed To',
        accessor: (item: any) => `${item.profile.user.firstName} ${item.profile.user.lastName}`,
        link: (item: any) => `/profile/${item.subscribedToProfileId}`
      },
      {
        Header: 'createdAt',
        accessor: (item: any) => formatDate(item.createdAt)
      },
      {
        Header: 'Delete',
        accessor: (item: any) => {
          return (
            <IconButton onClick={() => this.handleDelete(item.id)}>
              <DeleteIcon />
            </IconButton>
          )
        }
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>{UserPagesTitles.TutorNotifications}</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.TutorNotifications} />
        <ProfileDetail profile={this.state.profile} profileId={profileId} />
        <GenericTable data={this.state.activeSubscriptions} columns={columns} />
      </Layout>
    )
  }
}

export default withApollo(withRouter(SubscriptionsPage))
