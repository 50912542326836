import Typography from '@material-ui/core/Typography'
import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import adminGetTutorQuery from '../gql/queries/adminGetTutor'

import Layout from '../layouts/Layout'
import { GenericForm, RouterLink } from '../components'
import TutorFormSchema from './formSchemas/TutorFormSchema'
import { removeTypenameFromData } from '../api/GraphQLUtils'
import NotificationService, { MessageType } from '../services/NotificationService'
import Button from '@material-ui/core/Button'
import adminUpdateTutorMutation from '../gql/mutations/adminUpdateTutor'

interface Data {
  adminGetTutor: any
}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    tutorId: string
  }>

const TutorUpdatePage = ({ client, history, match }: Props) => {
  const getInitialValues = () => {
    return client
      .query<Data>({
        query: adminGetTutorQuery,
        variables: { id: match.params.tutorId }
      })
      .then(({ data }) => removeTypenameFromData(data.adminGetTutor))
  }

  const handleSubmit = (values: any) => {
    const image = values.user.image && values.user.image[0] ? values.user.image[0] : undefined
    delete values.user.image
    delete values.topics // We want to send only topicIds as an argument

    values.user.credential = values.user.credentials?.[0] || null
    delete values.user.credentials

    return client
      .mutate({
        mutation: adminUpdateTutorMutation,
        variables: {
          tutor: values,
          image
        }
      })
      .then((data) => {
        NotificationService.show(MessageType.success, 'Saved successfully')
        history.push('/tutors')

        return data
      })
  }

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h1'>
        Edit tutor
        <RouterLink to='/tutors'>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to tutors
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm getInitialValues={getInitialValues} schema={TutorFormSchema} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withApollo(TutorUpdatePage)
