import gql from 'graphql-tag'

export default gql`
  fragment PackageUpdateFragment on Package {
    id
    tutorId
    tutorUserId
    active
    name
    duration
    numOfLessons
    defaultPrice
    defaultCurrency
    description
    prerequisites
    level
    score
    topicIds
  }
`
