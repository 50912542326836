import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Layout from '../../layouts/Layout'
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import RjsfForm from 'rjsf-material-ui'
import generateCourseSchema from './courseSchema'
import { convertFormDataToQueryObject, FormData, getImageData, emptyFormData, emptyImagesData, ImagesData  } from '../../utils/courseHelpers'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useCourseForm } from './useCourseForm'
import { FormikUploadField } from '../../components'
import { Form, Formik, Field, FormikProps } from 'formik'
import { LANGUAGE_MAP } from './courseConfig'

type Params = {  
  topicL1: string
  topicL2: string
}

type Props = WithApolloClient<{}> & RouteComponentProps<Params>

const schema = generateCourseSchema(LANGUAGE_MAP)

const CourseCreatePage = ({ client, match,  }: Props) => {   
  const { topicL1, topicL2 } = match.params
  const [formData, setFormData] = useState<FormData>(emptyFormData)  
  const { handleSubmit, progress, shouldRedirect } = useCourseForm(client)
  const history = useHistory();

  const onSubmit = ({ formData }:any) => {
    const result = convertFormDataToQueryObject(formData as FormData, topicL1, topicL2)     
    handleSubmit(result, getImageData(formikRef.current?.values))    
  }

  const formikRef = useRef<FormikProps<ImagesData>>(null)

  useEffect(() => {
    if (shouldRedirect) {
      history.push(`/courses`)
    }
  }, [shouldRedirect, history])

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Create new Course
      </Typography>
      <>
        <Formik key='new' innerRef={formikRef} initialValues={emptyImagesData} onSubmit={() => {}}>
          <Form>
            { LANGUAGE_MAP.map((lang) => 
              <Field component={FormikUploadField} key={`new_image_${lang}`} name={`image_${lang}`} label={`SEO Image ${lang}`} />
            )}
          </Form>
        </Formik>
        <RjsfForm schema={schema as any} disabled={progress !== 'idle'} formData={formData} onSubmit={onSubmit}> 
          <Box mt={5} mb={10}>
            <Button variant='contained' color='primary' type='submit'>
              Submit
            </Button>
            <Button variant='text' onClick={() => setFormData(emptyFormData)}>
              Clear form
            </Button>     
          </Box>
        </RjsfForm>
      </>      
    </Layout>)
}

export default withApollo(CourseCreatePage)