import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withApollo, Query, WithApolloClient } from 'react-apollo'

import Layout from '../layouts/Layout'
import { Alert, GenericTable } from '../components'
import Button from '@material-ui/core/Button'
import { RouteComponentProps } from 'react-router-dom'
import getCredentialsWithoutHashQuery from '../gql/queries/getCredentialsWithoutHash'
import sendVerificationMutation from '../gql/mutations/sendVerification'

interface Data {
  getCredentialsWithoutHash: Array<any>
}

class VerifyUsersQuery extends Query<Data> {}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    tutorId: string
  }>

class VerifyUsersPage extends React.Component<Props> {
  handleClick = async (item: any) => {
    const { client } = this.props
    await client.mutate({
      mutation: sendVerificationMutation,
      variables: {
        identifier: item.identifier
      }
    })
  }
  render() {
    const columns = [
      {
        Header: 'identifier',
        accessor: (item: any) => item.identifier
      },
      {
        Header: 'Verify',
        accessor: (item: any) => (
          <Button color='secondary' variant='contained' size='small' onClick={async () => await this.handleClick(item)}>
            Verify
          </Button>
        )
      }
    ]
    return (
      <Layout>
        <VerifyUsersQuery query={getCredentialsWithoutHashQuery}>
          {({ data, loading, error }) => {
            return (
              <div>
                {loading && <CircularProgress size={20} thickness={5} />}
                {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
                {!loading && data && (
                  <div>
                    <GenericTable data={data.getCredentialsWithoutHash} columns={columns} />
                  </div>
                )}
              </div>
            )
          }}
        </VerifyUsersQuery>
      </Layout>
    )
  }
}

export default withApollo(VerifyUsersPage)
