/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPromoCredit
// ====================================================

export interface addPromoCredit {
  addPromoCredit: boolean;
}

export interface addPromoCreditVariables {
  data: PromoCreditInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCancelShift
// ====================================================

export interface adminCancelShift_adminCancelShift {
  __typename: "Shift";
  id: string;
}

export interface adminCancelShift {
  adminCancelShift: adminCancelShift_adminCancelShift;
}

export interface adminCancelShiftVariables {
  shiftId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateAiConfig
// ====================================================

export interface adminCreateAiConfig_adminCreateAiConfig {
  __typename: "AiConfig";
  id: string;
}

export interface adminCreateAiConfig {
  adminCreateAiConfig: adminCreateAiConfig_adminCreateAiConfig;
}

export interface adminCreateAiConfigVariables {
  input: AdminAiConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateAiPricelist
// ====================================================

export interface adminCreateAiPricelist_adminCreateAiPricelist {
  __typename: "AiPricelist";
  id: string;
}

export interface adminCreateAiPricelist {
  adminCreateAiPricelist: adminCreateAiPricelist_adminCreateAiPricelist;
}

export interface adminCreateAiPricelistVariables {
  input: AiPricelistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateContact
// ====================================================

export interface adminCreateContact {
  /**
   * Supports contacts of type: Email (default), Phone, Fee
   */
  adminCreateContact: boolean;
}

export interface adminCreateContactVariables {
  contactInput: ContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateCourseWithTranslations
// ====================================================

export interface adminCreateCourseWithTranslations_adminCreateCourseWithTranslations {
  __typename: "Course";
  id: string;
}

export interface adminCreateCourseWithTranslations {
  adminCreateCourseWithTranslations: adminCreateCourseWithTranslations_adminCreateCourseWithTranslations;
}

export interface adminCreateCourseWithTranslationsVariables {
  input: CourseWithTagsAndTranslationsInput;
  image_cs?: any | null;
  image_en?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateGroup
// ====================================================

export interface adminCreateGroup_adminCreateGroup {
  __typename: "Group";
  id: string;
}

export interface adminCreateGroup {
  adminCreateGroup: adminCreateGroup_adminCreateGroup;
}

export interface adminCreateGroupVariables {
  name: string;
  type: GroupType;
  groupInput: GroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreatePreview
// ====================================================

export interface adminCreatePreview_adminCreatePreview {
  __typename: "VideoPreview";
  fileName: string;
}

export interface adminCreatePreview {
  adminCreatePreview: adminCreatePreview_adminCreatePreview;
}

export interface adminCreatePreviewVariables {
  videoPreviewInput: VideoPreviewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateStripeBusinessAccount
// ====================================================

export interface adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount_account {
  __typename: "StripeAccount";
  id: string;
  business_type: StripeBusinessType | null;
  country: string;
}

export interface adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount_error {
  __typename: "StripeError";
  type: StripeErrorType;
  code: string | null;
  decline_code: string | null;
  param: string | null;
  message: string;
}

export interface adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount {
  __typename: "StripeAccountResponse";
  id: string;
  account: adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount_account | null;
  error: adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount_error | null;
}

export interface adminCreateStripeBusinessAccount {
  adminCreateStripeBusinessAccount: adminCreateStripeBusinessAccount_adminCreateStripeBusinessAccount;
}

export interface adminCreateStripeBusinessAccountVariables {
  userId: string;
  tutorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminCreateTopic
// ====================================================

export interface adminCreateTopic_adminCreateTopicWithTranslations_topic {
  __typename: "Topic";
  id: string;
}

export interface adminCreateTopic_adminCreateTopicWithTranslations {
  __typename: "TopicWithTranslations";
  topic: adminCreateTopic_adminCreateTopicWithTranslations_topic;
}

export interface adminCreateTopic {
  adminCreateTopicWithTranslations: adminCreateTopic_adminCreateTopicWithTranslations;
}

export interface adminCreateTopicVariables {
  topicInput: TopicWithTranslationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TutorCreateMutation
// ====================================================

export interface TutorCreateMutation_adminCreateTutor_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface TutorCreateMutation_adminCreateTutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  countryCode: string | null;
  middleName: string | null;
  gender: Gender | null;
  birthday: any | null;
  timezone: string | null;
  image: string | null;
  credentials: TutorCreateMutation_adminCreateTutor_user_credentials[] | null;
}

export interface TutorCreateMutation_adminCreateTutor {
  __typename: "Tutor";
  id: string;
  language: string;
  introduction: string | null;
  rating: number;
  score: number | null;
  blockingTime: number;
  user: TutorCreateMutation_adminCreateTutor_user;
}

export interface TutorCreateMutation {
  adminCreateTutor: TutorCreateMutation_adminCreateTutor | null;
}

export interface TutorCreateMutationVariables {
  tutor: AdminTutorInput;
  image?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminDeleteAiConfig
// ====================================================

export interface adminDeleteAiConfig {
  adminDeleteAiConfig: boolean;
}

export interface adminDeleteAiConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminDeleteAiPricelist
// ====================================================

export interface adminDeleteAiPricelist {
  adminDeleteAiPricelist: boolean;
}

export interface adminDeleteAiPricelistVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminDeletePreview
// ====================================================

export interface adminDeletePreview {
  adminDeletePreview: boolean;
}

export interface adminDeletePreviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminDeleteSubscription
// ====================================================

export interface adminDeleteSubscription {
  adminDeleteSubscription: boolean;
}

export interface adminDeleteSubscriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminMarkPurchaseUsed
// ====================================================

export interface adminMarkPurchaseUsed_adminMarkPurchaseUsed {
  __typename: "Purchase";
  id: string;
}

export interface adminMarkPurchaseUsed {
  adminMarkPurchaseUsed: adminMarkPurchaseUsed_adminMarkPurchaseUsed;
}

export interface adminMarkPurchaseUsedVariables {
  purchaseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminRefreshCourseSearchIndex
// ====================================================

export interface adminRefreshCourseSearchIndex_adminRefreshCourseSearchIndex {
  __typename: "CourseIndexRefreshStats";
  activeTutorCount: number | null;
  inactiveTutorCount: number | null;
  totalCourses: number | null;
  removedTutors: number | null;
}

export interface adminRefreshCourseSearchIndex {
  adminRefreshCourseSearchIndex: adminRefreshCourseSearchIndex_adminRefreshCourseSearchIndex | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminRefreshCustomSearchIndex
// ====================================================

export interface adminRefreshCustomSearchIndex_adminRefreshCustomSearchIndex {
  __typename: "CustomIndexRefreshStats";
  activePackageCount: number | null;
  tutorsWithCustomPackages: number | null;
  packagesRemoved: number | null;
}

export interface adminRefreshCustomSearchIndex {
  adminRefreshCustomSearchIndex: adminRefreshCustomSearchIndex_adminRefreshCustomSearchIndex | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminPayoutTutor
// ====================================================

export interface adminPayoutTutor {
  /**
   * Manual tutor payout from admin.
   */
  adminPayoutTutor: boolean;
}

export interface adminPayoutTutorVariables {
  data: TutorPayoutInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateAiConfig
// ====================================================

export interface adminUpdateAiConfig_adminUpdateAiConfig_config {
  __typename: "AiConfigObject";
  systemPrompt: string;
  firstMessages: string[];
  messagesBack: number;
}

export interface adminUpdateAiConfig_adminUpdateAiConfig {
  __typename: "AiConfig";
  /**
   * admininstrative title
   */
  title: string;
  id: string;
  /**
   * AI config in JSON, fields: systemPrompt (string), firstMessage (string), messagesBack (number)
   */
  config: adminUpdateAiConfig_adminUpdateAiConfig_config;
}

export interface adminUpdateAiConfig {
  adminUpdateAiConfig: adminUpdateAiConfig_adminUpdateAiConfig;
}

export interface adminUpdateAiConfigVariables {
  id: string;
  input: AdminAiConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateAiPricelist
// ====================================================

export interface adminUpdateAiPricelist_adminUpdateAiPricelist {
  __typename: "AiPricelist";
  id: string;
  minutes: number;
  price: number;
  currency: string;
}

export interface adminUpdateAiPricelist {
  adminUpdateAiPricelist: adminUpdateAiPricelist_adminUpdateAiPricelist;
}

export interface adminUpdateAiPricelistVariables {
  id: string;
  input: AiPricelistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateCourse
// ====================================================

export interface adminUpdateCourse_adminUpdateCourse {
  __typename: "Course";
  id: string;
}

export interface adminUpdateCourse {
  adminUpdateCourse: adminUpdateCourse_adminUpdateCourse;
}

export interface adminUpdateCourseVariables {
  id: string;
  input: CourseWithTagsAndTranslationsInput;
  image_cs?: any | null;
  image_en?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateDemandStatus
// ====================================================

export interface adminUpdateDemandStatus_adminUpdateDemandStatus {
  __typename: "DemandForm";
  id: string;
  status: DemandStatus;
}

export interface adminUpdateDemandStatus {
  adminUpdateDemandStatus: adminUpdateDemandStatus_adminUpdateDemandStatus;
}

export interface adminUpdateDemandStatusVariables {
  id: string;
  status: DemandStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PackageUpdateMutation
// ====================================================

export interface PackageUpdateMutation_adminUpdatePackage {
  __typename: "Package";
  id: string;
  tutorId: string;
  tutorUserId: string;
  active: boolean;
  name: string;
  /**
   * Duration in minutes
   */
  duration: number;
  numOfLessons: number;
  defaultPrice: number;
  defaultCurrency: string;
  description: string;
  prerequisites: string | null;
  level: string | null;
  score: number | null;
  topicIds: string[] | null;
}

export interface PackageUpdateMutation {
  adminUpdatePackage: PackageUpdateMutation_adminUpdatePackage;
}

export interface PackageUpdateMutationVariables {
  input: UpdatePackageInput;
  tutorId: string;
  tutorUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateStudentFreeAiUsage
// ====================================================

export interface adminUpdateStudentFreeAiUsage_adminUpdateStudentFreeAiUsage {
  __typename: "Student";
  id: string;
  freeAiUsage: number;
}

export interface adminUpdateStudentFreeAiUsage {
  adminUpdateStudentFreeAiUsage: adminUpdateStudentFreeAiUsage_adminUpdateStudentFreeAiUsage;
}

export interface adminUpdateStudentFreeAiUsageVariables {
  studentId: string;
  freeAiUsage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminUpdateTopic
// ====================================================

export interface adminUpdateTopic_adminUpdateTopic {
  __typename: "Topic";
  id: string;
  name: string;
  parentId: string | null;
  createdAt: any;
}

export interface adminUpdateTopic {
  adminUpdateTopic: adminUpdateTopic_adminUpdateTopic;
}

export interface adminUpdateTopicVariables {
  topicId: string;
  topicInput: TopicWithTranslationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TutorUpdateMutation
// ====================================================

export interface TutorUpdateMutation_adminUpdateTutor_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface TutorUpdateMutation_adminUpdateTutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  countryCode: string | null;
  middleName: string | null;
  gender: Gender | null;
  birthday: any | null;
  timezone: string | null;
  image: string | null;
  credentials: TutorUpdateMutation_adminUpdateTutor_user_credentials[] | null;
}

export interface TutorUpdateMutation_adminUpdateTutor {
  __typename: "Tutor";
  id: string;
  language: string;
  introduction: string | null;
  rating: number;
  score: number | null;
  blockingTime: number;
  user: TutorUpdateMutation_adminUpdateTutor_user;
}

export interface TutorUpdateMutation {
  adminUpdateTutor: TutorUpdateMutation_adminUpdateTutor | null;
}

export interface TutorUpdateMutationVariables {
  tutor: AdminTutorInput;
  image?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: banUser
// ====================================================

export interface banUser_banUser {
  __typename: "Ban";
  id: string;
}

export interface banUser {
  banUser: banUser_banUser;
}

export interface banUserVariables {
  banInput: BanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelGiftUser
// ====================================================

export interface cancelGiftUser {
  /**
   * Admin or service can cancel promo credit.
   */
  cancelGiftUser: string;
}

export interface cancelGiftUserVariables {
  data: CancelGiftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSystemMessage
// ====================================================

export interface createSystemMessage_createSystemMessage {
  __typename: "Message";
  id: string;
  content: string | null;
}

export interface createSystemMessage {
  createSystemMessage: createSystemMessage_createSystemMessage | null;
}

export interface createSystemMessageVariables {
  message: MessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createVoucher
// ====================================================

export interface createVoucher_createVoucher {
  __typename: "Voucher";
  id: string;
}

export interface createVoucher {
  createVoucher: createVoucher_createVoucher;
}

export interface createVoucherVariables {
  data: VoucherInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUser
// ====================================================

export interface deleteUser {
  deleteUser: boolean;
}

export interface deleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminEditRatingComment
// ====================================================

export interface adminEditRatingComment {
  adminEditRatingComment: boolean;
}

export interface adminEditRatingCommentVariables {
  id: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getNewHash
// ====================================================

export interface getNewHash {
  getNewHash: string | null;
}

export interface getNewHashVariables {
  identifier: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: insertTransaction
// ====================================================

export interface insertTransaction {
  insertTransaction: boolean;
}

export interface insertTransactionVariables {
  data: PromoCreditInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteGroupMember
// ====================================================

export interface InviteGroupMember_groupMember_wallet_options {
  __typename: "WalletOptions";
  /**
   * How many per cent group cover for member in range 0-100%. 100 = all covered by group
   */
  coverage: number | null;
  /**
   * Monthly limit for group member spending
   */
  monthlyLimit: number | null;
}

export interface InviteGroupMember_groupMember_wallet {
  __typename: "Wallet";
  id: string;
  options: InviteGroupMember_groupMember_wallet_options;
}

export interface InviteGroupMember_groupMember {
  __typename: "GroupStudent";
  /**
   * UserId
   */
  id: string;
  department: string | null;
  firstName: string | null;
  groupRole: GroupRole;
  lastName: string | null;
  identifier: string;
  state: GroupUserState | null;
  wallet: InviteGroupMember_groupMember_wallet | null;
}

export interface InviteGroupMember {
  groupMember: InviteGroupMember_groupMember | null;
}

export interface InviteGroupMemberVariables {
  department?: string | null;
  firstName: string;
  groupId: string;
  groupRole: GroupRole;
  identifier: string;
  lastName: string;
  state: GroupUserState;
  walletOptions: WalletOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: purchasePackageWithTransaction
// ====================================================

export interface purchasePackageWithTransaction {
  /**
   * Mutation to purchase package without payment. SourceId should contain eg voucher triggering transaction.
   */
  purchasePackageWithTransaction: boolean;
}

export interface purchasePackageWithTransactionVariables {
  packageId: string;
  userId: string;
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: savePaymentOptions
// ====================================================

export interface savePaymentOptions_savePaymentOptions {
  __typename: "PaymentOptions";
  id: string;
  otherMethodsEnabled: boolean;
  stripeEnabled: boolean;
}

export interface savePaymentOptions {
  savePaymentOptions: savePaymentOptions_savePaymentOptions;
}

export interface savePaymentOptionsVariables {
  userId: string;
  input: PaymentOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendNewPassword
// ====================================================

export interface sendNewPassword {
  sendNewPassword: boolean | null;
}

export interface sendNewPasswordVariables {
  identifier: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendVerification
// ====================================================

export interface sendVerification {
  sendVerification: boolean | null;
}

export interface sendVerificationVariables {
  identifier: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setRatingIgnored
// ====================================================

export interface setRatingIgnored_setRatingIgnored {
  __typename: "Rating";
  id: string;
  ignored: boolean;
}

export interface setRatingIgnored {
  setRatingIgnored: setRatingIgnored_setRatingIgnored;
}

export interface setRatingIgnoredVariables {
  id: string;
  ignored: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setStatus
// ====================================================

export interface setStatus {
  setStatus: boolean;
}

export interface setStatusVariables {
  status: StatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setTutorActiveState2
// ====================================================

export interface setTutorActiveState2_setTutorActiveState2 {
  __typename: "Tutor";
  id: string;
  isActive: boolean;
}

export interface setTutorActiveState2 {
  setTutorActiveState2: setTutorActiveState2_setTutorActiveState2;
}

export interface setTutorActiveState2Variables {
  tutorId: string;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setTutorPublishState
// ====================================================

export interface setTutorPublishState_setTutorPublishState {
  __typename: "Tutor";
  id: string;
  publish: boolean;
}

export interface setTutorPublishState {
  setTutorPublishState: setTutorPublishState_setTutorPublishState;
}

export interface setTutorPublishStateVariables {
  tutorId: string;
  publish: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setVisibility
// ====================================================

export interface setVisibility {
  setVisibility: boolean;
}

export interface setVisibilityVariables {
  id: string;
  visible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: tutorCreateStudent
// ====================================================

export interface tutorCreateStudent_invite_user_students {
  __typename: "Student";
  id: string;
}

export interface tutorCreateStudent_invite_user {
  __typename: "User";
  id: string;
  students: tutorCreateStudent_invite_user_students[] | null;
}

export interface tutorCreateStudent_invite {
  __typename: "ClassroomInviteResponse";
  newUser: boolean;
  hash: string | null;
  slug: string;
  user: tutorCreateStudent_invite_user;
}

export interface tutorCreateStudent {
  invite: tutorCreateStudent_invite | null;
}

export interface tutorCreateStudentVariables {
  email: string;
  message: string;
  tutorId: string;
  tutorUserId: string;
  nameOverride?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyEmailContact
// ====================================================

export interface verifyEmailContact {
  verifyEmailContact: boolean;
}

export interface verifyEmailContactVariables {
  email: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminFindAllPaymentsByProfileId
// ====================================================

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_BenefitPlusPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_BenefitPlusPayment {
  __typename: "BenefitPlusPayment" | "BraintreePayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_BenefitPlusPayment_tutor | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment_csobGatewayData {
  __typename: "CsobGatewayData";
  orderNumber: string;
  paymentId: string;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment {
  __typename: "CsobPayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment_tutor | null;
  csobGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment_csobGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment_paypalGatewayData {
  __typename: "PaypalGatewayData";
  captureId: string | null;
  orderId: string;
  issue: string | null;
  issueDescription: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment {
  __typename: "PaypalPayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment_tutor | null;
  paypalGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment_paypalGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment_sodexoGatewayData {
  __typename: "SodexoGatewayData";
  orderNumber: string;
  companyId: string | null;
  companyOrderId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment {
  __typename: "SodexoPayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment_tutor | null;
  sodexoGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment_sodexoGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment_payuGatewayData {
  __typename: "PayUGatewayData";
  orderId: string;
  refundId: string | null;
  payMethod: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment {
  __typename: "PayUPayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment_tutor | null;
  payuGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment_payuGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment_gbGatewayData {
  __typename: "GalleryBetaGatewayData";
  code: string;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment {
  __typename: "GalleryBetaPayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment_tutor | null;
  gbGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment_gbGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment_mollieGatewayData {
  __typename: "MollieGatewayData";
  method: MollieMethod;
  id: string;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment {
  __typename: "MolliePayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment_tutor | null;
  mollieGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment_mollieGatewayData;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment_tutor {
  __typename: "Tutor";
  stripeAccountId: string | null;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment_stripeGatewayData {
  __typename: "StripeGatewayData";
  paymentIntentId: string;
}

export interface adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment {
  __typename: "StripePayment";
  id: string;
  amount: number;
  blabuState: BlabuPaymentStateType;
  createdAt: any;
  type: string;
  tutorProfileId: string | null;
  tutor: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment_tutor | null;
  stripeGatewayData: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment_stripeGatewayData;
}

export type adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId = adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_BenefitPlusPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_CsobPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PaypalPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_SodexoPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_PayUPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_GalleryBetaPayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_MolliePayment | adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId_StripePayment;

export interface adminFindAllPaymentsByProfileId {
  adminFindAllPaymentsByProfileId: adminFindAllPaymentsByProfileId_adminFindAllPaymentsByProfileId[];
}

export interface adminFindAllPaymentsByProfileIdVariables {
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminFindRooms
// ====================================================

export interface adminFindRooms_adminFindRooms_scheduledCall {
  __typename: "ScheduledCall";
  id: string;
}

export interface adminFindRooms_adminFindRooms_video {
  __typename: "Video";
  id: string;
  status: StatusType;
}

export interface adminFindRooms_adminFindRooms_lesson_package {
  __typename: "Package";
  id: string;
  name: string;
}

export interface adminFindRooms_adminFindRooms_lesson {
  __typename: "Lesson";
  id: string;
  package: adminFindRooms_adminFindRooms_lesson_package;
}

export interface adminFindRooms_adminFindRooms_participants_profile_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  image: string | null;
}

export interface adminFindRooms_adminFindRooms_participants_profile {
  __typename: "Profile";
  id: string;
  user: adminFindRooms_adminFindRooms_participants_profile_user | null;
}

export interface adminFindRooms_adminFindRooms_participants {
  __typename: "Participant";
  id: string;
  profileId: string;
  role: Role;
  profile: adminFindRooms_adminFindRooms_participants_profile | null;
}

export interface adminFindRooms_adminFindRooms {
  __typename: "Room";
  id: string;
  /**
   * when is the actual start (countdowns, problems etc)
   */
  started: any | null;
  /**
   * when is the actual end (problems, prolongations etc)
   */
  ended: any | null;
  /**
   * duration in seconds, accepted values 900, 1800, 2700
   */
  duration: number;
  /**
   * available for student and internal calls only
   */
  priceCharged: number | null;
  unitsCharged: number | null;
  /**
   * formatted duration in h:mm:ss
   */
  actualDuration: string | null;
  state: string | null;
  meta: any | null;
  twilioSid: string | null;
  /**
   * loaded only for invite in scheduled call
   */
  scheduledCall: adminFindRooms_adminFindRooms_scheduledCall | null;
  video: adminFindRooms_adminFindRooms_video | null;
  lesson: adminFindRooms_adminFindRooms_lesson | null;
  participants: adminFindRooms_adminFindRooms_participants[] | null;
}

export interface adminFindRooms {
  /**
   * find rooms by user
   */
  adminFindRooms: adminFindRooms_adminFindRooms[];
}

export interface adminFindRoomsVariables {
  profileId: string;
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetActiveSubscriptions
// ====================================================

export interface adminGetActiveSubscriptions_adminGetActiveSubscriptions_profile_user {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface adminGetActiveSubscriptions_adminGetActiveSubscriptions_profile {
  __typename: "Profile";
  user: adminGetActiveSubscriptions_adminGetActiveSubscriptions_profile_user | null;
}

export interface adminGetActiveSubscriptions_adminGetActiveSubscriptions {
  __typename: "Subscriber";
  id: string;
  subscribedToProfileId: string | null;
  createdAt: any;
  profile: adminGetActiveSubscriptions_adminGetActiveSubscriptions_profile | null;
}

export interface adminGetActiveSubscriptions {
  adminGetActiveSubscriptions: adminGetActiveSubscriptions_adminGetActiveSubscriptions[];
}

export interface adminGetActiveSubscriptionsVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetAiConfigs
// ====================================================

export interface adminGetAiConfigs_adminGetAiConfigs_config {
  __typename: "AiConfigObject";
  firstMessages: string[];
  systemPrompt: string;
  messagesBack: number;
}

export interface adminGetAiConfigs_adminGetAiConfigs {
  __typename: "AiConfig";
  id: string;
  /**
   * admininstrative title
   */
  title: string;
  /**
   * AI config in JSON, fields: systemPrompt (string), firstMessage (string), messagesBack (number)
   */
  config: adminGetAiConfigs_adminGetAiConfigs_config;
  createdAt: any;
  updatedAt: any;
}

export interface adminGetAiConfigs {
  adminGetAiConfigs: adminGetAiConfigs_adminGetAiConfigs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetAllCourses
// ====================================================

export interface adminGetAllCourses_adminGetAllCourses_course {
  __typename: "Course";
  id: string;
  slug: string;
  topicL1: string;
  topicL2: string;
  flagCode: string | null;
  showAsMain: boolean;
  priority: number;
}

export interface adminGetAllCourses_adminGetAllCourses_translations {
  __typename: "CourseTranslation";
  name: string;
  lang: WebLanguage;
  seoTitle: string | null;
  seoDescription: string | null;
  seoImage: string | null;
}

export interface adminGetAllCourses_adminGetAllCourses_tags_tag {
  __typename: "CourseTag";
  slug: string;
  type: CourseTagType;
}

export interface adminGetAllCourses_adminGetAllCourses_tags_translations {
  __typename: "CourseTagTranslation";
  name: string;
  lang: WebLanguage;
}

export interface adminGetAllCourses_adminGetAllCourses_tags {
  __typename: "CourseTagsWithTranslations";
  tag: adminGetAllCourses_adminGetAllCourses_tags_tag;
  translations: adminGetAllCourses_adminGetAllCourses_tags_translations[];
}

export interface adminGetAllCourses_adminGetAllCourses {
  __typename: "CourseWithTagsAndTranslations";
  course: adminGetAllCourses_adminGetAllCourses_course;
  translations: adminGetAllCourses_adminGetAllCourses_translations[];
  tags: adminGetAllCourses_adminGetAllCourses_tags[];
}

export interface adminGetAllCourses {
  adminGetAllCourses: adminGetAllCourses_adminGetAllCourses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetAllShiftsInRange
// ====================================================

export interface adminGetAllShiftsInRange_adminGetAllShiftsInRange_tutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface adminGetAllShiftsInRange_adminGetAllShiftsInRange_tutor {
  __typename: "Tutor";
  id: string;
  user: adminGetAllShiftsInRange_adminGetAllShiftsInRange_tutor_user;
}

export interface adminGetAllShiftsInRange_adminGetAllShiftsInRange {
  __typename: "Shift";
  id: string;
  start: any;
  end: any;
  cancelledAt: any | null;
  createdAt: any;
  type: ShiftType | null;
  /**
   * Only for admin
   */
  tutor: adminGetAllShiftsInRange_adminGetAllShiftsInRange_tutor;
}

export interface adminGetAllShiftsInRange {
  /**
   * returns all shifts in range including cancelled for all tutors
   */
  adminGetAllShiftsInRange: adminGetAllShiftsInRange_adminGetAllShiftsInRange[];
}

export interface adminGetAllShiftsInRangeVariables {
  from: any;
  to: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetAllTutorProps
// ====================================================

export interface adminGetAllTutorProps_adminGetAllTutorProps {
  __typename: "AdminTutorProps";
  tutorId: string;
  completeProfile: boolean;
  oneCompletePackage: boolean;
  connectedStripeAccount: boolean;
  completeBilling: boolean;
}

export interface adminGetAllTutorProps {
  adminGetAllTutorProps: adminGetAllTutorProps_adminGetAllTutorProps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetConversation
// ====================================================

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentScheduledCall {
  __typename: "AttachmentScheduledCall" | "AttachmentScheduledCallExternal" | "AttachmentCallEnded" | "AttachmentCallCancelled" | "AttachmentEmpty" | "AttachmentCreditGiven" | "AttachmentFile" | "AttachmentImage" | "AttachmentProScheduledCall" | "AttachmentProScheduledCallConfirmed" | "AttachmentProScheduledCallRejected" | "AttachmentServiceGiven" | "AttachmentServicePurchased" | "AttachmentStartWithChat" | "AttachmentCallBooked" | "AttachmentProScheduledCallStudentRejected";
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery_aiSettings {
  __typename: "AiSettings";
  languageComplexity: AiLanguageComplexity | null;
  grammarFocus: AiGrammarFocus | null;
  conversationTheme: AiConversationTheme | null;
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery {
  __typename: "AttachmentAiQuery";
  type: string;
  aiReservationId: string | null;
  aiSettings: adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery_aiSettings;
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiReset {
  __typename: "AttachmentAiReset";
  type: string;
  aiReservationId: string | null;
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiStatus_aiSettingsPropsChanges {
  __typename: "AiSettings";
  languageComplexity: AiLanguageComplexity | null;
  grammarFocus: AiGrammarFocus | null;
  conversationTheme: AiConversationTheme | null;
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiStatus {
  __typename: "AttachmentAiStatus";
  type: string;
  statusMessageType: AiStatusMessageType;
  aiSettingsPropsChanges: adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiStatus_aiSettingsPropsChanges | null;
  aiSettingsPropsAffected: string[] | null;
  minutes: number | null;
}

export interface adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiResponse {
  __typename: "AttachmentAiResponse";
  type: string;
  aiReservationId: string | null;
  responseToId: string | null;
  tokensPrompt: number | null;
  tokensCompletion: number | null;
  AiError: string | null;
}

export type adminGetConversation_adminGetConversation_messages_attachment = adminGetConversation_adminGetConversation_messages_attachment_AttachmentScheduledCall | adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiQuery | adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiReset | adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiStatus | adminGetConversation_adminGetConversation_messages_attachment_AttachmentAiResponse;

export interface adminGetConversation_adminGetConversation_messages {
  __typename: "Message";
  content: string | null;
  sent: any;
  fromUserId: string | null;
  toUserId: string | null;
  read: any | null;
  roomId: string | null;
  attachment: adminGetConversation_adminGetConversation_messages_attachment | null;
}

export interface adminGetConversation_adminGetConversation_members {
  __typename: "ConversationMember";
  /**
   * userId
   */
  userId: string;
}

export interface adminGetConversation_adminGetConversation {
  __typename: "Conversation";
  id: string;
  messages: adminGetConversation_adminGetConversation_messages[] | null;
  members: adminGetConversation_adminGetConversation_members[];
}

export interface adminGetConversation {
  adminGetConversation: adminGetConversation_adminGetConversation | null;
}

export interface adminGetConversationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetConversations
// ====================================================

export interface adminGetConversations_adminGetConversations_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface adminGetConversations_adminGetConversations_user_tutors {
  __typename: "Tutor";
  id: string;
}

export interface adminGetConversations_adminGetConversations_user_students {
  __typename: "Student";
  id: string;
}

export interface adminGetConversations_adminGetConversations_user {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  credentials: adminGetConversations_adminGetConversations_user_credentials[] | null;
  tutors: adminGetConversations_adminGetConversations_user_tutors[] | null;
  students: adminGetConversations_adminGetConversations_user_students[] | null;
}

export interface adminGetConversations_adminGetConversations {
  __typename: "Conversation";
  id: string;
  lastMessageAt: any | null;
  user: adminGetConversations_adminGetConversations_user;
  createdAt: any;
}

export interface adminGetConversations {
  adminGetConversations: adminGetConversations_adminGetConversations[];
}

export interface adminGetConversationsVariables {
  userId: string;
  pageInfo?: PageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetCourse
// ====================================================

export interface adminGetCourse_adminGetCourse_course {
  __typename: "Course";
  id: string;
  slug: string;
  topicL1: string;
  topicL2: string;
  showAsMain: boolean;
  flagCode: string | null;
  priority: number;
}

export interface adminGetCourse_adminGetCourse_translations {
  __typename: "CourseTranslation";
  name: string;
  lang: WebLanguage;
  seoTitle: string | null;
  seoDescription: string | null;
  seoImage: string | null;
}

export interface adminGetCourse_adminGetCourse_tags_tag {
  __typename: "CourseTag";
  slug: string;
  type: CourseTagType;
}

export interface adminGetCourse_adminGetCourse_tags_translations {
  __typename: "CourseTagTranslation";
  name: string;
  lang: WebLanguage;
}

export interface adminGetCourse_adminGetCourse_tags {
  __typename: "CourseTagsWithTranslations";
  tag: adminGetCourse_adminGetCourse_tags_tag;
  translations: adminGetCourse_adminGetCourse_tags_translations[];
}

export interface adminGetCourse_adminGetCourse {
  __typename: "CourseWithTagsAndTranslations";
  course: adminGetCourse_adminGetCourse_course;
  translations: adminGetCourse_adminGetCourse_translations[];
  tags: adminGetCourse_adminGetCourse_tags[];
}

export interface adminGetCourse {
  adminGetCourse: adminGetCourse_adminGetCourse;
}

export interface adminGetCourseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetDemand
// ====================================================

export interface adminGetDemand_adminGetDemand_demandFormTutors {
  __typename: "DemandFormTutor";
  id: string;
  tutorId: string;
  status: DemandTutorStatus;
  createdAt: any;
  updatedAt: any;
}

export interface adminGetDemand_adminGetDemand {
  __typename: "DemandForm";
  id: string;
  status: DemandStatus;
  createdAt: any;
  updatedAt: any;
  demandFormTutors: adminGetDemand_adminGetDemand_demandFormTutors[] | null;
}

export interface adminGetDemand {
  adminGetDemand: adminGetDemand_adminGetDemand;
}

export interface adminGetDemandVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetDemands
// ====================================================

export interface adminGetDemands_adminGetDemands_user_students {
  __typename: "Student";
  id: string;
}

export interface adminGetDemands_adminGetDemands_user {
  __typename: "User";
  students: adminGetDemands_adminGetDemands_user_students[] | null;
  /**
   * defaults to user.displayName or firstName if not set
   */
  overrideName: string;
}

export interface adminGetDemands_adminGetDemands_demandFormTutors {
  __typename: "DemandFormTutor";
  id: string;
}

export interface adminGetDemands_adminGetDemands {
  __typename: "DemandForm";
  id: string;
  user: adminGetDemands_adminGetDemands_user;
  courseId: string | null;
  topicId: string | null;
  tutorId: string | null;
  packageId: string | null;
  note: string;
  status: DemandStatus;
  demandFormTutors: adminGetDemands_adminGetDemands_demandFormTutors[] | null;
  createdAt: any;
  updatedAt: any;
}

export interface adminGetDemands {
  adminGetDemands: adminGetDemands_adminGetDemands[];
}

export interface adminGetDemandsVariables {
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetDemandsCount
// ====================================================

export interface adminGetDemandsCount_adminGetDemands {
  __typename: "DemandForm";
  id: string;
}

export interface adminGetDemandsCount {
  adminGetDemands: adminGetDemandsCount_adminGetDemands[];
}

export interface adminGetDemandsCountVariables {
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetFutureScheduledCalls
// ====================================================

export interface adminGetFutureScheduledCalls_adminGetFutureScheduledCalls_participants {
  __typename: "ScheduledCallParticipant";
  userId: string;
  role: Role;
  profileId: string;
  cancellationReason: string | null;
  rejectMessage: string | null;
  purchaseId: string | null;
}

export interface adminGetFutureScheduledCalls_adminGetFutureScheduledCalls {
  __typename: "ScheduledCall";
  id: string;
  createdAt: any;
  start: any;
  end: any;
  cancelledAt: any | null;
  confirmedAt: any | null;
  /**
   * required when tutor scheduling call with student for unspecified lesson
   */
  packageId: string | null;
  inviteMessage: string | null;
  participants: adminGetFutureScheduledCalls_adminGetFutureScheduledCalls_participants[];
}

export interface adminGetFutureScheduledCalls {
  adminGetFutureScheduledCalls: adminGetFutureScheduledCalls_adminGetFutureScheduledCalls[];
}

export interface adminGetFutureScheduledCallsVariables {
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PackageUpdateQuery
// ====================================================

export interface PackageUpdateQuery_adminGetPackage {
  __typename: "Package";
  id: string;
  tutorId: string;
  tutorUserId: string;
  active: boolean;
  name: string;
  /**
   * Duration in minutes
   */
  duration: number;
  numOfLessons: number;
  defaultPrice: number;
  defaultCurrency: string;
  description: string;
  prerequisites: string | null;
  level: string | null;
  score: number | null;
  topicIds: string[] | null;
  courseId: string | null;
  firstLessonFree: boolean | null;
}

export interface PackageUpdateQuery {
  adminGetPackage: PackageUpdateQuery_adminGetPackage;
}

export interface PackageUpdateQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetPackagesForCourseId
// ====================================================

export interface adminGetPackagesForCourseId_adminGetPackagesForCourseId {
  __typename: "Package";
  id: string;
  name: string;
  tutorId: string;
  active: boolean;
  globalVisibility: Visibility;
  score: number | null;
}

export interface adminGetPackagesForCourseId {
  adminGetPackagesForCourseId: adminGetPackagesForCourseId_adminGetPackagesForCourseId[];
}

export interface adminGetPackagesForCourseIdVariables {
  courseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetPackageThumbStats
// ====================================================

export interface adminGetPackageThumbStats_adminGetPackageThumbStats {
  __typename: "PackageThumbStatsResponse";
  customPackagesThumbsOnline: number;
  customPackagesThumbsPending: number;
  customPackagesNoImage: number;
}

export interface adminGetPackageThumbStats {
  adminGetPackageThumbStats: adminGetPackageThumbStats_adminGetPackageThumbStats;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetPayouts
// ====================================================

export interface adminGetPayouts_adminGetPayouts {
  __typename: "Payout";
  id: string;
  amount: number;
  currency: Currency;
  createdAt: any;
  from: any | null;
  to: any | null;
  type: PayoutType;
  status: PayoutStatus;
}

export interface adminGetPayouts {
  adminGetPayouts: adminGetPayouts_adminGetPayouts[];
}

export interface adminGetPayoutsVariables {
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetPurchasedPackages
// ====================================================

export interface adminGetPurchasedPackages_adminGetPurchasedPackages_package_lessons {
  __typename: "Lesson";
  id: string;
  no: number | null;
}

export interface adminGetPurchasedPackages_adminGetPurchasedPackages_package {
  __typename: "Package";
  id: string;
  numOfLessons: number;
  lessons: (adminGetPurchasedPackages_adminGetPurchasedPackages_package_lessons | null)[];
  name: string;
  courseId: string | null;
  createdAt: any;
}

export interface adminGetPurchasedPackages_adminGetPurchasedPackages_usages {
  __typename: "LessonUsage";
  usedAt: any | null;
  bookedAt: any | null;
}

export interface adminGetPurchasedPackages_adminGetPurchasedPackages {
  __typename: "Purchase";
  id: string;
  tutorId: string | null;
  package: adminGetPurchasedPackages_adminGetPurchasedPackages_package;
  createdAt: any;
  usages: adminGetPurchasedPackages_adminGetPurchasedPackages_usages[] | null;
  status: PurchaseStatus;
}

export interface adminGetPurchasedPackages {
  adminGetPurchasedPackages: adminGetPurchasedPackages_adminGetPurchasedPackages[];
}

export interface adminGetPurchasedPackagesVariables {
  studentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetScheduledCallsByParticipant
// ====================================================

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_package {
  __typename: "Package";
  name: string;
}

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Tutor_user {
  __typename: "User";
  /**
   * defaults to user.displayName or firstName if not set
   */
  overrideName: string;
}

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Tutor {
  __typename: "Tutor";
  user: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Tutor_user;
}

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Student_user {
  __typename: "User";
  /**
   * defaults to user.displayName or firstName if not set
   */
  overrideName: string;
}

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Student {
  __typename: "Student";
  user: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Student_user;
}

export type adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile = adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Tutor | adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile_Student;

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants {
  __typename: "ScheduledCallParticipant";
  userId: string;
  role: Role;
  profileId: string;
  cancellationReason: string | null;
  rejectMessage: string | null;
  purchaseId: string | null;
  profile: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants_profile | null;
}

export interface adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant {
  __typename: "ScheduledCall";
  id: string;
  createdAt: any;
  start: any;
  end: any;
  cancelledAt: any | null;
  confirmedAt: any | null;
  /**
   * required when tutor scheduling call with student for unspecified lesson
   */
  packageId: string | null;
  package: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_package | null;
  inviteMessage: string | null;
  participants: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant_participants[];
}

export interface adminGetScheduledCallsByParticipant {
  adminGetScheduledCallsByParticipant: adminGetScheduledCallsByParticipant_adminGetScheduledCallsByParticipant[];
}

export interface adminGetScheduledCallsByParticipantVariables {
  profileId: string;
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetThumbStats
// ====================================================

export interface adminGetThumbStats_adminGetThumbStats {
  __typename: "ThumbStatsResponse";
  thumbsOnline: number;
  thumbsPending: number;
  noImage: number;
}

export interface adminGetThumbStats {
  adminGetThumbStats: adminGetThumbStats_adminGetThumbStats;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetTopics
// ====================================================

export interface adminGetTopics_adminGetTopics_topic {
  __typename: "Topic";
  name: string;
  parentId: string | null;
  slug: string;
  id: string;
}

export interface adminGetTopics_adminGetTopics_translations {
  __typename: "TopicTranslation";
  id: string;
  lang: WebLanguage;
  name: string;
  headline: string | null;
  subheadline: string | null;
}

export interface adminGetTopics_adminGetTopics {
  __typename: "TopicWithTranslations";
  topic: adminGetTopics_adminGetTopics_topic;
  translations: adminGetTopics_adminGetTopics_translations[];
}

export interface adminGetTopics {
  adminGetTopics: adminGetTopics_adminGetTopics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetTopicsCourses
// ====================================================

export interface adminGetTopicsCourses_getTranslatedTopics {
  __typename: "TranslatedTopic";
  id: string;
  name: string;
}

export interface adminGetTopicsCourses_getAllTranslatedCourses {
  __typename: "TranslatedCourse";
  id: string;
  name: string;
}

export interface adminGetTopicsCourses {
  getTranslatedTopics: adminGetTopicsCourses_getTranslatedTopics[];
  getAllTranslatedCourses: adminGetTopicsCourses_getAllTranslatedCourses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetTransactions
// ====================================================

export interface adminGetTransactions_adminGetTransactions {
  __typename: "Transaction";
  amount: number;
  type: TransactionType;
  createdAt: any;
  expiresAt: any | null;
  /**
   * Origin of the transaction, depends on type
   *   **redeem_voucher**: redemptionId
   */
  sourceId: string | null;
  comment: string | null;
}

export interface adminGetTransactions {
  adminGetTransactions: adminGetTransactions_adminGetTransactions[];
}

export interface adminGetTransactionsVariables {
  profileId: string;
  pageInfo?: PageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TutorUpdateQuery
// ====================================================

export interface TutorUpdateQuery_adminGetTutor_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface TutorUpdateQuery_adminGetTutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  countryCode: string | null;
  middleName: string | null;
  gender: Gender | null;
  birthday: any | null;
  timezone: string | null;
  image: string | null;
  credentials: TutorUpdateQuery_adminGetTutor_user_credentials[] | null;
}

export interface TutorUpdateQuery_adminGetTutor {
  __typename: "Tutor";
  id: string;
  language: string;
  introduction: string | null;
  rating: number;
  score: number | null;
  blockingTime: number;
  user: TutorUpdateQuery_adminGetTutor_user;
}

export interface TutorUpdateQuery {
  adminGetTutor: TutorUpdateQuery_adminGetTutor;
}

export interface TutorUpdateQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TutorInviteQuery
// ====================================================

export interface TutorInviteQuery_adminGetTutor_user {
  __typename: "User";
  id: string;
  /**
   * defaults to user.displayName or firstName if not set
   */
  overrideName: string;
}

export interface TutorInviteQuery_adminGetTutor {
  __typename: "Tutor";
  id: string;
  user: TutorInviteQuery_adminGetTutor_user;
}

export interface TutorInviteQuery {
  adminGetTutor: TutorInviteQuery_adminGetTutor;
}

export interface TutorInviteQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetTutorProps
// ====================================================

export interface adminGetTutorProps_adminGetTutorProps {
  __typename: "AdminTutorProps";
  tutorId: string;
  completeProfile: boolean;
  oneCompletePackage: boolean;
  connectedStripeAccount: boolean;
  completeBilling: boolean;
}

export interface adminGetTutorProps {
  adminGetTutorProps: adminGetTutorProps_adminGetTutorProps;
}

export interface adminGetTutorPropsVariables {
  tutorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetUserStatusHistory
// ====================================================

export interface adminGetUserStatusHistory_adminGetUserStatusHistory {
  __typename: "UserStatusHistory";
  status: Status;
  createdAt: any;
}

export interface adminGetUserStatusHistory {
  adminGetUserStatusHistory: adminGetUserStatusHistory_adminGetUserStatusHistory[];
}

export interface adminGetUserStatusHistoryVariables {
  userId: string;
  pageInfo: PageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminGetWalletBalance
// ====================================================

export interface adminGetWalletBalance_adminGetWalletBalances {
  __typename: "AdminWalletBalance";
  balance: number;
  userId: string;
}

export interface adminGetWalletBalance {
  adminGetWalletBalances: adminGetWalletBalance_adminGetWalletBalances[];
}

export interface adminGetWalletBalanceVariables {
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminListGroups
// ====================================================

export interface adminListGroups_adminListGroups {
  __typename: "Group";
  id: string;
  name: string;
  type: GroupType;
  createdAt: any;
}

export interface adminListGroups {
  adminListGroups: adminListGroups_adminListGroups[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudentsPage
// ====================================================

export interface StudentsPage_findStudents_user_credentials {
  __typename: "Credential";
  identifier: string;
  type: CredentialType;
}

export interface StudentsPage_findStudents_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: StudentsPage_findStudents_user_credentials[] | null;
}

export interface StudentsPage_findStudents {
  __typename: "Student";
  id: string;
  user: StudentsPage_findStudents_user;
}

export interface StudentsPage {
  findStudents: StudentsPage_findStudents[];
}

export interface StudentsPageVariables {
  first?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: generateGoogleAuthUrl
// ====================================================

export interface generateGoogleAuthUrl {
  generateGoogleAuthUrl: string | null;
}

export interface generateGoogleAuthUrlVariables {
  isLocal?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAiPricelists
// ====================================================

export interface getAiPricelists_getAiPricelists {
  __typename: "AiPricelist";
  id: string;
  price: number;
  currency: string;
  minutes: number;
  createdAt: any;
  updatedAt: any;
}

export interface getAiPricelists {
  getAiPricelists: getAiPricelists_getAiPricelists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAwaitingVerificationCredentials
// ====================================================

export interface getAwaitingVerificationCredentials_getAwaitingVerificationCredentials {
  __typename: "Credential";
  identifier: string;
}

export interface getAwaitingVerificationCredentials {
  getAwaitingVerificationCredentials: getAwaitingVerificationCredentials_getAwaitingVerificationCredentials[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCompositionFile
// ====================================================

export interface getCompositionFile {
  getCompositionFile: string | null;
}

export interface getCompositionFileVariables {
  roomId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCredentialsWithoutHash
// ====================================================

export interface getCredentialsWithoutHash_getCredentialsWithoutHash {
  __typename: "Credential";
  identifier: string;
}

export interface getCredentialsWithoutHash {
  getCredentialsWithoutHash: getCredentialsWithoutHash_getCredentialsWithoutHash[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOnlineTutors
// ====================================================

export interface getOnlineTutors_getOnlineTutors_user_statuses {
  __typename: "UserStatus";
  until: any | null;
}

export interface getOnlineTutors_getOnlineTutors_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  statuses: getOnlineTutors_getOnlineTutors_user_statuses[] | null;
}

export interface getOnlineTutors_getOnlineTutors {
  __typename: "Tutor";
  user: getOnlineTutors_getOnlineTutors_user;
  id: string;
}

export interface getOnlineTutors {
  getOnlineTutors: (getOnlineTutors_getOnlineTutors | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPackages
// ====================================================

export interface getPackages_getPackages {
  __typename: "Package";
  id: string;
  name: string;
}

export interface getPackages {
  getPackages: getPackages_getPackages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPackagesByTutorId
// ====================================================

export interface getPackagesByTutorId_getPackagesByTutorId_lessons {
  __typename: "Lesson";
  id: string;
  no: number | null;
  /**
   * minute count
   */
  units: number;
}

export interface getPackagesByTutorId_getPackagesByTutorId_topics {
  __typename: "Topic";
  name: string;
}

export interface getPackagesByTutorId_getPackagesByTutorId {
  __typename: "Package";
  id: string;
  name: string;
  description: string;
  defaultPrice: number;
  defaultCurrency: string;
  globalVisibility: Visibility;
  lessons: (getPackagesByTutorId_getPackagesByTutorId_lessons | null)[];
  active: boolean;
  topicIds: string[] | null;
  topics: getPackagesByTutorId_getPackagesByTutorId_topics[];
  prerequisites: string | null;
  score: number | null;
  courseId: string | null;
  firstLessonFree: boolean | null;
}

export interface getPackagesByTutorId {
  getPackagesByTutorId: getPackagesByTutorId_getPackagesByTutorId[];
}

export interface getPackagesByTutorIdVariables {
  tutorId: string;
  pageInfo?: PageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProfile
// ====================================================

export interface getProfile_getProfileFixed_Tutor_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface getProfile_getProfileFixed_Tutor_user_ban {
  __typename: "Ban";
  id: string;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_Stripe {
  __typename: "Stripe" | "StripeCustomer" | "ClassroomSettings";
  id: string;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions {
  __typename: "PaymentOptions";
  id: string;
  otherMethodsEnabled: boolean;
  stripeEnabled: boolean;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_Email {
  __typename: "Email";
  id: string;
  verifiedAt: any | null;
  email: string | null;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_Phone {
  __typename: "Phone";
  id: string;
  phone: string | null;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_Billing {
  __typename: "Billing";
  id: string;
  name: string;
  street: string;
  city: string;
  zip: string;
  countryCode: string;
  taxId: string | null;
  companyId: string | null;
}

export interface getProfile_getProfileFixed_Tutor_user_contacts_Fee {
  __typename: "Fee";
  id: string;
  fee: number | null;
}

export type getProfile_getProfileFixed_Tutor_user_contacts = getProfile_getProfileFixed_Tutor_user_contacts_Stripe | getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions | getProfile_getProfileFixed_Tutor_user_contacts_Email | getProfile_getProfileFixed_Tutor_user_contacts_Phone | getProfile_getProfileFixed_Tutor_user_contacts_Billing | getProfile_getProfileFixed_Tutor_user_contacts_Fee;

export interface getProfile_getProfileFixed_Tutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  image: string | null;
  createdAt: any;
  credentials: getProfile_getProfileFixed_Tutor_user_credentials[] | null;
  ban: getProfile_getProfileFixed_Tutor_user_ban | null;
  contacts: getProfile_getProfileFixed_Tutor_user_contacts[] | null;
}

export interface getProfile_getProfileFixed_Tutor {
  __typename: "Tutor";
  id: string;
  stripeAccountId: string | null;
  enabledPayments: PaymentOptionsType[];
  slug: string;
  publish: boolean;
  isActive: boolean;
  user: getProfile_getProfileFixed_Tutor_user;
}

export interface getProfile_getProfileFixed_Student_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface getProfile_getProfileFixed_Student_user_ban {
  __typename: "Ban";
  id: string;
}

export interface getProfile_getProfileFixed_Student_user_contacts_Stripe {
  __typename: "Stripe" | "StripeCustomer" | "ClassroomSettings" | "Fee";
  id: string;
}

export interface getProfile_getProfileFixed_Student_user_contacts_PaymentOptions {
  __typename: "PaymentOptions";
  id: string;
  otherMethodsEnabled: boolean;
  stripeEnabled: boolean;
}

export interface getProfile_getProfileFixed_Student_user_contacts_Email {
  __typename: "Email";
  id: string;
  verifiedAt: any | null;
  email: string | null;
}

export interface getProfile_getProfileFixed_Student_user_contacts_Phone {
  __typename: "Phone";
  id: string;
  phone: string | null;
}

export interface getProfile_getProfileFixed_Student_user_contacts_Billing {
  __typename: "Billing";
  id: string;
  name: string;
  street: string;
  city: string;
  zip: string;
  countryCode: string;
}

export type getProfile_getProfileFixed_Student_user_contacts = getProfile_getProfileFixed_Student_user_contacts_Stripe | getProfile_getProfileFixed_Student_user_contacts_PaymentOptions | getProfile_getProfileFixed_Student_user_contacts_Email | getProfile_getProfileFixed_Student_user_contacts_Phone | getProfile_getProfileFixed_Student_user_contacts_Billing;

export interface getProfile_getProfileFixed_Student_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  image: string | null;
  createdAt: any;
  credentials: getProfile_getProfileFixed_Student_user_credentials[] | null;
  ban: getProfile_getProfileFixed_Student_user_ban | null;
  contacts: getProfile_getProfileFixed_Student_user_contacts[] | null;
}

export interface getProfile_getProfileFixed_Student {
  __typename: "Student";
  id: string;
  freeAiUsage: number;
  user: getProfile_getProfileFixed_Student_user;
}

export type getProfile_getProfileFixed = getProfile_getProfileFixed_Tutor | getProfile_getProfileFixed_Student;

export interface getProfile {
  getProfileFixed: getProfile_getProfileFixed;
}

export interface getProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProfileWithBan
// ====================================================

export interface getProfileWithBan_getProfileFixed_Tutor_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface getProfileWithBan_getProfileFixed_Tutor_user_ban {
  __typename: "Ban";
  id: string;
}

export interface getProfileWithBan_getProfileFixed_Tutor_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: getProfileWithBan_getProfileFixed_Tutor_user_credentials[] | null;
  ban: getProfileWithBan_getProfileFixed_Tutor_user_ban | null;
}

export interface getProfileWithBan_getProfileFixed_Tutor {
  __typename: "Tutor";
  id: string;
  user: getProfileWithBan_getProfileFixed_Tutor_user;
}

export interface getProfileWithBan_getProfileFixed_Student_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface getProfileWithBan_getProfileFixed_Student_user_ban {
  __typename: "Ban";
  id: string;
}

export interface getProfileWithBan_getProfileFixed_Student_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: getProfileWithBan_getProfileFixed_Student_user_credentials[] | null;
  ban: getProfileWithBan_getProfileFixed_Student_user_ban | null;
}

export interface getProfileWithBan_getProfileFixed_Student {
  __typename: "Student";
  id: string;
  user: getProfileWithBan_getProfileFixed_Student_user;
}

export type getProfileWithBan_getProfileFixed = getProfileWithBan_getProfileFixed_Tutor | getProfileWithBan_getProfileFixed_Student;

export interface getProfileWithBan {
  getProfileFixed: getProfileWithBan_getProfileFixed;
}

export interface getProfileWithBanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getQuizzes
// ====================================================

export interface getQuizzes_getQuizzes {
  __typename: "Quiz";
  id: string;
  quiz: any;
}

export interface getQuizzes {
  getQuizzes: getQuizzes_getQuizzes[];
}

export interface getQuizzesVariables {
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRating
// ====================================================

export interface getRating_getRating {
  __typename: "Rating";
  id: string;
  ratingTotal: number;
  givingProfileId: string;
  ratedProfileId: string;
  createdAt: any;
  comment: string;
  visible: boolean;
  ignored: boolean;
}

export interface getRating {
  getRating: getRating_getRating;
}

export interface getRatingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRatings
// ====================================================

export interface getRatings_getRatings {
  __typename: "Rating";
  id: string;
  ratingTotal: number;
  givingProfileId: string;
  ratedProfileId: string;
  createdAt: any;
  comment: string;
  visible: boolean;
  ignored: boolean;
}

export interface getRatings {
  getRatings: getRatings_getRatings[];
}

export interface getRatingsVariables {
  profileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getReferralsByUserId
// ====================================================

export interface getReferralsByUserId_getReferralsByUserId_students {
  __typename: "Student";
  id: string;
}

export interface getReferralsByUserId_getReferralsByUserId_referee {
  __typename: "Referral";
  createdAt: any;
  hadCall: boolean;
  rewardedAt: any | null;
}

export interface getReferralsByUserId_getReferralsByUserId {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  students: getReferralsByUserId_getReferralsByUserId_students[] | null;
  /**
   * Referrals where this user is referee
   */
  referee: getReferralsByUserId_getReferralsByUserId_referee | null;
}

export interface getReferralsByUserId {
  /**
   * Returns array of users who were referred by specified user
   */
  getReferralsByUserId: getReferralsByUserId_getReferralsByUserId[];
}

export interface getReferralsByUserIdVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTutorPayoutInfo
// ====================================================

export interface getTutorPayoutInfo_getTutorPayoutInfo {
  __typename: "Transaction";
  id: string;
  amount: number;
  groupId: string;
  type: TransactionType;
  createdAt: any;
}

export interface getTutorPayoutInfo {
  getTutorPayoutInfo: getTutorPayoutInfo_getTutorPayoutInfo[];
}

export interface getTutorPayoutInfoVariables {
  from: any;
  until: any;
  tutorUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TutorsPage
// ====================================================

export interface TutorsPage_getTutors_user_credentials {
  __typename: "Credential";
  identifier: string;
  type: CredentialType;
}

export interface TutorsPage_getTutors_user_contacts_Billing {
  __typename: "Billing" | "StripeCustomer" | "PaymentOptions" | "ClassroomSettings" | "Email" | "Fee" | "Phone";
  id: string;
}

export interface TutorsPage_getTutors_user_contacts_Stripe {
  __typename: "Stripe";
  id: string;
  accountId: string;
  payouts_enabled: boolean;
  charges_enabled: boolean;
}

export type TutorsPage_getTutors_user_contacts = TutorsPage_getTutors_user_contacts_Billing | TutorsPage_getTutors_user_contacts_Stripe;

export interface TutorsPage_getTutors_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: TutorsPage_getTutors_user_credentials[] | null;
  contacts: TutorsPage_getTutors_user_contacts[] | null;
}

export interface TutorsPage_getTutors {
  __typename: "Tutor";
  id: string;
  user: TutorsPage_getTutors_user;
  language: string;
  balance: number | null;
  isActive: boolean;
  slug: string;
  enabledPayments: PaymentOptionsType[];
  stripeAccountId: string | null;
  publish: boolean;
}

export interface TutorsPage {
  adminGetTutorTotalCount: number;
  getTutors: TutorsPage_getTutors[];
}

export interface TutorsPageVariables {
  pageInfo?: PageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUser
// ====================================================

export interface getUser_getUser_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface getUser_getUser_ban {
  __typename: "Ban";
  id: string;
}

export interface getUser_getUser_contacts_Stripe {
  __typename: "Stripe" | "StripeCustomer" | "ClassroomSettings" | "Fee";
  id: string;
}

export interface getUser_getUser_contacts_PaymentOptions {
  __typename: "PaymentOptions";
  id: string;
  otherMethodsEnabled: boolean;
  stripeEnabled: boolean;
}

export interface getUser_getUser_contacts_Email {
  __typename: "Email";
  id: string;
  verifiedAt: any | null;
  email: string | null;
}

export interface getUser_getUser_contacts_Phone {
  __typename: "Phone";
  id: string;
  phone: string | null;
}

export interface getUser_getUser_contacts_Billing {
  __typename: "Billing";
  id: string;
  name: string;
  street: string;
  city: string;
  zip: string;
  countryCode: string;
}

export type getUser_getUser_contacts = getUser_getUser_contacts_Stripe | getUser_getUser_contacts_PaymentOptions | getUser_getUser_contacts_Email | getUser_getUser_contacts_Phone | getUser_getUser_contacts_Billing;

export interface getUser_getUser_tutors {
  __typename: "Tutor";
  id: string;
}

export interface getUser_getUser_students {
  __typename: "Student";
  id: string;
}

export interface getUser_getUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: getUser_getUser_credentials[] | null;
  ban: getUser_getUser_ban | null;
  contacts: getUser_getUser_contacts[] | null;
  tutors: getUser_getUser_tutors[] | null;
  students: getUser_getUser_students[] | null;
}

export interface getUser {
  getUser: getUser_getUser | null;
}

export interface getUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getVideoPreviewsByTutorId
// ====================================================

export interface getVideoPreviewsByTutorId_getVideoPreviewsByTutorId {
  __typename: "VideoPreview";
  id: string;
  fileName: string;
  createdAt: any;
}

export interface getVideoPreviewsByTutorId {
  getVideoPreviewsByTutorId: getVideoPreviewsByTutorId_getVideoPreviewsByTutorId[];
}

export interface getVideoPreviewsByTutorIdVariables {
  tutorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getVouchers
// ====================================================

export interface getVouchers_getVouchers {
  __typename: "Voucher";
  id: string;
  code: string;
  creditAmount: number;
  maxRedemptions: number | null;
  redemptions: number;
  creditExpirationAbsolute: any | null;
  /**
   * Relative expiration in days
   */
  creditExpirationRelative: number | null;
  expiration: any | null;
  createdAt: any;
  type: VoucherType | null;
  bonusAmountPercent: number | null;
}

export interface getVouchers {
  getVouchers: getVouchers_getVouchers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWallets
// ====================================================

export interface getWallets_getWallets {
  __typename: "Wallet";
  balance: number;
  type: WalletType;
}

export interface getWallets {
  getWallets: getWallets_getWallets[];
}

export interface getWalletsVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: googleLogin
// ====================================================

export interface googleLogin_googleLogin {
  __typename: "AuthResponse";
  token: string;
  expiresAt: any;
}

export interface googleLogin {
  googleLogin: googleLogin_googleLogin | null;
}

export interface googleLoginVariables {
  code: string;
  isLocal?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupAddMember
// ====================================================

export interface GroupAddMember_group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupAddMember {
  group: GroupAddMember_group | null;
}

export interface GroupAddMemberVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupDetail
// ====================================================

export interface GroupDetail_group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupDetail_students_wallet_options {
  __typename: "WalletOptions";
  /**
   * How many per cent group cover for member in range 0-100%. 100 = all covered by group
   */
  coverage: number | null;
  /**
   * Monthly limit for group member spending
   */
  monthlyLimit: number | null;
}

export interface GroupDetail_students_wallet {
  __typename: "Wallet";
  id: string;
  balance: number;
  options: GroupDetail_students_wallet_options;
}

export interface GroupDetail_students {
  __typename: "GroupStudent";
  /**
   * UserId
   */
  id: string;
  department: string | null;
  firstName: string | null;
  lastName: string | null;
  groupRole: GroupRole;
  identifier: string;
  state: GroupUserState | null;
  wallet: GroupDetail_students_wallet | null;
}

export interface GroupDetail {
  group: GroupDetail_group | null;
  students: GroupDetail_students[];
}

export interface GroupDetailVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpersPrice
// ====================================================

export interface HelpersPrice {
  /**
   * For codegen purposes only
   */
  getCurrencies: Currency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paymentStats
// ====================================================

export interface paymentStats_adminPaymentStats {
  __typename: "PaymentStats";
  date: any;
  amount: number;
  paymentType: string;
  blabuState: string;
}

export interface paymentStats {
  adminPaymentStats: paymentStats_adminPaymentStats[];
}

export interface paymentStatsVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resolveId
// ====================================================

export interface resolveId_resolveId {
  __typename: "ResolvedId";
  id: string | null;
  type: string | null;
  errorMessage: string | null;
}

export interface resolveId {
  resolveId: resolveId_resolveId;
}

export interface resolveIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resolveUsers
// ====================================================

export interface resolveUsers_resolveUsers_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface resolveUsers_resolveUsers_ban {
  __typename: "Ban";
  id: string;
}

export interface resolveUsers_resolveUsers_contacts_Stripe {
  __typename: "Stripe" | "StripeCustomer" | "ClassroomSettings" | "Fee";
  id: string;
}

export interface resolveUsers_resolveUsers_contacts_PaymentOptions {
  __typename: "PaymentOptions";
  id: string;
  otherMethodsEnabled: boolean;
  stripeEnabled: boolean;
}

export interface resolveUsers_resolveUsers_contacts_Email {
  __typename: "Email";
  id: string;
  verifiedAt: any | null;
  email: string | null;
}

export interface resolveUsers_resolveUsers_contacts_Phone {
  __typename: "Phone";
  id: string;
  phone: string | null;
}

export interface resolveUsers_resolveUsers_contacts_Billing {
  __typename: "Billing";
  id: string;
  name: string;
  street: string;
  city: string;
  zip: string;
  countryCode: string;
}

export type resolveUsers_resolveUsers_contacts = resolveUsers_resolveUsers_contacts_Stripe | resolveUsers_resolveUsers_contacts_PaymentOptions | resolveUsers_resolveUsers_contacts_Email | resolveUsers_resolveUsers_contacts_Phone | resolveUsers_resolveUsers_contacts_Billing;

export interface resolveUsers_resolveUsers_tutors {
  __typename: "Tutor";
  id: string;
}

export interface resolveUsers_resolveUsers_students {
  __typename: "Student";
  id: string;
}

export interface resolveUsers_resolveUsers {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  credentials: resolveUsers_resolveUsers_credentials[] | null;
  ban: resolveUsers_resolveUsers_ban | null;
  contacts: resolveUsers_resolveUsers_contacts[] | null;
  tutors: resolveUsers_resolveUsers_tutors[] | null;
  students: resolveUsers_resolveUsers_students[] | null;
}

export interface resolveUsers {
  resolveUsers: resolveUsers_resolveUsers[] | null;
}

export interface resolveUsersVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountCalls
// ====================================================

export interface statsCountCalls_statsCountCalls {
  __typename: "DailyCalls";
  date: any;
  count: number;
}

export interface statsCountCalls {
  statsCountCalls: statsCountCalls_statsCountCalls[];
}

export interface statsCountCallsVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountSuccessfulInvites
// ====================================================

export interface statsCountSuccessfulInvites_statsCountSuccessfulInvites {
  __typename: "DailyCalls";
  date: any;
  count: number;
}

export interface statsCountSuccessfulInvites {
  statsCountSuccessfulInvites: statsCountSuccessfulInvites_statsCountSuccessfulInvites[];
}

export interface statsCountSuccessfulInvitesVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountUnsuccessfulCalls
// ====================================================

export interface statsCountUnsuccessfulCalls_statsCountUnsuccessfulCalls {
  __typename: "DailyCalls";
  date: any;
  count: number;
}

export interface statsCountUnsuccessfulCalls {
  statsCountUnsuccessfulCalls: statsCountUnsuccessfulCalls_statsCountUnsuccessfulCalls[];
}

export interface statsCountUnsuccessfulCallsVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountUnsuccessfulInvites
// ====================================================

export interface statsCountUnsuccessfulInvites_statsCountUnsuccessfulInvites {
  __typename: "DailyCalls";
  date: any;
  count: number;
}

export interface statsCountUnsuccessfulInvites {
  statsCountUnsuccessfulInvites: statsCountUnsuccessfulInvites_statsCountUnsuccessfulInvites[];
}

export interface statsCountUnsuccessfulInvitesVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountUnverifiedUsers
// ====================================================

export interface statsCountUnverifiedUsers_statsCountUnverifiedUsers {
  __typename: "DailyUsers";
  date: any;
  count: number;
}

export interface statsCountUnverifiedUsers {
  statsCountUnverifiedUsers: statsCountUnverifiedUsers_statsCountUnverifiedUsers[];
}

export interface statsCountUnverifiedUsersVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: statsCountUsers
// ====================================================

export interface statsCountUsers_statsCountUsers {
  __typename: "DailyUsers";
  date: any;
  count: number;
}

export interface statsCountUsers {
  statsCountUsers: statsCountUsers_statsCountUsers[];
}

export interface statsCountUsersVariables {
  dateRangeInput: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TutorCompletionStatus
// ====================================================

export interface TutorCompletionStatus_TutorCompletionStatus {
  __typename: "TutorCompletionStatus";
  completeProfile: boolean;
  oneCompletePackage: boolean;
  addedAvailability: boolean;
  connectedStripeAccount: boolean;
  completeBilling: boolean;
}

export interface TutorCompletionStatus {
  TutorCompletionStatus: TutorCompletionStatus_TutorCompletionStatus;
}

export interface TutorCompletionStatusVariables {
  tutorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PackageUpdateFragment
// ====================================================

export interface PackageUpdateFragment {
  __typename: "Package";
  id: string;
  tutorId: string;
  tutorUserId: string;
  active: boolean;
  name: string;
  /**
   * Duration in minutes
   */
  duration: number;
  numOfLessons: number;
  defaultPrice: number;
  defaultCurrency: string;
  description: string;
  prerequisites: string | null;
  level: string | null;
  score: number | null;
  topicIds: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TutorUpdateFragment
// ====================================================

export interface TutorUpdateFragment_user_credentials {
  __typename: "Credential";
  identifier: string;
}

export interface TutorUpdateFragment_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  countryCode: string | null;
  middleName: string | null;
  gender: Gender | null;
  birthday: any | null;
  timezone: string | null;
  image: string | null;
  credentials: TutorUpdateFragment_user_credentials[] | null;
}

export interface TutorUpdateFragment {
  __typename: "Tutor";
  id: string;
  language: string;
  introduction: string | null;
  rating: number;
  score: number | null;
  blockingTime: number;
  user: TutorUpdateFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InviteGroupMemberStudent
// ====================================================

export interface InviteGroupMemberStudent_wallet_options {
  __typename: "WalletOptions";
  /**
   * How many per cent group cover for member in range 0-100%. 100 = all covered by group
   */
  coverage: number | null;
  /**
   * Monthly limit for group member spending
   */
  monthlyLimit: number | null;
}

export interface InviteGroupMemberStudent_wallet {
  __typename: "Wallet";
  id: string;
  options: InviteGroupMemberStudent_wallet_options;
}

export interface InviteGroupMemberStudent {
  __typename: "GroupStudent";
  /**
   * UserId
   */
  id: string;
  department: string | null;
  firstName: string | null;
  groupRole: GroupRole;
  lastName: string | null;
  identifier: string;
  state: GroupUserState | null;
  wallet: InviteGroupMemberStudent_wallet | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupDetailStudent
// ====================================================

export interface GroupDetailStudent_wallet_options {
  __typename: "WalletOptions";
  /**
   * How many per cent group cover for member in range 0-100%. 100 = all covered by group
   */
  coverage: number | null;
  /**
   * Monthly limit for group member spending
   */
  monthlyLimit: number | null;
}

export interface GroupDetailStudent_wallet {
  __typename: "Wallet";
  id: string;
  balance: number;
  options: GroupDetailStudent_wallet_options;
}

export interface GroupDetailStudent {
  __typename: "GroupStudent";
  /**
   * UserId
   */
  id: string;
  department: string | null;
  firstName: string | null;
  lastName: string | null;
  groupRole: GroupRole;
  identifier: string;
  state: GroupUserState | null;
  wallet: GroupDetailStudent_wallet | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Theme of conversation AI will attempt to follow
 */
export enum AiConversationTheme {
  Business = "Business",
  Cooking = "Cooking",
  Family = "Family",
  Travel = "Travel",
}

/**
 * Focus of grammar AI will be trying to teach
 */
export enum AiGrammarFocus {
  Nouns = "Nouns",
  Tenses = "Tenses",
  Verbs = "Verbs",
}

/**
 * Complexity of language understanding AI is expecting
 */
export enum AiLanguageComplexity {
  Advanced = "Advanced",
  Beginner = "Beginner",
  Intermediate = "Intermediate",
}

/**
 * Type of AI status message, is derived as subset of MessageType
 */
export enum AiStatusMessageType {
  AiConversationReset = "AiConversationReset",
  AiInitialized = "AiInitialized",
  AiReservationExpired = "AiReservationExpired",
  AiReservationFree = "AiReservationFree",
  AiReservationPurchased = "AiReservationPurchased",
  AiReservationStarted = "AiReservationStarted",
  AiSettingsChanged = "AiSettingsChanged",
}

/**
 * Type of payment state - general blabu.
 */
export enum BlabuPaymentStateType {
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  REFUNDED = "REFUNDED",
  SUCCESS = "SUCCESS",
}

export enum ContactType {
  Billing = "Billing",
  ClassroomSettings = "ClassroomSettings",
  Email = "Email",
  Fee = "Fee",
  PaymentOptions = "PaymentOptions",
  Phone = "Phone",
  Stripe = "Stripe",
  StripeCustomer = "StripeCustomer",
}

export enum CourseTagType {
  Flag = "Flag",
  Level = "Level",
}

export enum CredentialType {
  Google = "Google",
  Invite = "Invite",
  Password = "Password",
  RestorePassword = "RestorePassword",
  Verify = "Verify",
}

/**
 * Exchange rate currency
 */
export enum Currency {
  AUD = "AUD",
  BGN = "BGN",
  CAD = "CAD",
  CZK = "CZK",
  EUR = "EUR",
  GBP = "GBP",
  HUF = "HUF",
  PLN = "PLN",
  USD = "USD",
}

/**
 * demand status
 */
export enum DemandStatus {
  Archived = "Archived",
  New = "New",
  OneTutor = "OneTutor",
  RequestedAgain = "RequestedAgain",
  TutorsSent = "TutorsSent",
}

/**
 * demand tutor status
 */
export enum DemandTutorStatus {
  Approved = "Approved",
  Offered = "Offered",
  Rejected = "Rejected",
}

/**
 * gender
 */
export enum Gender {
  Female = "Female",
  Male = "Male",
}

export enum GroupRole {
  groupAdmin = "groupAdmin",
  groupManager = "groupManager",
  groupUser = "groupUser",
}

export enum GroupType {
  Company = "Company",
  Family = "Family",
}

export enum GroupUserState {
  Active = "Active",
  NotRenew = "NotRenew",
  Stopped = "Stopped",
}

export enum MessageType {
  AbortedCallStudent = "AbortedCallStudent",
  AbortedCallTutor = "AbortedCallTutor",
  AiConversationReset = "AiConversationReset",
  AiInitialized = "AiInitialized",
  AiReservationExpired = "AiReservationExpired",
  AiReservationFree = "AiReservationFree",
  AiReservationPurchased = "AiReservationPurchased",
  AiReservationStarted = "AiReservationStarted",
  AiSettingsChanged = "AiSettingsChanged",
  CallBooked = "CallBooked",
  CallCancelled = "CallCancelled",
  CallEnded = "CallEnded",
  CallEndedStudent = "CallEndedStudent",
  CallEndedTutor = "CallEndedTutor",
  CreditGiven = "CreditGiven",
  File = "File",
  FinishingShift = "FinishingShift",
  Image = "Image",
  NeedBreak = "NeedBreak",
  ProScheduledCall = "ProScheduledCall",
  ProScheduledCallStudentRejected = "ProScheduledCallStudentRejected",
  ScheduledCall = "ScheduledCall",
  ScheduledCallExternal = "ScheduledCallExternal",
  ServiceGiven = "ServiceGiven",
  ServicePurchased = "ServicePurchased",
  StartWithChat = "StartWithChat",
  TrialLead = "TrialLead",
  User = "User",
  WelcomeStudent = "WelcomeStudent",
}

/**
 * Mollie payment method
 */
export enum MollieMethod {
  applepay = "applepay",
  banktransfer = "banktransfer",
  creditcard = "creditcard",
  przelewy24 = "przelewy24",
  sofort = "sofort",
}

export enum PaymentOptionsType {
  Other = "Other",
  Stripe = "Stripe",
}

/**
 * Tutor payout status
 */
export enum PayoutStatus {
  Canceled = "Canceled",
  Done = "Done",
  Failed = "Failed",
  New = "New",
}

/**
 * Tutor payout type
 */
export enum PayoutType {
  MANUAL = "MANUAL",
  STRIPE = "STRIPE",
}

/**
 * Message priority
 */
export enum Priority {
  High = "High",
  Low = "Low",
}

/**
 * Status of Purchase.
 */
export enum PurchaseStatus {
  Done = "Done",
  InProgress = "InProgress",
  New = "New",
}

export enum Role {
  Master = "Master",
  Student = "Student",
  Tutor = "Tutor",
}

/**
 * Type of shift (online, booking)
 */
export enum ShiftType {
  Booking = "Booking",
  Online = "Online",
}

/**
 * status
 */
export enum Status {
  Offline = "Offline",
  Online = "Online",
}

/**
 * Type of video status
 */
export enum StatusType {
  Done = "Done",
  Failed = "Failed",
  New = "New",
  Queued = "Queued",
}

export enum StripeBusinessType {
  company = "company",
  government_entity = "government_entity",
  individual = "individual",
  non_profit = "non_profit",
}

export enum StripeErrorType {
  api_error = "api_error",
  authentication_error = "authentication_error",
  card_error = "card_error",
  idempotency_error = "idempotency_error",
  invalid_grant = "invalid_grant",
  invalid_request_error = "invalid_request_error",
  rate_limit_error = "rate_limit_error",
}

/**
 * Type of payment transaction.
 */
export enum TransactionType {
  AffiliateCommission = "AffiliateCommission",
  AffiliateVat = "AffiliateVat",
  BlabuCommission = "BlabuCommission",
  BlabuPromoCredit = "BlabuPromoCredit",
  BlabuPromoCreditOnHold = "BlabuPromoCreditOnHold",
  GatewayFee = "GatewayFee",
  GroupPay = "GroupPay",
  GroupPayOnHold = "GroupPayOnHold",
  GroupTopUpOnHold = "GroupTopUpOnHold",
  RedeemVoucher = "RedeemVoucher",
  RefereeReward = "RefereeReward",
  ReferrerReward = "ReferrerReward",
  SodexoBonus = "SodexoBonus",
  StudentAiPurchase = "StudentAiPurchase",
  StudentGift = "StudentGift",
  StudentGiftCancel = "StudentGiftCancel",
  StudentGiftExpired = "StudentGiftExpired",
  StudentPay = "StudentPay",
  StudentPayOnHold = "StudentPayOnHold",
  StudentPayPromo = "StudentPayPromo",
  StudentPurchase = "StudentPurchase",
  StudentPurchasePromo = "StudentPurchasePromo",
  TopUp = "TopUp",
  TopUpCancel = "TopUpCancel",
  TopUpGiftVoucher = "TopUpGiftVoucher",
  TopUpGiftVoucherBonus = "TopUpGiftVoucherBonus",
  TopUpGiftVoucherTransfer = "TopUpGiftVoucherTransfer",
  TopUpGiftVoucherTransferTo = "TopUpGiftVoucherTransferTo",
  TopUpOnHold = "TopUpOnHold",
  TutorGift = "TutorGift",
  TutorGiftPayout = "TutorGiftPayout",
  TutorPayout = "TutorPayout",
  TutorReferrerReward = "TutorReferrerReward",
  TutorRevenue = "TutorRevenue",
  Vat = "Vat",
}

/**
 * Package visibility definition
 */
export enum Visibility {
  Individual = "Individual",
  Public = "Public",
}

/**
 * Type of voucher.
 */
export enum VoucherType {
  FirstCall = "FirstCall",
  FirstCallAccel = "FirstCallAccel",
  Flexible = "Flexible",
  Gift = "Gift",
  Promo = "Promo",
}

/**
 * Type of wallet.
 */
export enum WalletType {
  Blabu = "Blabu",
  Group = "Group",
  Stripe = "Stripe",
  User = "User",
  UserGroup = "UserGroup",
  UserOnHold = "UserOnHold",
  UserPromo = "UserPromo",
  UserVoucher = "UserVoucher",
  Vat = "Vat",
}

export enum WebLanguage {
  cs = "cs",
  en = "en",
}

export interface AdminAiConfigInput {
  title: string;
  config: any;
}

export interface AdminTutorInput {
  id?: string | null;
  language?: string | null;
  introduction?: string | null;
  rating?: number | null;
  publish?: boolean | null;
  user?: UserUpdateInput | null;
  score?: number | null;
  aiTutor?: boolean | null;
  blockingTime?: number | null;
}

export interface AiPricelistInput {
  price: number;
  currency: string;
  minutes: number;
}

export interface BanInput {
  comment?: string | null;
  userId: string;
}

export interface CancelGiftInput {
  userId: string;
  profileId: string;
  amount: number;
  comment?: string | null;
}

export interface ContactInput {
  type: ContactType;
  contact: string;
  primary?: boolean | null;
  userId?: string | null;
  contactId?: string | null;
}

export interface CourseInput {
  topicL1: string;
  topicL2: string;
  slug: string;
  flagCode?: string | null;
  showAsMain?: boolean | null;
  priority?: number | null;
}

export interface CourseTagTranslationInput {
  lang: WebLanguage;
  name: string;
}

export interface CourseTagWithTranslationInput {
  slug: string;
  type: CourseTagType;
  translations: CourseTagTranslationInput[];
}

export interface CourseTranslationInput {
  lang: WebLanguage;
  name: string;
  seoTitle?: string | null;
  seoDescription?: string | null;
  seoImage?: string | null;
}

export interface CourseWithTagsAndTranslationsInput {
  course: CourseInput;
  translations: CourseTranslationInput[];
  tags: CourseTagWithTranslationInput[];
}

export interface CredentialUpdateInput {
  id?: string | null;
  identifier: string;
  hash?: string | null;
}

export interface DateRangeInput {
  from: any;
  to: any;
}

export interface GroupBillingInput {
  taxId?: string | null;
  companyId?: string | null;
  email: string;
  street: string;
  city: string;
  countryCode?: string | null;
  zip: string;
}

export interface GroupContactInput {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface GroupInput {
  logo?: string | null;
  billing?: GroupBillingInput | null;
  contact?: GroupContactInput | null;
}

/**
 * Provide either userIds OR conversationId
 */
export interface MessageInput {
  content?: string | null;
  attachment?: any | null;
  conversationId?: string | null;
  fromUserId?: string | null;
  toUserId?: string | null;
  roomId?: string | null;
  messageType?: MessageType | null;
  priority?: Priority | null;
  additionalData?: any | null;
  refId?: string | null;
}

export interface PageInfo {
  first?: number | null;
  offset?: number | null;
}

export interface PaymentOptionsInput {
  stripeEnabled?: boolean | null;
  otherMethodsEnabled?: boolean | null;
}

export interface PromoCreditInput {
  userId: string;
  profileId: string;
  amount: number;
  currency?: string | null;
  expiresAt?: any | null;
  transactionType: TransactionType;
  sourceId?: string | null;
  createdAt?: any | null;
  comment?: string | null;
}

export interface StatusInput {
  status: Status;
  userId?: string | null;
  notify?: boolean | null;
  until?: any | null;
  text?: string | null;
}

export interface TopicInput {
  parentId?: string | null;
  name: string;
  slug: string;
}

export interface TopicWithTranslationsInput {
  topic?: TopicInput | null;
  translations?: TranslationInput[] | null;
}

export interface TranslationInput {
  lang: WebLanguage;
  name: string;
  headline?: string | null;
  subheadline?: string | null;
}

export interface TutorPayoutInput {
  amount: number;
  currency?: Currency | null;
  userId: string;
  tutorId: string;
  comment?: string | null;
  createdAt?: any | null;
  from: any;
  to: any;
}

export interface UpdatePackageInput {
  id: string;
  active?: boolean | null;
  name?: string | null;
  duration?: number | null;
  numOfLessons?: number | null;
  defaultPrice?: number | null;
  firstLessonFree?: boolean | null;
  defaultCurrency?: string | null;
  description?: string | null;
  prerequisites?: string | null;
  image?: string | null;
  thumbs?: boolean | null;
  topicIds?: string[] | null;
  courseId?: string | null;
  levels?: string[] | null;
  flags?: string[] | null;
  level?: string | null;
  score?: number | null;
}

export interface UserUpdateInput {
  id?: string | null;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  gender?: Gender | null;
  birthday?: any | null;
  displayName?: string | null;
  countryCode: string;
  timezone: string;
  image?: string | null;
  credential?: CredentialUpdateInput | null;
}

export interface VideoPreviewInput {
  tutorId: string;
  fileSize?: number | null;
  fileName: string;
}

export interface VoucherInput {
  maxRedemptions?: number | null;
  expiration?: any | null;
  creditAmount: number;
  bonusAmount?: number | null;
  bonusAmountPercent?: number | null;
  code?: string | null;
  creditExpirationAbsolute?: any | null;
  creditExpirationRelative?: number | null;
  type?: VoucherType | null;
}

export interface WalletOptionsInput {
  maxDebt?: number | null;
  monthlyLimit?: number | null;
  coverage?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
