import gql from 'graphql-tag'

export default gql`
  mutation savePaymentOptions($userId: String!, $input: PaymentOptionsInput!) {
    savePaymentOptions(userId: $userId, input: $input) {
      id
      otherMethodsEnabled
      stripeEnabled
    }
  }
`
