import { useMutation, useQuery } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'

import Layout from '../layouts/Layout'
import { Alert, GenericForm, RouterLink } from '../components'
import GroupMemberSchema, { initialValues, validationSchema, Values } from './formSchemas/GroupMemberSchema'
import NotificationService, { MessageType } from '../services/NotificationService'
import CREATE from '../gql/mutations/inviteGroupMember'
import {
  GroupAddMember,
  GroupAddMemberVariables,
  GroupRole,
  GroupUserState,
  InviteGroupMember,
  InviteGroupMemberVariables
} from '../gql/__generated__'
import QUERY from '../gql/queries/groupAddMemberQuery.api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormikHelpers } from 'formik'

type Props = WithApolloClient<RouteComponentProps<Params>>

type Params = {
  groupId: string
}

const GroupAddMemberPage = ({ client, match, history }: Props) => {
  const {
    params: { groupId }
  } = match
  const { data, loading, error } = useQuery<GroupAddMember, GroupAddMemberVariables>(QUERY, {
    client,
    variables: {
      groupId
    }
  })
  const { group = null } = data || {}
  const loaded = !loading && !error
  const [addMember] = useMutation<InviteGroupMember, InviteGroupMemberVariables>(CREATE, { client })

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    actions.setSubmitting(true)
    try {
      await addMember({
        variables: {
          ...values,
          groupRole: values.groupRole as GroupRole,
          state: values.state as GroupUserState,
          groupId
        }
      })
      actions.setSubmitting(false)
      NotificationService.show(MessageType.success, 'Saved successfully')
      history.push(`/groups/${groupId}`)
    } catch (err: any) {
      actions.setSubmitting(false)
      NotificationService.showError(err.message)
    }
  }
  return (
    <Layout>
      {loading && <CircularProgress size={20} thickness={5} />}
      {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
      {loaded && group && (
        <>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant='h4'>
                Add member to {group.name} group
                <RouterLink to={`/groups/${groupId}`}>
                  <Button style={{ float: 'right' }} variant='contained'>
                    Back to group
                  </Button>
                </RouterLink>
              </Typography>
            </Box>
          </Box>
          <GenericForm
            getInitialValues={() => initialValues}
            schema={GroupMemberSchema}
            submitBtnTitle='Add member'
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          />
        </>
      )}
      {loaded && !group && <Alert>Group not found</Alert>}
    </Layout>
  )
}

export default withApollo(GroupAddMemberPage)
