import toastr from 'toastr'
import 'toastr/toastr.scss'

toastr.options = {
  preventDuplicates: true
}

export enum MessageType {
  error,
  info,
  success,
  warning
}

class NotificationService {
  static show(type: MessageType, msg: string) {
    if (type === MessageType.error) {
      return NotificationService.showError(msg)
    } else if (type === MessageType.warning) {
      return NotificationService.showWarning(msg)
    } else if (type === MessageType.info) {
      return NotificationService.showInfo(msg)
    } else if (type === MessageType.success) {
      return NotificationService.showSuccess(msg)
    }

    throw new Error(`Unhandled toast type '${type}'`)
  }

  static showError(title: string, error: string = '') {
    toastr.error(error, title)
  }

  static showWarning(msg: string) {
    toastr.warning(msg)
  }

  static showInfo(msg: string) {
    toastr.info(msg)
  }

  static showSuccess(msg: string) {
    toastr.success(msg)
  }
}

export default NotificationService
