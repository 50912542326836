import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import ProfileDetail from '../components/ProfileDetail'
import { withApollo, WithApolloClient } from 'react-apollo'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import TutorPackagesContent from '../components/TutorPackagesContent'
import { isStudent } from '../Utils'
import StudentPackagesContent from '../components/StudentPackagesContent'
import { Typography } from '@material-ui/core'
import getProfileQuery from '../gql/queries/getProfile'
import { getProfile_getProfileFixed as Profile, adminGetAllCourses_adminGetAllCourses as Course, adminGetAllCourses } from '../gql/__generated__'
import adminGetAllCoursesQuery from '../gql/queries/adminGetAllCourses'
import NotificationService from '../services/NotificationService'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
  }>

type State = {
  profile: Profile
  isLoaded: boolean
  allCourses: Course[]
}

class PackagesPage extends React.Component<Props, State> {
  state = {
    profile: {} as Profile,
    allCourses: [] as Course[],
    isLoaded: false
  }

  setUserProfile = async () => {
    const profile = await this.getProfile(this.props.match.params.profileId)
    this.setState({ profile })
  }

  setAllCourses = async () => {
    const { client } = this.props
    const result = await client.query<adminGetAllCourses>({query: adminGetAllCoursesQuery})
    this.setState({ allCourses: result.data.adminGetAllCourses })
  }

  componentDidMount = async () => {
    try {
      await Promise.all([this.setUserProfile(), this.setAllCourses()])
      this.setState({ isLoaded: true })
    } catch (err) {
      NotificationService.showError('Error when loading profile and courses data')
      console.error(err);
    }
  }

  getProfile = async (id: string): Promise<Profile> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  render() {
    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return <CircularProgress size={20} thickness={5} />
    }

    return (
      <Layout>
        <Typography variant='h4'>Packages</Typography>
        <UserPagesNavigation title={UserPagesTitles.Packages} profileId={profileId} />
        <ProfileDetail profile={this.state.profile} profileId={profileId} />
        {isStudent(profileId) ? (
          <StudentPackagesContent profileId={profileId} userId={this.state.profile.user.id} allCourses={this.state.allCourses} />
        ) : (
          <TutorPackagesContent profileId={profileId} allCourses={this.state.allCourses} />
        )}
      </Layout>
    )
  }
}

export default withApollo(withRouter(PackagesPage))
