import gql from 'graphql-tag'

export default gql`
  query adminGetTutorProps($tutorId: String!) {
    adminGetTutorProps(tutorId: $tutorId) {
      tutorId
      completeProfile
      oneCompletePackage
      connectedStripeAccount
      completeBilling
    }
  }
`