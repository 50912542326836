import { FormSchema } from '../../components'

export const initialValues = {
  comment: ''
}

const BanFormSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Ban user',
      fields: [
        {
          label: 'Edit comment',
          accessor: 'comment',
          fieldProps: {
            multiline: true
          }
        }
      ]
    }
  ]
}

export default BanFormSchema
