import React, { ReactChild, SyntheticEvent } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

type PropsType = RouteComponentProps & {
  children: ReactChild
  to: string
  passHref?: boolean
}

class LinkImpl extends React.Component<PropsType> {
  render() {
    const { children, history, to, passHref } = this.props

    return React.Children.map(children, (child) => {
      return React.cloneElement(child as React.ReactElement<any>, {
        href: passHref ? to : undefined,
        onClick: (ev: SyntheticEvent) => {
          ev.preventDefault()
          history.push(to)
        }
      })
    })
  }
}

const RouterLink = withRouter(LinkImpl)

export { RouterLink }
