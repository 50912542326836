import { ApolloClient } from 'apollo-boost'
import {
  AiPricelistInput,
  getAiPricelists_getAiPricelists as aiPricelist,
  adminCreateAiPricelist,
  adminCreateAiPricelistVariables,
  adminDeleteAiPricelist,
  adminDeleteAiPricelistVariables,
  adminUpdateAiPricelist,
  adminUpdateAiPricelistVariables
} from '../../gql/__generated__'
import { FormSubmitValues, Operation } from './AiPricelistsPage'
import adminCreateaiPricelistMutation from '../../gql/mutations/adminCreateAiPricelist'
import adminUpdateaiPricelistMutation from '../../gql/mutations/adminUpdateAiPricelist'
import adminDeleteaiPricelistMutation from '../../gql/mutations/adminDeleteAiPricelist'
import { FormSchema } from '../../components'

export function getInitialValues(aiPricelistObject: aiPricelist) {
  return {
    id: aiPricelistObject.id,
    minutes: aiPricelistObject.minutes,
    price: aiPricelistObject.price,
    currency: aiPricelistObject.currency    
  }
}

export function getAiPricelistInput(values: FormSubmitValues) {
  return {
    minutes: parseInt(values.minutes),
    price: parseFloat(values.price),
    currency: values.currency
  }
}

export async function createAiPricelist(client: ApolloClient<any>, aiPricelistInput: AiPricelistInput): Promise<void> {
  await client.mutate<adminCreateAiPricelist, adminCreateAiPricelistVariables>({
    mutation: adminCreateaiPricelistMutation,
    variables: {
      input: aiPricelistInput
    }
  })
}

export async function updateAiPricelist(
  client: ApolloClient<any>,
  aiPricelistId: string,
  aiPricelistInput: AiPricelistInput
): Promise<void> {
  await client.mutate<adminUpdateAiPricelist, adminUpdateAiPricelistVariables>({
    mutation: adminUpdateaiPricelistMutation,
    variables: {
      id: aiPricelistId,
      input: aiPricelistInput
    }
  })
}

export async function deleteAiPricelist(client: ApolloClient<any>, aiPricelistId: string): Promise<void> {
  await client.mutate<adminDeleteAiPricelist, adminDeleteAiPricelistVariables>({
    mutation: adminDeleteaiPricelistMutation,
    variables: {
      id: aiPricelistId
    }
  })
}

export function getaiPricelistFormSchema(operation: Operation): FormSchema {
  const fields = []

  if (operation === 'update') {
    fields.push({
      label: 'AI Pricelist ID',
      accessor: 'id',
      fieldProps: { disabled: true }
    })
  }

  return {
    fieldsets: [
      {
        title: `${operation === 'create' ? 'Create' : 'Update'} AI Pricelist`,
        fields: [
          ...fields,
          {
            label: 'Minutes',
            accessor: 'minutes',
            fieldProps: { number: true, required: true, min: 1, integer: true }
          },
          {
            label: 'Currency',
            accessor: 'currency',
            fieldProps: { required: true }
          },
          {
            label: 'Price',
            accessor: 'price',
            fieldProps: { required: true, number: true }
          }
        ]
      }
    ]
  }
}
