import gql from 'graphql-tag';

export default gql`
  query adminGetTopicsCourses {
    getTranslatedTopics(lang: "en") {
    	id
    	name
  	}
    getAllTranslatedCourses(mainOnly: false, lang: "en") { 
      id
    	name
    }
  }
`