import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import Layout from '../../layouts/Layout'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { GenericTable } from '../../components'
import { useCoursesPage } from './useCoursesPage'
import { OrganizedTopic } from './useCoursesPage'
import ReactCountryFlag from "react-country-flag"

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const CoursesPage = ({ client }: Props) => { 
  const { topics } = useCoursesPage(client)

  const TagList = ({ tags }: { tags: string[] }) => {
    return <ul>
      {tags.map((tag, i) => <li key={i}>{tag}</li>)}
    </ul>
  } 

  const columns = [
    {
      Header: 'L1',
      accessor: 'topicL1Name'
    },
    {
      Header: 'L2',
      accessor: 'topicL2Name'
    },    
    {
      Header: 'Subject',
      accessor: 'courseName'
    },
    {
      Header: 'Slug',
      accessor: 'courseSlug'
    }, 
    {
      Header: 'Course ID',
      accessor: 'courseId'
    }, 
    {
      Header: 'Levels',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        && Array.isArray(item.courseLevels) 
        && item.courseLevels.length > 0
        ? <TagList tags={item.courseLevels} /> 
        : null
    },
    {
      Header: 'Flags',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        && Array.isArray(item.courseFlags) 
        && item.courseFlags.length > 0
        ? <TagList tags={item.courseFlags} /> 
        : null
    },  
    {
      Header: 'Flag',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        && item.courseFlagCode
        ? <ReactCountryFlag countryCode={item.courseFlagCode} svg style={{ width: '2rem', height: '1.5rem', boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.4)'}} />
        : null
    },
    {
      Header: 'Show as Main',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        && item.courseShowAsMain
        ? '✓ Main' : null
    },  
    {
      Header: 'Priority',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        && item.coursePriority        
    },    
    {
      Header: 'Edit',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        ? 'Edit' : 'Create',
      link: (item: OrganizedTopic) => item.hasCourse 
        && item.courseId
        ? `/courses/edit/${item.courseId}`
        : `/courses/create/${item.topicL1}/${item.topicL2}`
    },
    {
      Header: 'Packages',
      accessor: (item: OrganizedTopic) => item.hasCourse 
        ? 'See packages' : '-',
      link: (item: OrganizedTopic) => item.hasCourse 
        && item.courseId
        ? `/courses/packages/${item.courseId}`
        : null
    }

  ]

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Course Catalog
      </Typography>
      <GenericTable data={topics} columns={columns} />
    </Layout>
  )
}

export default withApollo(CoursesPage)
