import { ApolloClient } from "apollo-boost"
import { FormSubmitValues, Operation } from "./AiConfigsPage"
import { useEffect, useState } from "react"
import NotificationService, { MessageType } from "../../services/NotificationService"
import { adminGetAiConfigs, adminGetAiConfigs_adminGetAiConfigs } from "../../gql/__generated__"
import adminGetAiConfigsQuery from "../../gql/queries/adminGetAiConfigsQuery"
import { createAiConfig, deleteAiConfig, getAiConfigInput, getInitialValues, updateAiConfig } from "./util"
import { FormikHelpers } from "formik"

type FormInitialValues = {
  title: string  
  firstMessage0: string
  firstMessage1: string
  firstMessage2: string
  systemPrompt: string
  messagesBack: number
}

const formInitialValues:FormInitialValues = {
  title: '',
  systemPrompt: '',
  messagesBack: 8,  
  firstMessage0: '',
  firstMessage1: '',
  firstMessage2: '',
}

export function useAiConfigsPage(client: ApolloClient<any>) {
  const [aiConfigs, setAiConfigs] = useState<adminGetAiConfigs_adminGetAiConfigs[]>([])
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [operation, setOperation] = useState<Operation>('create')

  useEffect(() => {
    fetchAiConfigs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEdit = (aiConfigId: string) => {
    const aiConfigObject = aiConfigs.find((item) => item.id === aiConfigId)
    if (aiConfigObject) {
      setOperation('update')
      setInitialValues(getInitialValues(aiConfigObject))
    }
  }

  const resetState = () => {
    setOperation('create')
    setInitialValues(formInitialValues)    
    fetchAiConfigs()
  }

  const handleSubmit = async (values: FormSubmitValues, actions: FormikHelpers<FormSubmitValues>) => {
    const aiConfigInput = getAiConfigInput(values)
    
    try {
      if (operation === 'create') {
        await createAiConfig(client, aiConfigInput)
      } else {
        await updateAiConfig(client, values.id!, aiConfigInput)
      }      
    } catch (err: any) {
      NotificationService.showError(`AI Config ${operation} action failed`, err)
      return
    }

    resetState()
    actions.resetForm()
    NotificationService.show(MessageType.success, `AI Config ${operation} action successful`)
    
  }

  const handleDelete = async(aiConfigId: string) => {
    try {
      await deleteAiConfig(client, aiConfigId)
    } catch (err: any) {
      NotificationService.showError('Delete of aiConfig failed', err)
      return
    }
    resetState()
    NotificationService.show(MessageType.success, `AI Config removal successful`)
  }

  const fetchAiConfigs = async () => {
    try {
      const aiConfigs = await client.query<adminGetAiConfigs>({ query: adminGetAiConfigsQuery })
      setAiConfigs(aiConfigs.data.adminGetAiConfigs)
    } catch (err: any) {
      NotificationService.showError('Fetch of aiConfigs failed', err)
    }
  }

  

  return {
    aiConfigs,
    initialValues,
    operation,
    onEdit,
    handleSubmit,
    handleDelete
  }
}