import React from 'react'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withApollo, WithApolloClient } from 'react-apollo'
import { Button } from '@material-ui/core'
import NotificationService from '../services/NotificationService'
import savePaymentOptions from '../gql/mutations/savePaymentOptions'
import {
  getProfile_getProfileFixed_Tutor,
  getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions
} from '../gql/__generated__'

type Props = WithApolloClient<{}> &
  WithStyles<typeof styles> & {
    profile: getProfile_getProfileFixed_Tutor
  }

const styles = {
  card: {
    marginBottom: 20
  }
}

class EnableStripeBtn extends React.Component<Props> {
  state = {
    stripeEnabled: null
  }

  handleClick = async (userId: string, stripeEnabled = true) => {
    const { client } = this.props
    const input = {
      stripeEnabled: stripeEnabled,
      otherMethodsEnabled: !stripeEnabled
    }
    const result = await client.mutate({
      mutation: savePaymentOptions,
      variables: {
        userId,
        input
      }
    })
    this.setState({ ...result.data.savePaymentOptions })
    stripeEnabled && NotificationService.showSuccess('Tutor stripe enabled')
    !stripeEnabled && NotificationService.showSuccess('Tutor stripe disabled')
  }

  render() {
    const { profile } = this.props

    // @ts-ignore
    if (profile.__typename === 'Student') {
      return <></>
    }

    const stripeEnabledReal = (profile?.user?.contacts?.find(
      (p) => p.__typename === 'PaymentOptions'
    ) as getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions)?.stripeEnabled

    return (
      <>
        {!stripeEnabledReal ? (
          <Button
            color='primary'
            variant='contained'
            onClick={async () => await this.handleClick(profile.user.id, true)}
          >
            enable Stripe
          </Button>
        ) : (
          <Button
            color='secondary'
            variant='contained'
            onClick={async () => await this.handleClick(profile.user.id, false)}
          >
            disable Stripe
          </Button>
        )}
      </>
    )
  }
}

export default withStyles(styles)(withApollo(EnableStripeBtn))
