import gql from 'graphql-tag';

export default gql`
  mutation adminUpdateTopic($topicId: String! $topicInput: TopicWithTranslationsInput!) {
    adminUpdateTopic(
      topicId: $topicId
      topicInput: $topicInput
    ) {
      id
      name
      parentId
      createdAt
    }
  }
`
