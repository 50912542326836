import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { RouteComponentProps } from 'react-router'
import { withApollo, WithApolloClient } from 'react-apollo'
import { Rating, User } from '../types'
import getRatingQuery from '../gql/queries/getRating'
import getProfileQuery from '../gql/queries/getProfile'
import RatingDetail from '../components/RatingDetail'
import { GenericForm } from '../components'
import EditRatingCommentSchema from './formSchemas/EditRatingCommentSchema'
import editRatingCommentMutation from '../gql/mutations/editRatingComment'
import NotificationService from '../services/NotificationService'
import { Typography } from '@material-ui/core'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    ratingId: string
  }>

interface Profile {
  id: string
  user: User
}

interface State {
  rating: Rating
  ratedProfile: Profile
  givingProfile: Profile
  isLoaded: boolean
}

class RatingDetailPage extends React.Component<Props, State> {
  state = {
    rating: {
      id: '',
      ratingTotal: 0,
      givingProfileId: '',
      ratedProfileId: '',
      createdAt: new Date(),
      comment: '',
      visible: false
    },
    ratedProfile: {} as Profile,
    givingProfile: {} as Profile,
    isLoaded: false
  }

  componentDidMount = async () => {
    await this.getRating()
    const givingProfilePromise = this.getProfile(this.state.rating.givingProfileId)
    const ratedProfilePromise = this.getProfile(this.state.rating.ratedProfileId)
    const [givingProfile, ratedProfile] = await Promise.all([givingProfilePromise, ratedProfilePromise])
    this.setState({ isLoaded: true, givingProfile, ratedProfile })
  }

  getRating = async () => {
    const { client, match } = this.props
    const result = await client.query({
      query: getRatingQuery,
      variables: {
        id: match.params.ratingId
      }
    })
    this.setState({ rating: result.data.getRating })
    return result.data.getRating
  }

  getProfile = async (id: string): Promise<Profile> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  handleSubmit = async (values: any) => {
    const { client } = this.props
    await client.mutate({
      mutation: editRatingCommentMutation,
      variables: {
        id: this.state.rating.id,
        comment: values.comment
      }
    })
    this.props.history.push(`/ratings/${this.state.ratedProfile.id}`)
    NotificationService.showSuccess('Comment successfully updated')
    return true
  }

  getInitialValues = () => {
    return {
      comment: this.state.rating.comment
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null
    }

    return (
      <Layout>
        <Typography variant='h4'>Rating - edit comment</Typography>
        <RatingDetail
          rating={this.state.rating}
          givingUser={this.state.givingProfile.user}
          ratedUser={this.state.ratedProfile.user}
        />
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={EditRatingCommentSchema}
          onSubmit={this.handleSubmit}
        />
      </Layout>
    )
  }
}

export default withApollo(withRouter(RatingDetailPage))
