import React from 'react'
import { Query, withApollo, WithApolloClient } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import adminFindRooms from '../gql/queries/adminFindRooms'
import getCompositionFile from '../gql/queries/getCompositionSid'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { IconButton } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Typography from '@material-ui/core/Typography'
import { adminFindRooms_adminFindRooms as Room } from '../gql/__generated__'
import { TablePaginationProps } from '@material-ui/core/TablePagination'
import { withStyles, WithStyles } from '@material-ui/core/styles'

const getParticipant = (room: any, role: string) => {
  return room.participants.find((participant: any) => participant.role === role)
}

const styles = {
  card: {
    marginBottom: 20
  }
}

const tutorRow = {
  Header: 'Tutor',
  accessor: (item: any) => {
    const participant = getParticipant(item, 'Tutor')
    return participant
      ? `${participant.profile.user.firstName} ${participant.profile.user.lastName}`
      : 'Tutor not found'
  },
  link: (item: any) => {
    const participant = getParticipant(item, 'Tutor')
    return participant ? `/profile/${participant.profileId}` : undefined
  }
}

const studentRow = {
  Header: 'Student',
  accessor: (item: any) => {
    const participant = getParticipant(item, 'Student')
    return participant
      ? `${participant.profile.user.firstName} ${participant.profile.user.lastName}`
      : 'Student not found'
  },
  link: (item: any) => {
    const participant = getParticipant(item, 'Student')
    return participant ? `/profile/${participant.profileId}` : undefined
  }
}

interface Data {
  adminGetWalletBalance: any
  getProfileFixed: any
}

class GetProfileQuery extends Query<Data> {}

type State = {
  rooms: Room[]
  profileId: string
  isLoaded: boolean
  pagination: TablePaginationProps
}

type Props = WithApolloClient<{}> &
  WithStyles<typeof styles> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

class ProfileCalls extends React.Component<Props, State> {
  handleChangeRowsPerPage = (event: any) => {
    const rowsPerPage = +event.target.value
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
    this.findRooms(rowsPerPage, 0).then((result) => {
      this.setState({ isLoaded: true, rooms: result })
    })
  }

  handleChangePage = (events: any, page: number) => {
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
    this.findRooms(this.state.pagination.rowsPerPage, this.state.pagination.rowsPerPage * page).then((result) => {
      this.setState({ isLoaded: true, rooms: result })
    })
  }

  state = {
    rooms: [],
    profileId: this.props.match.params.profileId,
    isLoaded: false,
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 15,
      rowsPerPageOptions: [15, 30, 50],
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangePage: this.handleChangePage
    }
  }

  onUpdate = async () => {
    const result = await this.findRooms(this.state.pagination.rowsPerPage, 0)
    this.setState({ isLoaded: true, rooms: result })
  }

  componentDidMount = async () => {
    await this.onUpdate()
  }

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevProps === undefined) {
      return false
    }
    const profileId = this.props.match.params.profileId
    if (this.state.profileId !== profileId) {
      await this.onUpdate()
    }
  }

  getCompositionsFile = async (roomId: string) => {
    const { client } = this.props
    const result = await client.query({
      query: getCompositionFile,
      variables: {
        roomId
      }
    })
    return result.data.getCompositionFile
  }

  findRooms = async (first = 30, offset = 0) => {
    const { client, match } = this.props
    const profileId = match.params.profileId
    const result = await client.query({
      query: adminFindRooms,
      variables: {
        profileId,
        pageInfo: {
          first,
          offset
        }
      }
    })
    return result.data.adminFindRooms
  }

  handleClick = async (roomId: string) => {
    const compositionFile = await this.getCompositionsFile(roomId)

    if (compositionFile) {
      window.location.href = compositionFile
    } else {
      NotificationService.showError('No composition exists for this room')
    }
  }

  render() {
    const columns: any[] = [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'duration(s)',
        accessor: 'duration'
      },
      {
        Header: 'started',
        accessor: (room: any) => {
          return formatDate(room.started)
        }
      },
      {
        Header: 'ended',
        accessor: (room: any) => formatDate(room.ended)
      },
      {
        Header: 'actual duration',
        accessor: 'actualDuration'
      },
      {
        Header: 'cancelled',
        accessor: (room: any) => (room.cancelled ? 'yes' : '')
      },
      {
        Header: 'price Charged',
        accessor: 'priceCharged'
      },
      {
        Header: 'download',
        accessor: (room: any) => {
          if (room.video && room.video.status === 'Queued') {
            return (
              <IconButton onClick={async () => await this.handleClick(room.id)}>
                <DownloadIcon />
              </IconButton>
            )
          }
        }
      },
      {
        Header: 'package',
        accessor: (room: any) => room.lesson && room.lesson.package.name
      },
      {
        Header: 'twilioSid',
        accessor: 'twilioSid'
      },
      {
        Header: 'scheduledCallId',
        accessor: (room: any) => room.scheduledCall && room.scheduledCall.id
      },
      {
        Header: 'state',
        accessor: 'state'
      },      
      {
        Header: 'meta',
        accessor: (room: any) => JSON.stringify(room.meta)
      }
    ]    
    const profileId = this.props.match.params.profileId
    let profileType = 'tutor'
    if (profileId.startsWith('ST')) {
      profileType = 'student'
      columns.push(tutorRow)
    } else {
      columns.push(studentRow)
    }

    return (
      <Layout>
        <Typography variant='h4'>{profileType === 'tutor' ? 'Tutor' : 'Student'} detail - list of calls</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Calls} />
        <GetProfileQuery query={getProfile} variables={{ id: profileId }}>
          {({ data, loading, error }) => {
            return (
              <div>
                {loading && <CircularProgress size={20} thickness={5} />}
                {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
                {!loading && data && <ProfileDetail profile={data.getProfileFixed} profileId={profileId} />}
              </div>
            )
          }}
        </GetProfileQuery>
        {this.state.isLoaded ? (
          <GenericTable data={this.state.rooms} columns={columns} pagination={this.state.pagination} />
        ) : (
          <CircularProgress size={20} thickness={5} />
        )}
      </Layout>
    )
  }
}

export default withStyles(styles)(withApollo(withRouter(ProfileCalls)))
