import gql from 'graphql-tag'

export default gql`
  query adminGetThumbStats {
    adminGetThumbStats {
      thumbsOnline
      thumbsPending
      noImage
    }
  }
`