import gql from 'graphql-tag'

export default gql`
  query getVideoPreviewsByTutorId($tutorId: String!) {
    getVideoPreviewsByTutorId(tutorId: $tutorId) {
      id
      fileName
      createdAt
    }
  }
`
