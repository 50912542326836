import gql from 'graphql-tag'

export default gql`
  query adminGetDemands($pageInfo: PageInfo!) {
    adminGetDemands(pageInfo: $pageInfo) {
      id
      user {
        students {
          id
        }
        overrideName        
      }      
      courseId
      topicId
      tutorId      
      packageId
      note      
      status
      demandFormTutors {
        id        
      }
      createdAt
      updatedAt
    }
  }
`