import { RouteComponentProps, withRouter } from 'react-router-dom'
import React from 'react'
import Cookies from 'js-cookie'
import { Avatar, Button, Container, CssBaseline, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const styles = {
  paper: {
    marginTop: '150px',
    display: 'flex',
    flexDirection: 'column' as any,
    alignItems: 'center',
    border: '2px solid lightgray',
    padding: '20px'
  },
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    flex: 'none',
    maxWidth: '50%',
    fontSize: '18px'
  }
}

class LoginPage extends React.Component<RouteComponentProps, {}> {
  componentDidMount() {
    Cookies.remove('admin_token')
  }

  render() {
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div style={styles.paper}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Truano Admin
          </Typography>
          <Button type='submit' fullWidth variant='contained' color='primary' href='/_oauth/google'>
            Login via Google
          </Button>
        </div>
      </Container>
    )
  }
}

export default withRouter(LoginPage)
