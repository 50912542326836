import { FormSchema } from '../../components'
import { FormikDateTimePicker } from '../../components/FormikDateTimePicker'
import { VoucherType } from '../../gql/__generated__'

const initialValues = {
  creditAmount: '',
  maxRedemptions: '',
  expiration: ''
}

const CreateVoucherSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Create voucher',
      fields: [
        {
          label: 'Type',
          accessor: 'type',
          fieldProps: {
            select: true,
            selectOptions: Object.values(VoucherType)
              .filter((type) => type !== 'Gift')
              .map((type) => ({
                label: type,
                value: type
              }))
          }
        },
        {
          label: 'Credit amount (0 for promo)',
          accessor: 'creditAmount'
        },
        {
          label: 'Percent amount (only for promo)',
          accessor: 'bonusAmountPercent'
        },
        {
          label: 'Max redemptions',
          accessor: 'maxRedemptions'
        },
        {
          label: 'Code',
          accessor: 'code'
        },
        {
          label: 'Voucher expiration',
          accessor: 'expiration',
          component: FormikDateTimePicker,
          fieldProps: {
            disableFuture: false,
            disablePast: true
          }
        },
        {
          label: 'Credit expiration absolute',
          accessor: 'creditExpirationAbsolute',
          component: FormikDateTimePicker,
          fieldProps: {
            disableFuture: false,
            disablePast: true
          }
        },
        {
          label: 'Credit expiration relative (in days)',
          accessor: 'creditExpirationRelative'
        }
      ]
    }
  ]
}

export default CreateVoucherSchema

export { initialValues }
