import { ApolloClient } from 'apollo-boost'
import { FormSchema } from '../../components'
import adminCreateTopicMutation from '../../gql/mutations/adminCreateTopic'
import adminUpdateTopicMutation from '../../gql/mutations/adminUpdateTopic'
import {
  adminCreateTopic,
  adminCreateTopicVariables,
  adminGetTopics_adminGetTopics,
  adminGetTopics_adminGetTopics as Topic,
  adminGetTopics_adminGetTopics_translations,
  adminUpdateTopic,
  adminUpdateTopicVariables,
  TopicWithTranslationsInput,
  WebLanguage
} from '../../gql/__generated__'
import { FormSubmitValues, Operation } from './TopicsPage'

export const getTranslation = (translations: adminGetTopics_adminGetTopics_translations[], lang: WebLanguage, type: string) =>{
  if (type === "name") return translations.find((translation) => translation.lang === lang)?.name 
  if (type === "headline") return translations.find((translation) => translation.lang === lang)?.headline 
  if (type === "subheadline") return translations.find((translation) => translation.lang === lang)?.subheadline 
}
  

export function getTopicFormSchema(operation: Operation, topics: adminGetTopics_adminGetTopics[]): FormSchema {
  const fields = []

  if (operation === 'update') {
    fields.push({
      label: 'Topic ID',
      accessor: 'id',
      fieldProps: { disabled: true }
    })
  }

  return {
    fieldsets: [
      {
        title: `${operation === 'create' ? 'Add' : 'Change'} topic`,
        fields: [
          ...fields,
          {
            label: 'Name',
            accessor: 'name',
            fieldProps: { required: true }
          },
          {
            label: 'Parent',
            accessor: 'parentId',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: '' },
                ...topics.map((topic: Topic) => {
                  return { value: topic.topic.id, label: topic.topic.name }
                })
              ]
            }
          },
          {
            label: 'Slug',
            accessor: 'slug',
            fieldProps: { required: true }
          },
          {
            label: 'English Name',
            accessor: "enName",
            fieldProps: { required: true }
          },
          {
            label: 'English Headline',
            accessor: "enHeadline",
          },
          {
            label: 'English Subheadline',
            accessor: "enSubheadline",
          },
          {
            label: 'Czech Name',
            accessor: "csName",
            fieldProps: { required: true }
          },
          {
            label: 'Czech Headline',
            accessor: "csHeadline",
          },
          {
            label: 'Czech Subheadline',
            accessor: "csSubheadline",
          }
        ]
      }
    ]
  }
}

export function getInitialValues(topicObject: Topic) {
  return {
    id: topicObject.topic.id,
    name: topicObject.topic.name,
    parentId: topicObject.topic.parentId,
    slug: topicObject.topic.slug,
    enName: topicObject?.translations.find((translation) => translation.lang === WebLanguage.en)?.name || '',
    enHeadline: topicObject?.translations.find((translation) => translation.lang === WebLanguage.en)?.headline || '',
    enSubheadline: topicObject?.translations.find((translation) => translation.lang === WebLanguage.en)?.subheadline || '',
    csName: topicObject?.translations.find((translation) => translation.lang === WebLanguage.cs)?.name || '',
    csHeadline: topicObject?.translations.find((translation) => translation.lang === WebLanguage.cs)?.headline || '',
    csSubheadline: topicObject?.translations.find((translation) => translation.lang === WebLanguage.cs)?.subheadline || ''
  }
}

export function getTopicInput(values: FormSubmitValues): TopicWithTranslationsInput {
  return {
    topic: {
      name: values.name,
      slug: values.slug,
      parentId: values.parentId && values.parentId !== "null" ? String(values.parentId) : null
    },
    translations: [
      {
        lang: WebLanguage.en,
        name: values.enName,
        headline: values.enHeadline,
        subheadline: values.enSubheadline
      },
      {
        lang: WebLanguage.cs,
        name: values.csName,
        headline: values.csHeadline,
        subheadline: values.csSubheadline
      }
    ]
  }
}

export async function createTopic(client: ApolloClient<any>, topicInput: TopicWithTranslationsInput): Promise<void> {
  await client.mutate<adminCreateTopic, adminCreateTopicVariables>({
    mutation: adminCreateTopicMutation,
    variables: {
      topicInput
    }
  })
}

export async function updateTopic(
  client: ApolloClient<any>,
  topicId: string,
  topicInput: TopicWithTranslationsInput
): Promise<void> {
  await client.mutate<adminUpdateTopic, adminUpdateTopicVariables>({
    mutation: adminUpdateTopicMutation,
    variables: {
      topicId: topicId,
      topicInput
    }
  })
}
