import { FormSchema } from '../../components'

export type FreeAiUsageForm = {
  freeAiUsage: string
}

export const initialValues: FreeAiUsageForm = {
  freeAiUsage: '1'
}

const FreeAiUsageFormSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Free Ai Usage for student',
      fields: [
        {
          label: 'Can use free AI times:',
          accessor: 'freeAiUsage',          
          fieldProps: {
            number: true,            
          }
        }
      ]
    }
  ]
}

export default FreeAiUsageFormSchema