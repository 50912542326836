import gql from 'graphql-tag'

export default gql`
  query TutorUpdateQuery($id: String!) {
    adminGetTutor(id: $id) {
      id
      language
      introduction
      rating
      score
      blockingTime
      user {
        id
        firstName
        lastName
        countryCode
        middleName
        gender
        birthday
        timezone
        image
        credentials {
          identifier
        }
      }
    }
  }
`
