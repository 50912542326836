import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfileQuery from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { getProfile_getProfileFixed, getQuizzes_getQuizzes as Quiz } from '../gql/__generated__'
import getQuizzesQuery from '../gql/queries/getQuizzes'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

type QuizQuestion = {
  question: string
  answer: string
}

interface State {
  profile: getProfile_getProfileFixed
  quiz: QuizQuestion[]
  isLoaded: boolean
}

class QuizzesPage extends React.Component<Props, State> {
  state = {
    quiz: [],
    isLoaded: false,
    profile: {} as getProfile_getProfileFixed
  }

  componentDidMount = async () => {
    const { match } = this.props

    let quizzes
    try {
      const profile = await this.getProfile(match.params.profileId)
      this.setState({ profile })
      quizzes = await this.getQuizzes()
    } catch (err) {
      NotificationService.showError('Cannot get quizzes')
    }

    if (quizzes && quizzes.length) {
      const quizData = this.createQuizData(quizzes)
      this.setState({ quiz: quizData })
    }

    this.setState({ isLoaded: true })
  }

  getProfile = async (id: string): Promise<getProfile_getProfileFixed> => {
    const { client } = this.props
    const result = await client.query({
      query: getProfileQuery,
      variables: {
        id
      }
    })
    return result.data.getProfileFixed
  }

  createQuizData = (quizzes: Quiz[]): QuizQuestion[] => {
    return Object.entries(quizzes[0].quiz).map(([key, value]: any) => {
      return {
        question: key,
        answer: value.toString()
      }
    })
  }

  getQuizzes = async (): Promise<Quiz[]> => {
    const { client } = this.props
    const result = await client.query({
      query: getQuizzesQuery,
      variables: { profileId: this.props.match.params.profileId }
    })
    return result.data.getQuizzes
  }

  render() {
    const columns = [
      {
        Header: 'Question',
        accessor: 'question'
      },
      {
        Header: 'Answer',
        accessor: 'answer'
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>Profile detail - Quizzes</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Quizzes} />
        <div>
          <ProfileDetail profile={this.state.profile} profileId={profileId} />
        </div>
        <div>
          <GenericTable title={`Quiz`} data={this.state.quiz} columns={columns} />
        </div>
      </Layout>
    )
  }
}

export default withApollo(withRouter(QuizzesPage))
