import { Currency } from '../gql/__generated__'

export type Price = {
  amount: number
  currency: Currency
}

const addCushionToPrice = (price: Price): Price => {
  return price.currency === Currency.USD ? price : { amount: price.amount * 1.015, currency: price.currency }
}

const makeRoundedPrice = (price: Price): Price => {
  const { amount, currency } = price
  if (currency === Currency.CZK) {
    return { amount: Math.ceil(amount / 10) * 10 - 1, currency }
  }

  if ([Currency.EUR].includes(currency)) {
    const notFinalPrice = Math.round(amount * 100) / 10
    const finalPrice = Math.ceil(notFinalPrice) / 10 - 0.01
    return { amount: Number(finalPrice.toFixed(2)), currency }
  }

  if (currency === Currency.USD) {
    return price
  }

  return price
}

export const makeNicePrice = (price: Price): Price => {
  const priceWithCushion = addCushionToPrice(price)
  return makeRoundedPrice(priceWithCushion)
}

export const exchangePrice = (price: Price, exchangeRate: number, targetCurrency: Currency): Price => {
  const exchangedPrice = price.amount * exchangeRate
  return {
    amount: exchangedPrice,
    currency: targetCurrency
  }
}

type FormatPriceOptions = {
  // Use international format with currency code, e.g. 123.45 USD
  international?: boolean
}

/**
 * Formats price with currency in either:
 * - local format with currency sign, e.g. $123.45
 * - international format with currency code, e.g. 123.45 USD
 */
export const formatPrice = (price: Price, options?: FormatPriceOptions): string => {
  const { international = false } = options || {}
  let priceFormatted
  const amountFormatted = formatPriceAmount(price)

  if (international) {
    priceFormatted = `${amountFormatted}\u00A0${price.currency}`
  } else {
    switch (price.currency) {
      case 'USD':
        priceFormatted = `$${amountFormatted}`
        break
      case 'CZK':
        priceFormatted = `${amountFormatted}\u00A0Kč`
        break
      case 'PLN':
        priceFormatted = `${amountFormatted}\u00A0zł`
        break
      default:
        priceFormatted = `${amountFormatted}\u00A0${price.currency}`
        break
    }
  }

  return priceFormatted
}

/**
 * Formats price without any currency sign or code
 * e.g. 123.456 -> `123.46` or 123.00 -> `123`
 */
export const formatPriceAmount = (price: Price): string => {
  let amountFormatted

  switch (price.currency) {
    case 'USD':
      amountFormatted = String(price.amount % 1 ? price.amount.toFixed(2) : price.amount)
      break
    case 'CZK':
      amountFormatted = String(price.amount % 1 ? Math.ceil(price.amount) : price.amount)
      break
    case 'PLN':
      amountFormatted = String(price.amount % 1 ? Math.ceil(price.amount) : price.amount)
      break
    default:
      amountFormatted = String(price.amount % 1 ? price.amount.toFixed(2) : price.amount)
      break
  }

  return amountFormatted
}

export const exchangeMakeNice = (price: Price, exchangeRate: number, targetCurrency: Currency): Price => {
  const exchangedPrice = exchangePrice(price, exchangeRate, targetCurrency)
  const priceWithCushion = addCushionToPrice(exchangedPrice)
  return makeRoundedPrice(priceWithCushion)
}

export const exchangeMakeNiceFormat = (price: Price, exchangeRate: number, targetCurrency: Currency): string => {
  const nicePrice = exchangeMakeNice(price, exchangeRate, targetCurrency)
  return formatPrice(nicePrice)
}

export const makeDualPrice = (price: Price, exchangeRate: number, targetCurrency: Currency, isNice = false): string => {
  const originalFormatted = formatPrice(price)

  if (targetCurrency === Currency.USD) {
    return originalFormatted
  }

  let exchangedPrice

  exchangedPrice = isNice
    ? exchangeMakeNice(price, exchangeRate, targetCurrency)
    : exchangePrice(price, exchangeRate, targetCurrency)

  const exchangedFormatted = formatPrice(exchangedPrice)

  return `${originalFormatted}\u00A0(~${exchangedFormatted})`
}

export const makePrice = (amount: number, currency = Currency.USD): Price => {
  return {
    amount,
    currency
  }
}
