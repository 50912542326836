import gql from 'graphql-tag'

export default gql`
  query adminGetPackageThumbStats {
    adminGetPackageThumbStats {
      customPackagesThumbsOnline
      customPackagesThumbsPending
      customPackagesNoImage
    }
  }
`