import gql from 'graphql-tag'

export default gql`
  query TutorInviteQuery($id: String!) {
    adminGetTutor(id: $id) {
      id
      user {
        id
        overrideName
      }
    }
  }
`
