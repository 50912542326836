import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import adminGetPackageQuery from '../gql/queries/adminGetPackage'

import Layout from '../layouts/Layout'
import { GenericForm, RouterLink } from '../components'
import { getPackageFormSchema } from './formSchemas/PackageFormSchema'
import { removeTypenameFromData } from '../api/GraphQLUtils'
import NotificationService, { MessageType } from '../services/NotificationService'
import Button from '@material-ui/core/Button'
import adminUpdatePackageMutation from '../gql/mutations/adminUpdatePackage'
import { adminGetTopics, adminGetTopics_adminGetTopics, adminGetAllCourses, adminGetAllCourses_adminGetAllCourses } from '../gql/__generated__'
import adminGetTopicsQuery from '../gql/queries/adminGetTopics'
import adminGetAllCoursesQuery from '../gql/queries/adminGetAllCourses'

interface Data {
  adminGetPackage: any
}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    packageId: string
  }>

const PackageUpdatePage = ({ client, history, match }: Props) => {
  const [topics, setTopics] = useState<adminGetTopics_adminGetTopics[]>([])
  const [courses, setCourses] = useState<adminGetAllCourses_adminGetAllCourses[]>([])
  const [tutor, setTutor] = useState('')

  useEffect(() => {
    fetchTopics()
    fetchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitialValues = () => {
    return client
      .query<Data>({
        query: adminGetPackageQuery,
        variables: { id: match.params.packageId }
      })
      .then(({ data }) => {
        setTutor(data?.adminGetPackage.tutorId)
        return removeTypenameFromData(data?.adminGetPackage)
      })
  }

  const fetchTopics = async () => {
    try {
      const topics = await client.query<adminGetTopics>({ query: adminGetTopicsQuery })
      setTopics(topics.data.adminGetTopics)
    } catch (err: any) {
      NotificationService.showError('Fetch of topics failed', err)
    }
  }

  const fetchCourses = async () => {
    try {
      const courses = await client.query<adminGetAllCourses>({ query: adminGetAllCoursesQuery })
      setCourses(courses.data.adminGetAllCourses)
    } catch (err: any) {
      NotificationService.showError('Fetch of courses failed', err)
    }
  }

  const handleSubmit = (values: any) => {
    // const image = values.user.image && values.user.image[0] ? values.user.image[0] : undefined
    const tutorId = values.tutorId
    const tutorUserId = values.tutorUserId

    delete values.tutorId
    delete values.tutorUserId

    return client
      .mutate({
        mutation: adminUpdatePackageMutation,
        variables: {
          input: values,
          tutorId: tutorId,
          tutorUserId: tutorUserId
        }
      })
      .then((data) => {
        NotificationService.show(MessageType.success, 'Saved successfully')
        history.push('/packages/' + tutorId)

        return data
      })
  }

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h1'>
        Edit package
        <RouterLink to={'/packages/' + tutor}>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to tutor
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm getInitialValues={getInitialValues} schema={getPackageFormSchema(topics, courses)} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withApollo(PackageUpdatePage)
