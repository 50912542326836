import gql from 'graphql-tag';

export default gql`
  mutation adminCreateTopic($topicInput: TopicWithTranslationsInput!) {
    adminCreateTopicWithTranslations(topicInput: $topicInput) {
      topic {
        id
      }
    }
  }
`
