import { ApolloClient } from 'apollo-boost'
import {
  AdminAiConfigInput,
  adminGetAiConfigs_adminGetAiConfigs as AiConfig,
  adminCreateAiConfig,
  adminCreateAiConfigVariables,
  adminDeleteAiConfig,
  adminDeleteAiConfigVariables,
  adminUpdateAiConfig,
  adminUpdateAiConfigVariables
} from '../../gql/__generated__'
import { FormSubmitValues, Operation } from './AiConfigsPage'
import adminCreateAiConfigMutation from '../../gql/mutations/adminCreateAiConfig'
import adminUpdateAiConfigMutation from '../../gql/mutations/adminUpdateAiConfig'
import adminDeleteAiConfigMutation from '../../gql/mutations/adminDeleteAiConfig'
import { FormSchema } from '../../components'

export function getInitialValues(aiConfigObject: AiConfig) {
  return {
    id: aiConfigObject.id,
    title: aiConfigObject.title,
    firstMessage0: aiConfigObject.config.firstMessages?.[0] || '',
    firstMessage1: aiConfigObject.config.firstMessages?.[1] || '',
    firstMessage2: aiConfigObject.config.firstMessages?.[2] || '',
    systemPrompt: aiConfigObject.config.systemPrompt,
    messagesBack: aiConfigObject.config.messagesBack
  }
}

export function getAiConfigInput(values: FormSubmitValues) {
  return {
    title: values.title,
    config: {
      firstMessages: [values.firstMessage0, values.firstMessage1, values.firstMessage2],
      systemPrompt: values.systemPrompt,
      messagesBack: values.messagesBack
    }
  }
}

export async function createAiConfig(client: ApolloClient<any>, aiConfigInput: AdminAiConfigInput): Promise<void> {
  await client.mutate<adminCreateAiConfig, adminCreateAiConfigVariables>({
    mutation: adminCreateAiConfigMutation,
    variables: {
      input: aiConfigInput
    }
  })
}

export async function updateAiConfig(
  client: ApolloClient<any>,
  aiConfigId: string,
  aiConfigInput: AdminAiConfigInput
): Promise<void> {
  await client.mutate<adminUpdateAiConfig, adminUpdateAiConfigVariables>({
    mutation: adminUpdateAiConfigMutation,
    variables: {
      id: aiConfigId,
      input: aiConfigInput
    }
  })
}

export async function deleteAiConfig(client: ApolloClient<any>, aiConfigId: string): Promise<void> {
  await client.mutate<adminDeleteAiConfig, adminDeleteAiConfigVariables>({
    mutation: adminDeleteAiConfigMutation,
    variables: {
      id: aiConfigId
    }
  })
}

export function getAiConfigFormSchema(operation: Operation): FormSchema {
  const fields = []

  if (operation === 'update') {
    fields.push({
      label: 'AI Config ID',
      accessor: 'id',
      fieldProps: { disabled: true }
    })
  }

  return {
    fieldsets: [
      {
        title: `${operation === 'create' ? 'Create' : 'Update'} AI Config`,
        fields: [
          ...fields,
          {
            label: 'Title (administrative)',
            accessor: 'title',
            fieldProps: { required: true }
          },
          ...[0, 1, 2].map((index) => ({
            label: 'First Message #' + (index + 1),
            accessor: 'firstMessage' + index,
            fieldProps: { required: true, multiline: true, rows: 2 }
          })),
          {
            label: 'System Prompt',
            accessor: 'systemPrompt',
            fieldProps: { required: true, multiline: true, rows: 3 }
          },
          {
            label: 'Messages Back',
            accessor: 'messagesBack',
            fieldProps: { required: true, number: true }
          }
        ]
      }
    ]
  }
}

export const DEFAULT_FIRST_MESSAGES = [
  'Hello! I am Toby your AI English tutor.',
  'I am here to improve your English level. Just type or record me a message below.  I am here to improve your English level.',
  'Start conversation selecting a package and sending me a message :)'
]
export const DEFAULT_SYSTEM_PROMPT =
  'You are an AI English Tutor. Your goal is to converse with the user to practice English and check the grammar of his messages. You can talk about any topic you want. You can also ask questions to the user. Always talk in English. Produce a JSON with: { message: "conversation message", grammar: "grammar evaluation", grammar_correct: boolean, indicate if correct or not } '
