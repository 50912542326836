import React, { ReactNode } from 'react'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { withStyles, WithStyles } from '@material-ui/core/styles'

const styles = (theme: any) => ({
  formControl: {
    minWidth: 400,
    margin: theme.spacing()
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
})

type Props = WithStyles<typeof styles> & {
  value: any
  handleChange: any
  menuItems: ReactNode[]
}

interface State {
  value: number
}

class SimpleSelect extends React.Component<Props, State> {
  render() {
    const { classes } = this.props
    return (
      <form>
        <FormControl variant='outlined' className={classes.formControl}>
          <Select value={this.props.value} onChange={this.props.handleChange}>
            {this.props.menuItems}
          </Select>
        </FormControl>
      </form>
    )
  }
}

export default withStyles(styles)(SimpleSelect)
