import gql from 'graphql-tag'

export default gql`
  query getPackagesByTutorId($tutorId: String!, $pageInfo: PageInfo) {
    getPackagesByTutorId(tutorId: $tutorId, activeOnly: false, pageInfo: $pageInfo) {
      id
      name
      description
      defaultPrice
      defaultCurrency
      globalVisibility
      lessons {
        id
        no
        units
      }
      active
      topicIds
      topics {
        name
      }
      prerequisites
      score
      courseId
      firstLessonFree
    }
  }
`
