import gql from 'graphql-tag'

export default gql`
  query adminFindRooms($profileId: String!, $pageInfo: PageInfo!) {
    adminFindRooms(profileId: $profileId, pageInfo: $pageInfo) {
      id
      started
      ended
      duration
      priceCharged
      unitsCharged
      actualDuration
      state
      meta
      twilioSid
      scheduledCall {
        id
      }
      video {
        id
        status
      }
      lesson {
        id
        package {
          id
          name
        }
      }
      participants {
        id
        profileId
        role
        profile {
          id
          user {
            id
            firstName
            lastName
            image
          }
        }
      }
    }
  }
`
