import React from 'react'
import ListItem from '@material-ui/core/ListItem/index'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { RouterLink } from './index'

const MenuItem = (props: any) => {
  const { route, title, icon } = props

  return (
    <RouterLink to={route}>
      <ListItem button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </RouterLink>
  )
}

export default MenuItem
