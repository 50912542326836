import gql from 'graphql-tag'

export default gql`
  query adminGetAiConfigs {
    adminGetAiConfigs {
      id
      title  
      config {
        firstMessages
        systemPrompt
        messagesBack
      }
      createdAt
      updatedAt
    }
  }
`