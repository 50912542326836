import { FormSchema } from '../../components'
import { adminGetTopics_adminGetTopics as Topic, adminGetAllCourses_adminGetAllCourses as Course } from '../../gql/__generated__'

export function getPackageFormSchema(topics: Topic[], courses: Course[]): FormSchema {
  return {
    fieldsets: [
      {
        title: 'Package',
        fields: [
          {
            label: 'Tutor Id',
            accessor: 'tutorId',
            fieldProps: { disabled: true }
          },
          {
            label: 'Tutor User Id',
            accessor: 'tutorUserId',
            fieldProps: { disabled: true }
          },
          {
            label: 'Active',
            accessor: 'active',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: 'True', value: true },
                { label: 'False', value: false }
              ]
            }
          },
          {
            label: 'Name',
            accessor: 'name'
          },
          {
            label: 'Description',
            accessor: 'description'
          },
          {
            label: 'Prerequisites',
            accessor: 'prerequisites'
          },
          {
            label: 'Topics',
            accessor: 'topicIds',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: '' },
                ...topics.map((topic: Topic) => {
                  return { value: topic.topic.id, label: topic.topic.name }
                })
              ]
            }
          },
          {
            label: 'Course',
            accessor: 'courseId',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: '(choose)', value: null },
                ...courses.map((course: Course) => {
                  return { 
                    value: course.course.id, 
                    label: course.translations.filter((tr) => tr.lang === "cs")[0].name 
                  }
                })
              ]
            }
          },
          {
            label: 'Level',
            accessor: 'level'
          },
          {
            label: 'Duration',
            accessor: 'duration',
            fieldProps: {
              select: true,
              selectOptions: [
                { value: 15, label: '15 min.' },
                { value: 30, label: '30 min.' },
                { value: 45, label: '45 min.' },
                { value: 60, label: '60 min.' },
                { value: 90, label: '90 min.' },
                { value: 180, label: '180 min.' }
              ]
            }
          },
          {
            label: 'Default Price',
            accessor: 'defaultPrice',
            fieldProps: {
              type: 'number'
            }
          },
          {
            label: 'Default Currency',
            accessor: 'defaultCurrency',
            fieldProps: {
              select: true,
              selectOptions: [
                { value: 'CZK', label: 'CZK' },
                { value: 'EUR', label: 'EUR' },
                { value: 'GBP', label: 'GBP' },
                { value: 'USD', label: 'USD' }
              ]
            }
          },
          {
            label: 'Number of Lessons',
            accessor: 'numOfLessons',
            fieldProps: {
              type: 'number',
              validate: (value: number) => {
                if (value % 1 !== 0) {
                  return 'Must be whole number.'
                }
              }
            }
          },
          {
            label: 'Score',
            accessor: 'score',
            fieldProps: {
              type: 'number'
            }
          },
          {
            label: 'Free lesson',
            accessor: 'firstLessonFree',
            fieldProps: {
              select: true,
              selectOptions: [
                { label: 'True', value: true },
                { label: 'False', value: false }
              ]
            }
          }
          // {
          //   label: 'Image',
          //   accessor: 'user.image',
          //   component: FormikUploadField,
          //   fieldProps: {}
          // }
        ]
      }
    ]
  }
}
