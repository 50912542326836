import gql from 'graphql-tag'

export default gql`
  query adminGetFutureScheduledCalls($pageInfo: PageInfo!) {
    adminGetFutureScheduledCalls(pageInfo: $pageInfo) {
      id    
      createdAt
      start
      end
      cancelledAt   
      confirmedAt
      packageId
      inviteMessage        
      participants {
        userId
        role
        profileId
        cancellationReason
        rejectMessage
        purchaseId
      }
    }
  }
`