import { FormSchema } from '../../components'

const initialValues = {
  fileName: ''
}

const VideoPreviewSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Add video preview',
      fields: [
        {
          label: 'Enter url',
          accessor: 'fileName'
        }
      ]
    }
  ]
}

export default VideoPreviewSchema

export { initialValues }
