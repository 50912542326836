import { ApolloClient } from 'apollo-boost'
import { FormikHelpers } from 'formik'
import { useEffect, useState } from 'react'
import adminGetTopicsQuery from '../../gql/queries/adminGetTopics'
import { adminGetTopics, adminGetTopics_adminGetTopics } from '../../gql/__generated__'
import NotificationService, { MessageType } from '../../services/NotificationService'
import { Values } from '../formSchemas/GroupMemberSchema'
import { FormSubmitValues, Operation } from './TopicsPage'
import { createTopic, getInitialValues, getTopicInput, updateTopic } from './util'

type FormInitialValues = {
  id: string
  name: string
  parentId: string | null,
  slug: string
  enName: string
  enHeadline: string
  enSubheadline: string
  csName: string
  csHeadline: string
  csSubheadline: string
}

const formInitialValues:FormInitialValues = {
  id: '',
  name: '',
  parentId: null,
  slug: '',
  enName: '',
  enHeadline: '',
  enSubheadline: '',
  csName: '',
  csHeadline: '',
  csSubheadline: '',
}

export function useTopicsPage(client: ApolloClient<any>) {
  const [topics, setTopics] = useState<adminGetTopics_adminGetTopics[]>([])
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [operation, setOperation] = useState<Operation>('create')

  useEffect(() => {
    fetchTopics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEdit = (topicId: string) => {
    const topicObject = topics.find((item) => item.topic.id === topicId)
    if (topicObject) {
      setOperation('update')
      setInitialValues(getInitialValues(topicObject))
    }
  }

  const fetchTopics = async () => {
    try {
      const topics = await client.query<adminGetTopics>({ query: adminGetTopicsQuery })
      setTopics(topics.data.adminGetTopics)
    } catch (err: any) {
      NotificationService.showError('Fetch of topics failed', err)
    }
  }

  const handleSubmit = async (values: FormSubmitValues, actions: FormikHelpers<Values>) => {
    const topicInput = getTopicInput(values)

    try {
      if (operation === 'create') {
        await createTopic(client, topicInput)
      }

      if (operation === 'update' && values.id) {
        await updateTopic(client, values.id, topicInput)
      }
    } catch (err: any) {
      NotificationService.showError('Create or update of topic failed', err)
    }

    setOperation('create')
    setInitialValues(formInitialValues)
    actions.resetForm()
    NotificationService.show(MessageType.success, 'Topic added successfully')
    fetchTopics()
  }

  return {
    topics,
    initialValues,
    operation,
    onEdit,
    handleSubmit
  }
}
