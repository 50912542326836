import gql from 'graphql-tag'

export default gql`
  query adminGetUserStatusHistory($userId: String!, $pageInfo: PageInfo!) {
    adminGetUserStatusHistory(userId: $userId, pageInfo: $pageInfo) {
      status
      createdAt
    }
  }
`
