import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  getProfile_getProfileFixed_Student,
  getProfile_getProfileFixed_Student_user_contacts_PaymentOptions,
  getProfile_getProfileFixed_Tutor,
  getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions,
  getProfile_getProfileFixed_Tutor_user_contacts_Billing
} from '../gql/__generated__'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { getUserImageUrl } from '../Utils'
import PublicIcon from '@material-ui/icons/Public'

type Props = WithStyles<typeof styles> & {
  wallets?: any
  profileId?: string
  profile: getProfile_getProfileFixed_Student | getProfile_getProfileFixed_Tutor
  onCreateStripeBusiness?: () => void,
  stripeBusinessPending?: boolean
}

const styles = {
  card: {
    marginBottom: 20
  }
}

const ProfileDetail = (props: Props) => {
  const { classes, profile, wallets } = props
  const user = profile.user
  const tutor = profile.id.startsWith('TU') ? (profile as getProfile_getProfileFixed_Tutor) : undefined
  // const student = profile.id.startsWith('ST') ? (profile as getProfile_getProfileFixed_Student) : undefined
  const paymentOptions =
    user &&
    user.contacts &&
    (user.contacts.filter((c) => c.__typename === 'PaymentOptions')[0] as
      | getProfile_getProfileFixed_Tutor_user_contacts_PaymentOptions
      | getProfile_getProfileFixed_Student_user_contacts_PaymentOptions)
  
  const billingDetails = 
    tutor &&
    user &&
    user.contacts &&
    (user.contacts.filter((c) => c.__typename === 'Billing')[0] as getProfile_getProfileFixed_Tutor_user_contacts_Billing)



  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
          {user.image && (
            <Grid item xs={4}>
              <img src={getUserImageUrl(user.image)} alt='user' style={{ maxWidth: '50px', maxHeight: '50px' }} />
            </Grid>
          )}
          <Grid item xs={4}>
            Identifier: <b>{user.credentials && user.credentials.length && user.credentials[0].identifier}</b>
          </Grid>
          <Grid item xs={4}>
            Name:{' '}
            <b>
              {user.firstName} {user.lastName}
            </b>
          </Grid>
          <Grid item xs={4}>
            UserId: <b>{user.id}</b>
          </Grid>
          <Grid item xs={4}>
            ProfileId: <b>{props.profileId}</b>{' '}
            {tutor && (
              <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}u/${tutor.slug}`} rel='noreferrer'>
                <PublicIcon />
              </a>
            )}
          </Grid>
          <Grid item xs={4}>
            Signup at: <b>{user.createdAt}</b>
          </Grid>
          {wallets && (
            <Grid item xs={4}>
              {wallets &&
                wallets.map((wallet: any) => (
                  <>
                    {wallet.type}: <b>{wallet.balance} $</b> &nbsp;
                  </>
                ))}
            </Grid>
          )}
          {tutor && (
            <>
              <Grid item xs={4}>
                stripe: <b>{paymentOptions && paymentOptions.stripeEnabled ? 'true' : 'false'}</b>
                &nbsp; legacy payments: <b>{paymentOptions && paymentOptions.otherMethodsEnabled ? 'true' : 'false'}</b>
              </Grid>
              {tutor.stripeAccountId ? (
                <Grid item xs={4}>
                  Stripe Account ID:{' '}
                  <b>
                    <a href={`https://dashboard.stripe.com/${tutor.stripeAccountId}`}>{tutor.stripeAccountId}</a>
                  </b>
                </Grid>
              )  : 
                wallets && props.onCreateStripeBusiness && (                  
                  props.stripeBusinessPending ? (
                  <Grid item xs={4}>
                    Creating stripe business account... Please wait...
                  </Grid>
                  )
                 : 
                 (
                  <Grid item xs={4}>                  
                    No stripe account:{' '}
                    <b>
                      <a href='/' onClick={(e) => { e.preventDefault(); props.onCreateStripeBusiness && props.onCreateStripeBusiness()}}>Create Business</a>
                    </b>                  
                  </Grid>
                  )              
                )
              }
              {billingDetails && billingDetails.taxId && (  
                <Grid item xs={4}>
                  IČO: <b>{billingDetails.taxId}</b>
                  {billingDetails.companyId && (
                    <>
                      <br />
                      DIČ: <b>{billingDetails.companyId}</b>
                    </>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(ProfileDetail)
