import FormHelperText from '@material-ui/core/FormHelperText'
import TextField, { TextFieldProps as TextFieldPropsType } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { convertToRaw, convertFromHTML, ContentState, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { FieldProps, getIn } from 'formik'
import MUIRichTextEditor from 'mui-rte'
import { TMUIRichTextEditorRef, TMUIRichTextEditorProps } from 'mui-rte/src/MUIRichTextEditor'
import { v4 as uuid } from 'uuid'
import React, { Ref, useRef, useImperativeHandle, useMemo, useEffect, useState } from 'react'

type RichTextInputProps = TMUIRichTextEditorProps & {
  focused?: boolean
  inputRef?: Ref<unknown>
}

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    marginTop: spacing(1),
    marginBottom: spacing(0.5),
    width: '100%'
  }
}))

export const RichTextInput = ({ inputRef, focused, onBlur, ...RichTextEditorProps }: RichTextInputProps) => {
  const editor = useRef<TMUIRichTextEditorRef>(null)

  // Attempts to focus the rich text editor reference
  // eslint-disable-next-line
  const focusEditor = () => {
    editor.current && editor.current.focus()
  }

  // Imperatively trigger save event on every blur
  const handleBlur = () => {
    editor.current && editor.current.save()
    onBlur && onBlur()
  }

  // Pass on the focus method of the input ref to the rich text ref
  // See https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
  useImperativeHandle(inputRef, () => ({ focus: () => focusEditor }))

  useEffect(() => {
    focused && focusEditor()
  }, [focused, focusEditor])

  return (
    <MUIRichTextEditor
      {...RichTextEditorProps}
      controls={['title', 'bold', 'italic', 'underline', 'link', 'numberList', 'bulletList']}
      onBlur={handleBlur}
      ref={editor}
    />
  )
}

const FormikRichText = ({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  ...TextFieldProps
}: FieldProps & TextFieldPropsType) => {
  const classes = useStyles()
  const id = useMemo(() => uuid(), [])
  const fieldTouched = getIn(touched, name)
  const error = getIn(errors, name)
  // RTE is not controlled, so compute value only once
  const defaultValue = useMemo(
    () => {
      const contentState = convertFromHTML(value)
      const state = ContentState.createFromBlockArray(contentState.contentBlocks, contentState.entityMap)
      return JSON.stringify(convertToRaw(state))
    },
    // eslint-disable-next-line
    [] /* Intentionally empty dependencies */
  )
  const changedState = useRef<EditorState>(null)

  // Manually handle the TextField's focused state based on the editor's focused state
  const [focused, setFocused] = useState(false)

  const inputProps: RichTextInputProps = {
    id: id,
    defaultValue,
    label: TextFieldProps.placeholder,
    inlineToolbar: true,
    onChange: (state) => {
      // @ts-ignore
      changedState.current = state
    },
    onSave: () => {
      // State-to-HTML conversion is expensive, so compute it only when `onSave` is triggered
      // (which happens onBlur only)
      if (changedState.current) {
        const changedValue = stateToHTML(changedState.current.getCurrentContent())
        setFieldValue(name, changedValue)
      }
    },
    focused,
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false)
  }

  return (
    <>
      <TextField
        variant='outlined'
        {...TextFieldProps}
        className={cx(classes.textField, TextFieldProps.className)}
        id={id}
        focused={focused}
        onClick={() => setFocused(true)}
        InputLabelProps={{ ...TextFieldProps.InputLabelProps, shrink: true }}
        InputProps={{ inputComponent: RichTextInput as any, inputProps: inputProps as any }}
        multiline={true}
      />
      {fieldTouched && error && (
        <FormHelperText error={true} style={{ marginLeft: '15px' }}>
          {error}
        </FormHelperText>
      )}
    </>
  )
}

export default FormikRichText
