import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import React from 'react'
import Layout from '../../layouts/Layout'
import { Button, Typography } from '@material-ui/core'
import { GenericForm, GenericTable } from '../../components'
import { useAiPricelistsPage } from './useAiPricelistsPageHook'
import { getAiPricelists_getAiPricelists } from '../../gql/__generated__'
import { getaiPricelistFormSchema } from './util'

export interface FormSubmitValues {
  id?: string
  minutes: string
  price: string
  currency: string
}

const WARNING_MESSAGE = 'Are you sure you want to delete this AI Pricelist?'

export type Operation = 'create' | 'update'

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const AiPricelistsPage = ({ client }: Props) => {
  const { AiPricelists, handleSubmit, handleDelete, initialValues, onEdit, operation } = useAiPricelistsPage(client)
  
  const EditBtn = ({ aiConfigId }: { aiConfigId: string }) => {
    return <Button onClick={() => onEdit(aiConfigId)}>Edit</Button>
  }

  const DeleteBtn = ({ aiConfigId }: { aiConfigId: string }) => {
    return <Button onClick={() => window.confirm(WARNING_MESSAGE) && handleDelete(aiConfigId)}>Delete</Button>
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Minutes',
      accessor: 'minutes'
    },
    {
      Header: 'Price',
      accessor: (item: getAiPricelists_getAiPricelists) => item.price === 0 ? 'Free' : item.price
    },
    {
      Header: 'Currency',
      accessor: 'currency'
    },
    {
      Header: 'Edit',      
      accessor: (item: getAiPricelists_getAiPricelists) => <EditBtn aiConfigId={item.id} />
    },
    {
      Header: 'Delete',      
      accessor: (item: getAiPricelists_getAiPricelists) => <DeleteBtn aiConfigId={item.id} />
    }
  ]

  return (
    <Layout>      
      <Typography gutterBottom={true} variant='h4'>
        AI Pricelists
      </Typography>
      <GenericForm 
        getInitialValues={initialValues}
        schema={
          getaiPricelistFormSchema(operation)
        }
        onSubmit={handleSubmit}
        submitBtnTitle={operation === 'create' ? 'Create' : 'Update'}
      />
      <GenericTable data={AiPricelists} columns={columns} />            
    </Layout>
  )
}

export default withApollo(AiPricelistsPage)
