import { countryCodesISO2, countryNames } from '../../utils/countries'

const generateSchema = (langs: string[]) => {
  const schema = {
    type: "object",
  } as any
  
  schema.required = ["slug", ...langs.map((lang) => `translations_${lang}_name`)]
  
  schema.properties = {
    slug: {
      type: "string"
    },
    flagCode: {
      type: "string",
      title: "Flag code (shows a flag in matrix)",
      enum: ["", ...countryCodesISO2],
      enumNames: [" - None - ", ...countryNames]
    },
    showAsMain: {
      type: "boolean",
      title: "Show as main course"
    },   
    priority: {
      type: "number",
      title: "Priority (higher number means higher priority)",      
    } 
  }
  
  langs.forEach((lang) => {
    schema.properties[`translations_${lang}_name`] = {
      title: `name (${lang})`,
      type: "string"
    }

    schema.properties[`translations_${lang}_seoTitle`] = {
      title: `SEO title (${lang})`,
      type: ["string", "null"]
    }

    schema.properties[`translations_${lang}_seoDescription`] = {
      title: `SEO description (${lang})`,
      type: ["string", "null"]
    }
  })

  schema.properties.tags = {
    type: "array",
    items: {
      type: "object",
      required: ["slug", "type", ...langs.map((lang) => `translations_${lang}_name`)],
      properties: {
        slug: {
          title: "Tag slug",
          type: "string"
        },
        type: {
          title: "Tag type",
          description: "Level is for complexity, Flag is general tag.",
          type: "string",
          enum: ["Level", "Flag"]
        },        
      }  
    }
  }

  langs.forEach((lang) => {
    schema.properties.tags.items.properties[`translations_${lang}_name`] = {
      title: `Tag name (${lang})`,
      type: "string"
    }
  })

  return schema  
}

export default generateSchema