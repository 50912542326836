import React from 'react'
import SchoolIcon from '@material-ui/icons/School'
import CalendarIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import MusicIcon from '@material-ui/icons/MusicNote'
import OfferIcon from '@material-ui/icons/LocalOffer'
import MenuItem from '../../components/MenuItem'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import PackageIcon from '@material-ui/icons/ViewList'
import GroupIcon from '@material-ui/icons/Group'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import UpdateIcon from '@material-ui/icons/Update';
import AlarmIcon from '@material-ui/icons/Alarm';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import MoneyIcon from '@material-ui/icons/Money'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

const menuItems = (
  <div>
    <MenuItem route='/' title='Dashboard' icon={<HomeIcon />} />
    <MenuItem route='/tutors' title='Tutors' icon={<SchoolIcon />} />
    <MenuItem route='/tutor/create' title='Tutors' icon={<PlaylistAddIcon />} />
    <MenuItem route='/students' title='Students' icon={<PersonIcon />} />
    <MenuItem route='/topics' title='Topics' icon={<MusicIcon />} />
    <MenuItem route='/courses' title='Courses Catalog' icon={<LibraryBooksIcon />} />
    <MenuItem route='/voucher' title='Vouchers' icon={<OfferIcon />} />
    <MenuItem route='/reservations' title='Reservations' icon={<AlarmIcon />} /> 
    <MenuItem route='/packages/create' title='Packages' icon={<PackageIcon />} />
    <MenuItem route='/shifts' title='Shifts' icon={<CalendarIcon />} />
    <MenuItem route='/groups' title='Groups' icon={<GroupIcon />} />    
    <MenuItem route='/actions' title='Actions' icon={<UpdateIcon />} />    
    <MenuItem route='/ai-configs' title='AI Configs' icon={<PhonelinkSetupIcon />} />    
    <MenuItem route='/ai-pricelists' title='AI Pricelists' icon={<MoneyIcon />} />    
    <MenuItem route='/demands' title='Demands' icon={<AccessibilityNewIcon />} />
  </div>
)

export default menuItems
