import React, { useState } from 'react'
import Form from 'rjsf-material-ui'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import Layout from '../layouts/Layout'
import schema from './formSchemas/packageSchema.json'
import packages from '../locales/en/packages.json'

const removeEmpty = (o: any) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue // If null or not an object, skip to the next iteration
    }

    // The property is an object
    removeEmpty(o[k]) // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k] // The object had no properties, so delete that property
    }
  }
  return o
}

const PackageCreatePage = () => {
  const [formData, setFormData] = useState({})
  const courses: any = packages.list

  const onSubmit = ({ formData }: any) => {
    setFormData(formData)
    const cleanedData = JSON.stringify(removeEmpty({ ...formData }), undefined, 2)
    navigator.clipboard.writeText(cleanedData).then(
      () => {
        alert('Coped to clipboard!')
      },
      () => {
        alert('Copying to clipboard failed! Pleas copy it manually')
      }
    )
  }

  const handleError = () => {
    const cont = document.querySelector('main')
    cont && cont.scrollTo(0, 0)
  }

  const loadCourse = (name: any) => {
    setFormData(courses[name])
  }

  return (
    <Layout>
      <Box display='flex' flexWrap='wrap'>
        <Box width={{ xs: '100%', md: '60%' }}>
          <Typography gutterBottom={true} variant='h4'>
            Create Mini course
          </Typography>
          <Form liveValidate schema={schema as any} onError={handleError} formData={formData} onSubmit={onSubmit}>
            <Box my={5}>
              <Button variant='contained' color='primary' type='submit'>
                Submit
              </Button>
            </Box>
            <code style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}>{JSON.stringify(formData, undefined, 2)}</code>
          </Form>
        </Box>
        <Box width={{ xs: '100%', md: '40%' }} pl={{ md: 10 }}>
          <Typography gutterBottom={true} variant='h4'>
            Saved courses
          </Typography>
          {Object.keys(courses).map((name) => (
            <Box key={name}>
              <Button onClick={() => loadCourse(name)}>{name}</Button>
            </Box>
          ))}
          <Box mt={5} mb={10}>
            <Button variant='contained' onClick={() => setFormData({})}>
              Clear form
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default PackageCreatePage
