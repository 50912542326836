import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Layout from '../layouts/Layout'
import { GenericForm, RouterLink } from '../components'
import TutorFormSchema, { initialValues } from './formSchemas/TutorFormSchema'
import NotificationService, { MessageType } from '../services/NotificationService'
import adminCreateTutorMutation from '../gql/mutations/adminCreateTutor'

type Props = WithApolloClient<{}> & RouteComponentProps<{}>

const TutorUpdatePage = ({ client, history }: Props) => {
  const getInitialValues = () => initialValues

  const handleSubmit = (values: any) => {
    const image = values.user.image && values.user.image[0] ? values.user.image[0] : undefined
    delete values.user.image

    values.user.credential = values.user.credentials && values.user.credentials[0]
    delete values.user.credentials

    return client
      .mutate({
        mutation: adminCreateTutorMutation,
        variables: {
          tutor: values,
          image
        },
        errorPolicy: 'none'
      })
      .then((data) => {
        console.log('data', data)
        NotificationService.show(MessageType.success, 'Saved successfully')
        history.push('/tutors')

        return data
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Create tutor
        <RouterLink to='/tutors'>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to tutors
          </Button>
        </RouterLink>
      </Typography>
      <GenericForm getInitialValues={getInitialValues} schema={TutorFormSchema} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withApollo(TutorUpdatePage)
