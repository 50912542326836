import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Layout from '../../layouts/Layout'
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import RjsfForm from 'rjsf-material-ui'
import generateCourseSchema from './courseSchema'
import { convertFormDataToQueryObject, convertQueryObjectToFormData, getImageData, FormData, emptyFormData, emptyImagesData, ImagesData } from '../../utils/courseHelpers'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useCourseForm } from './useCourseForm'
import { FormikUploadField } from '../../components'
import { Form, Formik, Field, FormikProps } from 'formik'
import { LANGUAGE_MAP } from './courseConfig'

type Params = {  
  courseId: string  
}

const schema = generateCourseSchema(LANGUAGE_MAP)

type Props = WithApolloClient<{}> & RouteComponentProps<Params>

const CourseEditPage = ({ client, match,  }: Props) => {   
  const { courseId } = match.params
  const [formData, setFormData] = useState<FormData>(emptyFormData)
  const [imgData, setImgData] = useState<ImagesData>(emptyImagesData)
  const { handleSubmit, progress, courseData, shouldRedirect } = useCourseForm(client, courseId)
  const history = useHistory();
  

  const onSubmit = ({ formData }:any) => {
    const { topicL1, topicL2 } = courseData!.course
    const result = convertFormDataToQueryObject(formData as FormData, topicL1, topicL2)    
    handleSubmit(result, getImageData(formikRef.current?.values))
  }

  const formikRef = useRef<FormikProps<ImagesData>>(null)

  useEffect(() => {
    if (shouldRedirect) {
      history.push(`/courses`)
    }
  }, [shouldRedirect, history])

  useEffect(() => {
    if(courseData) {
      setFormData(convertQueryObjectToFormData(courseData))
      const imgData = {} as ImagesData
      LANGUAGE_MAP.forEach((lang) => {
        const seoImage = courseData.translations.find((t) => t.lang === lang)?.seoImage
        const imgKey = `image_${lang}` as keyof ImagesData
        imgData[imgKey] = seoImage        
      })
      setImgData(imgData)
    }
  }, [courseData])

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Edit Course
      </Typography>
      { progress === 'loading' 
        ? <Typography gutterBottom={true} variant='body1'>Loading Course data...</Typography>
        : (<>
            <Formik key={courseId} innerRef={formikRef} initialValues={imgData} onSubmit={() => {}}>
              <Form>
                { LANGUAGE_MAP.map((lang) => 
                  <Field component={FormikUploadField} key={lang} name={`image_${lang}`} label={`SEO Image ${lang}`} />
                )}
              </Form>
            </Formik>
            <RjsfForm schema={schema as any} disabled={progress !== 'idle'} formData={formData} onSubmit={onSubmit} > 
              <Box mt={5} mb={10}>
                <Button variant='contained' color='primary' type='submit'>
                  Submit
                </Button>          
              </Box>
            </RjsfForm>
          </>)
      }      
    </Layout>)
}

export default withApollo(CourseEditPage)