import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts/Layout'
import { withRouter } from 'react-router-dom'
import { WithApolloClient, withApollo } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import Button from '@material-ui/core/Button'
import getNewHashMutation from '../gql/mutations/getNewHash'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    identifier: string
  }>

interface State {
  newToken: string
}

class GenerateTokenPage extends React.Component<Props, State> {
  state = {
    newToken: ''
  }
  handleClick = async () => {
    const { client } = this.props
    const result = await client.mutate({
      mutation: getNewHashMutation,
      variables: { identifier: this.props.match.params.identifier }
    })
    this.setState({ newToken: result.data.getNewHash })
  }
  render() {
    return (
      <Layout>
        <Card>
          <CardContent>
            <Typography variant='h3' component='h3'>
              Generate new token
            </Typography>
            <Typography variant='h5' component='h5'>
              Identifier: {this.props.match.params.identifier}
            </Typography>
          </CardContent>
          <CardActions>
            <Button color='secondary' variant='contained' size='large' onClick={this.handleClick}>
              Generate
            </Button>
            {this.state.newToken && (
              <Typography
                variant='h5'
                component='h5'
              >{`${process.env.REACT_APP_BASE_URL}confirm-email?token=${this.state.newToken}`}</Typography>
            )}
          </CardActions>
        </Card>
      </Layout>
    )
  }
}

export default withApollo(withRouter(GenerateTokenPage))
