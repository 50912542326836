import { ApolloClient } from 'apollo-boost'
import { useEffect, useState } from 'react'
import adminGetTopicsQuery from '../../gql/queries/adminGetTopics'
import adminGetAllCoursesQuery from '../../gql/queries/adminGetAllCourses'
import { adminGetTopics, adminGetAllCourses  } from '../../gql/__generated__'
import NotificationService from '../../services/NotificationService'
import { organizeTopics, enrichTopicsByCourses } from '../../utils/courseHelpers'

export type OrganizedTopic = {
  topicL1: string
  topicL2: string
  topicL1Name: string
  topicL2Name: string
  hasCourse: boolean
  courseName?: string
  courseSlug?: string
  courseLevels?: string[]
  courseFlags?: string[]  
  courseId?: string
  courseShowAsMain?: boolean
  courseFlagCode?: string | null
  coursePriority?: number
}

export function useCoursesPage(client: ApolloClient<any>) { 
  const [topics, setTopics] = useState<OrganizedTopic[]>([]) 

  useEffect(() => {
    fetchTopics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTopics = async () => {
    try {
      const topics = await client.query<adminGetTopics>({ query: adminGetTopicsQuery })      
      const organized = organizeTopics(topics.data.adminGetTopics)
      const courses = await client.query<adminGetAllCourses>({ query: adminGetAllCoursesQuery })
      const result = enrichTopicsByCourses(organized, courses.data.adminGetAllCourses)      
      setTopics(result)

    } catch (err: any) {
      NotificationService.showError('Fetch of topics failed', err)
    }
  }

  return {
    topics    
  }

}