import React from 'react'
import { withRouter } from 'react-router-dom'
import JSONPretty from 'react-json-pretty'
import Layout from '../layouts/Layout'
import adminGetTransactions from '../gql/queries/adminGetTransactions'
import addPromoCreditMutation from '../gql/mutations/addPromoCredit'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import getBalance from '../gql/queries/getWallets'
import ProfileDetail from '../components/ProfileDetail'
import { GenericForm } from '../components/GenericForm'
import CreditFormSchema, { initialValues } from './formSchemas/CreditFormSchema'
import TutorPayoutSchema, { initialValues as tutorPayoutValues } from './formSchemas/TutorPayoutSchema'
import FeeFormSchema, { initialValues as feeInitialValues, validationSchema as feeFormValidationSchema } from './formSchemas/FeeFormSchema'
import { formatDate, isStudent } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import NotificationService, { MessageType } from '../services/NotificationService'
import { UserPagesTitles } from '../enums'
import UserPagesNavigation from '../components/UserPagesNavigation'
import CancelCreditSchema from './formSchemas/CancelCreditSchema'
import cancelGiftUserMutation from '../gql/mutations/cancelGiftUser'
import adminTutorPayoutMutation from '../gql/mutations/adminTutorPayout'
import adminFindAllPaymentsByProfileId from '../gql/queries/adminFindAllPaymentsByProfileId'
import adminCreateStripeBusinessAccountMutation from '../gql/mutations/adminCreateStripeBusinessAccount'
import adminCreateContact from '../gql/mutations/adminCreateContact'
import { Typography } from '@material-ui/core'
import { TablePaginationProps } from '@material-ui/core/TablePagination'
import { adminCreateStripeBusinessAccount, adminCreateStripeBusinessAccountVariables, getProfile_getProfileFixed } from '../gql/__generated__'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profileId: string
  profile: getProfile_getProfileFixed
  feeContactId: string | null
  customFee: number | null
  isLoaded: boolean
  wallets: any[]
  payments: any[]
  transactions: any[]
  pagination: TablePaginationProps
  stripeBusinessPending: boolean
}

class WalletDetailPage extends React.Component<Props, State> {
  handleChangeRowsPerPage = async (event: any) => {
    const rowsPerPage = +event.target.value
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
    await this.onUpdate(rowsPerPage, this.state.pagination.page)
  }

  handleChangePage = async (events: any, page: number) => {
    this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
    await this.onUpdate(this.state.pagination.rowsPerPage, page)
  }

  onUpdate = async (rowsPerPage: number, page = 0) => {
    const transactions = await this.getTransactions(rowsPerPage, rowsPerPage * page)
    this.setState({ isLoaded: true, transactions })
    if (transactions.length < rowsPerPage) {
      this.setState({ pagination: { ...this.state.pagination, count: rowsPerPage * page + transactions.length } })
    }
  }

  state = {
    profileId: '',
    profile: {} as getProfile_getProfileFixed,
    payments: [],
    wallets: [],
    transactions: [],
    isLoaded: false,
    feeContactId: null,
    customFee: null,
    stripeBusinessPending: false,
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 15,
      rowsPerPageOptions: [15, 30, 50],
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangePage: this.handleChangePage
    }
  }

  componentDidMount = async () => {
    await this.setState({ profileId: this.props.match.params.profileId })
    const { client } = this.props
    const result = await client.query({
      query: getProfile,
      variables: {
        id: this.state.profileId
      }
    })
    this.setState({ profile: result.data.getProfileFixed })
    
    const contactFee = result.data.getProfileFixed.user.contacts?.find((contact: any) => contact.__typename === 'Fee')
    if(contactFee) {
      this.setState({
        feeContactId: contactFee.id,
        customFee: contactFee.fee
      })      
    }

    try {
      const wallets = await this.getWallets()
      this.setState({ wallets })
    } catch (err: any) {
      NotificationService.showError('Error getting wallets', err)
    }
    try {
      const payments = await this.getPayments()
      this.setState({ payments })
    } catch (err: any) {
      NotificationService.showError('Error while getting payments', err)
    }
    try {
      await this.onUpdate(this.state.pagination.rowsPerPage)
    } catch (err: any) {
      NotificationService.showError('Error while getting transactions', err)
    }
  }

  getPayments = async () => {
    const { client } = this.props
    const profileId = this.props.match.params.profileId
    const result = await client.query({
      query: adminFindAllPaymentsByProfileId,
      variables: {
        profileId
      }
    })
    return result.data.adminFindAllPaymentsByProfileId
  }

  getWallets = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getBalance,
      variables: {
        userId: this.state.profile.user.id
      }
    })
    return result.data.getWallets
  }

  handleSubmitGift = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props
    await client.mutate({
      mutation: addPromoCreditMutation,
      variables: {
        data: {
          userId: this.state.profile.user.id,
          profileId: profileId,
          amount: Number(values.amount),
          expiresAt: values.expiresAt,
          transactionType: isStudent(profileId) ? 'StudentGift' : 'TutorGift',
          comment: values.comment ? values.comment : null
        }
      }
    })
    NotificationService.show(MessageType.success, 'User gifted successfully')
    window.location.reload()
  }

  handleSubmitCancel = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props
    try {
      await client.mutate({
        mutation: cancelGiftUserMutation,
        variables: {
          data: {
            userId: this.state.profile.user.id,
            profileId: profileId,
            amount: Number(values.amount),
            comment: values.comment ? values.comment : null
          }
        }
      })
      NotificationService.show(MessageType.success, 'User credit canceled successfully')
      window.location.reload()
    } catch (err: any) {
      NotificationService.showError('User credit canceled failed', err)
    }
  }

  handleTutorPayout = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props
    try {
      await client.mutate({
        mutation: adminTutorPayoutMutation,
        variables: {
          data: {
            userId: this.state.profile.user.id,
            tutorId: profileId,
            amount: Number(values.amount),
            createdAt: values.createdAt
          }
        }
      })
      NotificationService.show(MessageType.success, 'Tutor payout successful')
      window.location.reload()
    } catch (err: any) {
      NotificationService.showError('Tutor payout failed', err)
    }
  }

  handleSubmitFee = async (values: any) => { 
    console.log(values)
    const { client } = this.props
    const contactInput = {
      contact: String(values.fee), 
      userId: this.state.profile.user.id,
      type: 'Fee',
    } as any
    if(this.state.feeContactId) {
      contactInput['contactId'] = this.state.feeContactId
    }
    const result = await client.mutate({
      mutation: adminCreateContact,
      variables: { 
        contactInput
      }
    })
    console.log(result)
    if(result.data.adminCreateContact) {
      NotificationService.show(MessageType.success, 'Tutor custom fee set')    
      window.location.reload()
    } else {
      NotificationService.show(MessageType.error, 'Tutor custom fee setting failed')
    }
  }

  getTransactions = async (first: number, offset: number) => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetTransactions,
      variables: {
        profileId: this.state.profileId,
        pageInfo: {
          first,
          offset
        }
      }
    })
    this.setState({ transactions: result.data.adminGetTransactions })
    return result.data.adminGetTransactions
  }

  getInitialValues = () => initialValues

  getPayoutInitialValues = () => tutorPayoutValues

  getFeeInitialValues = () => {
    if(this.state.customFee !== null && !isNaN(this.state.customFee as unknown as number)) { 
      return {
        fee: this.state.customFee
      }
    }
    return feeInitialValues  
  }

  handleCreateBusiness = async () => {
    this.setState({ stripeBusinessPending: true })
    console.log(this.state.profileId, this.state.profile.user.id)
    const { client } = this.props
    try {
      await client.mutate<adminCreateStripeBusinessAccount, adminCreateStripeBusinessAccountVariables>({
        mutation: adminCreateStripeBusinessAccountMutation,
        variables: {
          userId: this.state.profile.user.id,
          tutorId: this.state.profileId
        }
      })
      NotificationService.show(MessageType.success, 'Stripe business account created')
      window.location.reload()      
    } catch(err) {
      console.error(err)
      NotificationService.show(MessageType.error, 'Stripe business account creation error')
    }
  }

  render() {
    const transactionsColumns = [
      {
        Header: 'amount',
        accessor: (item: any) => `${item.amount} $`
      },
      {
        Header: 'type',
        accessor: 'type'
      },
      {
        Header: 'createdAt',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        }
      },
      {
        Header: 'expiresAt',
        accessor: (item: any) => {
          return formatDate(item.expiresAt)
        }
      },
      {
        Header: 'sourceId',
        accessor: 'sourceId'
      },
      {
        Header: 'comment',
        accessor: 'comment'
      }
    ]

    const paymentsColumns = [
      {
        Header: 'amount',
        accessor: (item: any) => `${item.amount} $`
      },
      {
        Header: 'type',
        accessor: (item: any) => {
          return item.type.replace('Payment', '')
        }
      },
      {
        Header: 'state',
        accessor: (item: any) => {
          return item.blabuState.toLowerCase()
        }
      },
      {
        Header: 'createdAt',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        }
      },
      {
        Header: 'data',
        accessor: (item: any) => {
          let data
          if (item.type === 'CsobPayment') {
            data = item.csobGatewayData
          } else if (item.type === 'PaypalPayment') {
            data = item.paypalGatewayData
          } else if (item.type === 'SodexoPayment') {
            data = item.sodexoGatewayData
          } else if (item.type === 'PayUPayment') {
            data = item.payuGatewayData
          } else if (item.type === 'GalleryBetaPayment') {
            data = item.dbGatewayData
          } else if (item.type === 'MolliePayment') {
            data = item.mollieGatewayData
          } else if (item.type === 'StripePayment') {
            data = item.stripeGatewayData
            return (
              <>
                <JSONPretty style={{ overflow: 'auto' }} data={data}></JSONPretty>
                {item.tutor?.stripeAccountId && data?.paymentIntentId && (
                  <a
                    href={`https://dashboard.stripe.com/${item.tutor.stripeAccountId}/payments/${data.paymentIntentId}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    payment detail
                  </a>
                )}
              </>
            )
          }

          return <JSONPretty style={{ overflow: 'auto' }} data={data}></JSONPretty>
        }
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return null
    }

    return (
      <Layout>
        <Typography variant='h4'>Profile detail - list of transactions</Typography>
        <UserPagesNavigation title={UserPagesTitles.WalletDetail} profileId={profileId} />
        <ProfileDetail profile={this.state.profile} wallets={this.state.wallets} profileId={profileId} onCreateStripeBusiness={this.handleCreateBusiness} stripeBusinessPending={this.state.stripeBusinessPending} />
        <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={3}>
          <Grid item xs={10}>
            <GenericTable title='Payments' data={this.state.payments} columns={paymentsColumns} />
            <div>
              {!this.state.isLoaded && <CircularProgress size={20} thickness={5} />}
              {this.state.isLoaded && (
                <div style={{ marginTop: 25 }}>
                  <GenericTable
                    title='Transactions'
                    data={this.state.transactions}
                    columns={transactionsColumns}
                    pagination={this.state.pagination}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            {!this.state.isLoaded && <CircularProgress size={20} thickness={5} />}
            {this.state.isLoaded && (
              <GenericForm
                getInitialValues={this.getFeeInitialValues}
                schema={FeeFormSchema}
                validationSchema={feeFormValidationSchema}
                onSubmit={this.handleSubmitFee}
              />
            )}
            <GenericForm
              getInitialValues={this.getInitialValues}
              schema={CreditFormSchema}
              onSubmit={this.handleSubmitGift}
            />
            <GenericForm
              getInitialValues={this.getInitialValues}
              schema={CancelCreditSchema}
              onSubmit={this.handleSubmitCancel}
            />
            <GenericForm
              getInitialValues={this.getPayoutInitialValues}
              schema={TutorPayoutSchema}
              onSubmit={this.handleTutorPayout}
            />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withApollo(withRouter(WalletDetailPage))
