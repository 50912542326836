import gql from 'graphql-tag';

export default gql`
  query adminGetTopics {
    adminGetTopics {
      topic {
        name
        parentId
        slug
        id
      }
      translations {
        id
        lang
        name
        headline
        subheadline
      }
    }
  }
`
