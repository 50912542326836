import { ApolloClient } from "apollo-boost"
import { FormSubmitValues, Operation } from "./AiPricelistsPage"
import { useEffect, useState } from "react"
import NotificationService, { MessageType } from "../../services/NotificationService"
import { getAiPricelists, getAiPricelists_getAiPricelists } from "../../gql/__generated__"
import getAiPricelistsQuery from "../../gql/queries/getAiPricelists"
import { createAiPricelist, deleteAiPricelist, getAiPricelistInput, getInitialValues, updateAiPricelist } from "./util"
import { FormikHelpers } from "formik"

type FormInitialValues = {
  price: number
  minutes: number
  currency: string  
}

const formInitialValues:FormInitialValues = {
  price: 0,
  minutes: 30,
  currency: 'CZK'
}

export function useAiPricelistsPage(client: ApolloClient<any>) {
  const [AiPricelists, setAiPricelists] = useState<getAiPricelists_getAiPricelists[]>([])
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [operation, setOperation] = useState<Operation>('create')

  useEffect(() => {
    fetchAiPricelists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEdit = (aiConfigId: string) => {
    const aiPricelistObject = AiPricelists.find((item) => item.id === aiConfigId)
    if (aiPricelistObject) {
      setOperation('update')
      setInitialValues(getInitialValues(aiPricelistObject))
    }
  }

  const resetState = () => {
    setOperation('create')
    setInitialValues(formInitialValues)    
    fetchAiPricelists()
  }

  const handleSubmit = async (values: FormSubmitValues, actions: FormikHelpers<FormSubmitValues>) => {
    const aiPricelistInput = getAiPricelistInput(values)
    
    try {
      if (operation === 'create') {
        await createAiPricelist(client, aiPricelistInput)
      } else {
        await updateAiPricelist(client, values.id!, aiPricelistInput)
      }      
    } catch (err: any) {
      NotificationService.showError(`AI Pricelist ${operation} action failed`, err)
      return
    }

    resetState()
    actions.resetForm()
    NotificationService.show(MessageType.success, `AI Pricelist ${operation} action successful`)
    
  }

  const handleDelete = async(aiPricelistId: string) => {
    try {
      await deleteAiPricelist(client, aiPricelistId)
    } catch (err: any) {
      NotificationService.showError('Delete of aiPricelist failed', err)
      return
    }
    resetState()
    NotificationService.show(MessageType.success, `AI Pricelist removal successful`)
  }

  const fetchAiPricelists = async () => {
    try {
      const AiPricelists = await client.query<getAiPricelists>({ query: getAiPricelistsQuery })
      setAiPricelists(AiPricelists.data.getAiPricelists)
    } catch (err: any) {
      NotificationService.showError('Fetch of AiPricelists failed', err)
    }
  }

  

  return {
    AiPricelists,
    initialValues,
    operation,
    onEdit,
    handleSubmit,
    handleDelete
  }
}