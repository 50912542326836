import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import adminGetUserStatusHistoryQuery from '../gql/queries/adminGetUserStatusHistory'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient } from 'react-apollo'
import { UserStatusHistory } from '../types'
import { TablePaginationProps } from '@material-ui/core/TablePagination'
import NotificationService from '../services/NotificationService'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'
import { getProfile_getProfileFixed } from '../gql/__generated__'

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profileId: string
  profile: getProfile_getProfileFixed
  isLoaded: boolean
  historyRecords: UserStatusHistory[]
  pagination: TablePaginationProps
}

class OnlineStatusHistoryPage extends React.Component<Props, State> {
  state = {
    profileId: '',
    profile: {} as getProfile_getProfileFixed,
    historyRecords: [],
    isLoaded: false,
    pagination: {
      count: 1000,
      page: 0,
      rowsPerPage: 30,
      rowsPerPageOptions: [30, 50, 100],
      onChangeRowsPerPage: (event: any) => {
        const rowsPerPage = +event.target.value
        this.setState({ isLoaded: false, pagination: { ...this.state.pagination, rowsPerPage, page: 0 } })
        this.getUserStatusHistory(0, rowsPerPage).then(() => {
          this.setState({ isLoaded: true })
        })
      },
      onChangePage: (event: any, page: number) => {
        this.setState({ isLoaded: false, pagination: { ...this.state.pagination, page } })
        this.getUserStatusHistory(page, this.state.pagination.rowsPerPage).then(() => {
          this.setState({ isLoaded: true })
        })
      }
    }
  }

  componentDidMount = async () => {
    await this.setState({ profileId: this.props.match.params.profileId })
    try {
      await this.getProfile()
      await this.getUserStatusHistory(0, this.state.pagination.rowsPerPage)
    } catch (err) {
      NotificationService.showError(err)
    }
    this.setState({ isLoaded: true })
  }

  getProfile = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getProfile,
      variables: {
        id: this.state.profileId
      }
    })
    this.setState({ profile: result.data.getProfileFixed })
  }

  getUserStatusHistory = async (page: number, rowsPerPage: number) => {
    const { client } = this.props
    const result = await client.query({
      query: adminGetUserStatusHistoryQuery,
      variables: {
        userId: this.state.profile.user.id,
        pageInfo: {
          first: rowsPerPage,
          offset: page * rowsPerPage
        }
      }
    })
    this.setState({ historyRecords: result.data.adminGetUserStatusHistory })
  }

  render() {
    const columns = [
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Date and time',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        }
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }

    return (
      <Layout>
        <Typography variant='h4'>Online status history</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.StatusHistory} />
        <ProfileDetail profile={this.state.profile} profileId={profileId} />
        <GenericTable data={this.state.historyRecords} pagination={this.state.pagination} columns={columns} />
      </Layout>
    )
  }
}

export default withApollo(withRouter(OnlineStatusHistoryPage))
