import gql from 'graphql-tag'

export default gql`
  query adminGetConversation($id: String!) {
    adminGetConversation(id: $id, pageInfo: { first: 200, offset: 0 }) {
      id
      messages {
        content
        sent
        fromUserId
        toUserId
        read
        roomId
        attachment {
          ...on AttachmentAiQuery {
            type
            aiReservationId
            aiSettings {
              languageComplexity
              grammarFocus
              conversationTheme
            }            
          }
          ...on AttachmentAiReset {
            type
            aiReservationId
          }
          ...on AttachmentAiStatus {
            type
            statusMessageType
            aiSettingsPropsChanges {
              languageComplexity
              grammarFocus
              conversationTheme
            }
            aiSettingsPropsAffected
            minutes
          }
          ...on AttachmentAiResponse {
            type
            aiReservationId
            responseToId
            tokensPrompt
            tokensCompletion       
            AiError
          }
        }
      }
      members {
        userId
      }
    }
  }
`
