import gql from 'graphql-tag'
import PackageUpdateFragment from '../fragments/packageUpdateFragment'

export default gql`
  mutation PackageUpdateMutation($input: UpdatePackageInput!, $tutorId: String!, $tutorUserId: String!) {
    adminUpdatePackage(input: $input, tutorId: $tutorId, tutorUserId: $tutorUserId) {
      ...PackageUpdateFragment
    }
  }
  ${PackageUpdateFragment}
`
