import React, { useEffect, useState } from 'react'
import { Query, withApollo, WithApolloClient } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { Alert, GenericForm, GenericTable } from '../components'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import AdminGetTutorProps from '../gql/queries/adminGetTutorProps'
import ProfileDetail from '../components/ProfileDetail'
import SendNewPassword from '../components/SendNewPassword'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography, CardContent, Card, CircularProgress, Button, Grid } from '@material-ui/core'
import EnableStripeBtn from '../components/EnableStripeBtn'
import PublishBtn from '../components/PublishBtn'
import ActivateBtn from '../components/ActivateBtn'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { TutorProps } from '../types'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { formatDate } from '../Utils'
import verifyEmailContact from '../gql/mutations/verifyEmailContact'
import NotificationService from '../services/NotificationService'
import { ContactType, adminUpdateStudentFreeAiUsage, adminUpdateStudentFreeAiUsageVariables, getProfile_getProfileFixed, getProfile_getProfileFixed_Student } from '../gql/__generated__'
import FreeAiUsageFormSchema, {FreeAiUsageForm} from './formSchemas/FreeAiUsageSchema'
import adminUpdateStudentFreeAiUsageMutation from '../gql/mutations/adminUpdateStudentFreeAiUsage'

const styles = {
  card: {
    marginBottom: 20
  },
  cell: {
    border: 'none'
  },
  grid: {
    margin: '20px'
  }
}

interface Data {
  getProfileFixed: any
}

class GetProfileQuery extends Query<Data> {}

type Props = WithApolloClient<{}> &
  WithStyles<typeof styles> &
  RouteComponentProps<{
    profileId: string
  }>

const ProfileDetailPage = ({ client, classes, match }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const profileId = match.params.profileId
  const [tutorProps, setTutorProps] = useState<TutorProps>()
  const [profile, setProfile] = useState<getProfile_getProfileFixed>()
  let profileType = 'Tutor'
  if (profileId.startsWith('ST')) {
    profileType = 'Student'
  }

  useEffect(() => {
    profileType === 'Tutor' && getTutorProps()
    getProfileInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileType])

  const getProfileInfo = async () => {
    setIsLoaded(false)
    const result = await client.query({
      query: getProfile,
      variables: {
        id: profileId
      }
    })
    setProfile(result.data.getProfileFixed)
    setIsLoaded(true)
  }

  const getTutorProps = async () => {
    setIsLoaded(false)
    const result = await client.query({
      query: AdminGetTutorProps,
      variables: {
        tutorId: profileId
      }
    })
    setTutorProps(result.data.adminGetTutorProps)
    setIsLoaded(true)
  }

  const getInitialFreeUsage = (data: getProfile_getProfileFixed_Student) => {    
    return {
      freeAiUsage: data.freeAiUsage 
    }
  }

  const saveFreeUsage = async (values: FreeAiUsageForm) => {
    try {
      await client.mutate<adminUpdateStudentFreeAiUsage, adminUpdateStudentFreeAiUsageVariables>({
        mutation: adminUpdateStudentFreeAiUsageMutation,
        variables: {
          studentId: profileId,
          freeAiUsage: parseInt(values.freeAiUsage)
        }
      })
      NotificationService.showSuccess('Free AI usage updated')
    } catch (err) {
      NotificationService.showError('Cannot update free AI usage')
    }
  }

  const getStatus = (status: boolean) => {
    if (status) {
      return <CheckIcon />
    } else {
      return <CloseIcon color='error' />
    }
  }

  const omitTypeName = ({ __typename, ...rest }: any) => rest

  const handleClick = async (email: string) => {
    try {
      await client.mutate({
        mutation: verifyEmailContact,
        variables: {
          email,
          userId: profileId
        }
      })
      window.location.reload()
    } catch (err) {
      NotificationService.showError('Cannot verify contact')
    }
  }

  const columns = [
    {
      Header: 'type',
      accessor: '__typename'
    },
    {
      Header: 'contact',
      accessor: (item: any) => JSON.stringify(omitTypeName(item))
    },
    {
      Header: 'verified',
      accessor: (item: any) => {
        if (item.__typename === ContactType.Email && !item.verifiedAt) {
          return (
            <Button onClick={() => handleClick(item.email)} color='primary' variant='contained' size='small'>
              Verify
            </Button>
          )
        }
        return formatDate(item.verifiedAt)
      }
    }
  ]

  return (
    <Layout>
      <Typography variant='h4'>Profile detail - overview</Typography>
      <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Overview} />
      <GetProfileQuery query={getProfile} variables={{ id: profileId }}>
        {({ data, loading, error }) => {
          return (
            <div>
              {loading && <CircularProgress size={20} thickness={5} />}
              {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
              {!loading && data && (
                <>
                  <ProfileDetail profile={data.getProfileFixed} profileId={profileId} />
                  <Card className={classes.card}>
                    <CardContent>
                      {profileType === 'Tutor' && (
                        <>
                          <ActivateBtn client={client} tutorId={profileId} isActive={data.getProfileFixed.isActive} />
                          <PublishBtn client={client} tutorId={profileId} publish={data.getProfileFixed.publish} />
                          <Button color='primary' variant='contained' href={`/tutor/${profileId}`}>
                            Edit
                          </Button>
                          <Button color='primary' variant='contained' href={`/invite/${profileId}`}>
                            Invite student
                          </Button>
                          <EnableStripeBtn profile={data.getProfileFixed} />
                        </>
                      )}                      
                      <SendNewPassword user={data.getProfileFixed.user} />
                    </CardContent>                    
                  </Card>
                  {profileType === 'Student' && isLoaded && (
                  <Grid container spacing={2}>
                    <Grid item xs={3}>   
                      <GenericForm
                        key={profileId}
                        submitBtnTitle='Save'
                        getInitialValues={getInitialFreeUsage(data.getProfileFixed)}
                        schema={FreeAiUsageFormSchema}
                        onSubmit={saveFreeUsage}
                      />
                    </Grid>
                    </Grid>
                  )} 
                </>
              )}
            </div>
          )
        }}
      </GetProfileQuery>
      {isLoaded ? (
        <>
          {profileType === 'Tutor' && (
            <Card className={classes.card}>
              <CardContent>
                <Typography variant='h4'>Completion status</Typography>
                <Grid className={classes.grid} container direction='row' spacing={2}>
                  <Grid item xs={1}>
                    Profile
                  </Grid>
                  <Grid item xs={1}>
                    Lesson
                  </Grid>
                  <Grid item xs={1}>
                    Stripe
                  </Grid>
                  <Grid item xs={1}>
                    Billing
                  </Grid>
                  
                </Grid>
                <Grid className={classes.grid} container direction='row' spacing={2}>
                  <Grid item xs={1}>
                    {tutorProps && getStatus(tutorProps.completeProfile)}
                  </Grid>
                  <Grid item xs={1}>
                    {tutorProps && getStatus(tutorProps.oneCompletePackage)}
                  </Grid>
                  <Grid item xs={1}>
                    {tutorProps && getStatus(tutorProps.connectedStripeAccount)}
                  </Grid>
                  <Grid item xs={1}>
                    {tutorProps && getStatus(tutorProps.completeBilling)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {profile && <GenericTable data={profile.user.contacts} columns={columns} />}
        </>
      ) : (
        <CircularProgress size={20} thickness={5} />
      )}
    </Layout>
  )
}

export default withStyles(styles)(withApollo(withRouter(ProfileDetailPage)))
