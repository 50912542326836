import gql from 'graphql-tag'

export default gql`
  query StudentsPage($first: Int = 9999, $offset: Int = 0) {
    findStudents(pageInfo: { first: $first, offset: $offset }) {
      id
      user {
        id
        firstName
        lastName
        credentials {
          identifier
          type
        }
      }
    }
  }
`
