import { FormikDatePicker, FormSchema } from '../../components'

const initialValues = {
  amount: 0.01,
  expiresAt: null,
  comment: ''
}

const CreditFormSchema: FormSchema = {
  fieldsets: [
    {
      title: 'Gift credit ($)',
      fields: [
        {
          label: 'Gift credits($)',
          accessor: 'amount'
        },
        {
          label: 'Expires at',
          accessor: 'expiresAt',
          component: FormikDatePicker,
          fieldProps: {
            disableFuture: false,
            disablePast: true
          }
        },
        {
          label: 'comment',
          accessor: 'comment'
        }
      ]
    }
  ]
}

export default CreditFormSchema

export { initialValues }
