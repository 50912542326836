import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks'
import adminListGroups from '../gql/queries/adminListGroups'
import { Typography } from '@material-ui/core'
import { GenericTable } from '../components'
import Layout from '../layouts/Layout'
import { formatDate } from '../Utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { adminListGroups_adminListGroups } from '../gql/__generated__'
import ButtonLink from '../components/ButtonLink'

type Props = WithApolloClient<{}>

const GroupListPage = ({ client }: Props) => {
  const { data, loading } = useQuery<{ adminListGroups: adminListGroups_adminListGroups[] }>(adminListGroups, {
    client
  })

  const columns = [
    {
      Header: 'id',
      accessor: 'id'
    },
    {
      Header: 'Name',
      accessor: 'name',
      link: (item: any) => `groups/${item.id}`
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'CreatedAt',
      accessor: (item: any) => {
        return formatDate(item.createdAt)
      }
    }
  ]

  if (loading) {
    return (
      <Layout>
        <CircularProgress size={20} thickness={5} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant='h4'>Groups</Typography>
      <ButtonLink link='/groups/create'>Create Group</ButtonLink>
      {data && <GenericTable title='Groups' data={data.adminListGroups} columns={columns} />}
    </Layout>
  )
}

export default withApollo(GroupListPage)
