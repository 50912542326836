import gql from 'graphql-tag'

export default gql`
  query TutorsPage($pageInfo: PageInfo) {
    adminGetTutorTotalCount
    getTutors(pageInfo: $pageInfo) {
      id
      user {
        id
        firstName
        lastName
        credentials {
          identifier
          type
        }
        contacts {
          id
          ... on Stripe {
            accountId
            payouts_enabled
            charges_enabled
          }
        }
      }
      language
      balance
      isActive
      slug
      enabledPayments
      stripeAccountId
      publish
    }
  }
`
