import { GenericTable } from './GenericTable'
import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import {
  adminGetPurchasedPackages_adminGetPurchasedPackages as Purchase,
  getPackages_getPackages as Package,
  adminGetAllCourses_adminGetAllCourses as Course,
  adminMarkPurchaseUsed,
  adminMarkPurchaseUsedVariables
} from '../gql/__generated__'
import adminGetPurchasedPackages from '../gql/queries/adminGetPurchasedPackages'
import { formatDate } from '../Utils'
import getPackagesQuery from '../gql/queries/getPackages'
import { GenericForm } from './GenericForm'
import PurchasePackageSchema, { initialValues } from '../pages/formSchemas/PurchasePackageSchema'
import NotificationService from '../services/NotificationService'
import purchasePackageWithTransaction from '../gql/mutations/purchasePackageWithTransaction'
import adminMarkPurchaseUsedMutation from '../gql/mutations/adminMarkPurchaseUsed'
import { Button, CircularProgress } from '@material-ui/core'

type Props = WithApolloClient<{}> & {
  profileId: string
  userId: string
  allCourses: Course[]
}

type State = {
  isLoaded: boolean
  purchasedPackages: Purchase[]
  packages: Package[]
  purchaseInProgress: { [key: string]: boolean }  
}

class StudentPackagesContent extends React.Component<Props, State> {
  state: State = {
    purchasedPackages: [],
    isLoaded: false,
    packages: [],
    purchaseInProgress: {}
  }

  componentDidMount = async () => {
    await Promise.all([this.getPurchasedPackages(), this.getPackages()]).catch((err: any) =>
      NotificationService.showError(err.toString())
    )
    await this.setState({ isLoaded: true })
  }

  getPackages = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getPackagesQuery
    })
    this.setState({ packages: result.data.getPackages })
  }

  getPurchasedPackages = async () => {
    const { client, profileId } = this.props
    const result = await client.query({
      query: adminGetPurchasedPackages,
      variables: { studentId: profileId }
    })
    this.setState({ purchasedPackages: result.data.adminGetPurchasedPackages })
  }

  getInitialValues = () => initialValues

  handleSubmit = async (values: any) => {
    const { client, profileId, userId } = this.props
    await client.mutate({
      mutation: purchasePackageWithTransaction,
      variables: {
        packageId: values.packageId,
        profileId,
        userId
      }
    })
    NotificationService.showSuccess('Package successfully purchased')
    window.location.reload()
  }

  handleMarkUsed = async (purchaseId: string) => {
    if(window.confirm('Are you sure you want to mark this package as used? This can have irreversible consequences.') === false) return
    const { client } = this.props
    this.setState({ purchaseInProgress: { ...this.state.purchaseInProgress, [purchaseId]: true } })
    const result = await client.mutate<adminMarkPurchaseUsed, adminMarkPurchaseUsedVariables>({
      mutation: adminMarkPurchaseUsedMutation,
      variables: { purchaseId }
    })
    if (result.data) {
      NotificationService.showSuccess('Package marked as used')      
      window.location.reload()
    }
  }

  render() {
    const columns = [
      {
        Header: 'purchaseId',
        accessor: 'id'
      },
      {
        Header: 'tutorId',
        accessor: 'tutorId'
      },
      {
        Header: 'packageId',
        accessor: (item: any) => item.package.id
      },
      {
        Header: 'name',
        accessor: (item: any) => item.package.name
      },
      {
        Header: 'course',
        accessor: (item: any) => { 
          if(item.package.courseId) {
            const courseId = item.package.courseId
            const courseName = this.props.allCourses.find((course: Course) => course.course.id === courseId)
              ?.translations.filter((tr) => tr.lang === 'cs')[0]?.name
            return (
              <a href={`/courses/edit/${courseId}`} target='_blank' rel='noreferrer'>
                {courseName}
              </a>
            )
          } else {
            return '-'
          }
        }
      },
      {
        Header: 'Purchase date',
        accessor: (item: any) => formatDate(item.createdAt)
      },
      {
        Header: 'Used/Booked/Total lessons',
        accessor: (item: any) => {
          const booked = item.usages.filter((u: any) => u.bookedAt).length
          const used = item.usages.filter((u: any) => u.usedAt).length

          return `${used}/${booked}/${item.package.numOfLessons}`
        }
      },
      {
        Header: 'Mark Used',
        accessor: (item: any) => {
          if (this.state.purchaseInProgress[item.id as string]) {
            return <CircularProgress size={10} thickness={5} />
          }
          const remainingLessons = item.usages.filter((u: any) => u.bookedAt).length - item.usages.filter((u: any) => u.usedAt).length
          if (remainingLessons > 0) {
            return <Button size='small' onClick={() => this.handleMarkUsed(item.id)}>Mark used</Button>
          }
        },        
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ]

    if (!this.state.isLoaded) {
      return null
    }

    return (
      <>
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={PurchasePackageSchema(this.state.packages)}
          onSubmit={this.handleSubmit}
        />
        <GenericTable data={this.state.purchasedPackages} columns={columns} />
      </>
    )
  }
}

export default withApollo(StudentPackagesContent)
