import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import getRatingsQuery from '../gql/queries/getRatings'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import ProfileDetail from '../components/ProfileDetail'
import { formatDate } from '../Utils'
import { withApollo, WithApolloClient, Query } from 'react-apollo'
import Button from '@material-ui/core/Button'
import setVisibilityMutation from '../gql/mutations/setVisibility'
import setRatingIgnoredMutation from '../gql/mutations/setRatingIgnored'
import NotificationService from '../services/NotificationService'
import { Rating } from '../types'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import { Typography } from '@material-ui/core'

interface Data {
  getRatings: any
  getProfileFixed: any
}

class GetProfileQuery extends Query<Data> {}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  ratings: Rating[]
  isLoaded: boolean
}

class RatingsPage extends React.Component<Props, State> {
  state = {
    ratings: [],
    isLoaded: false
  }

  componentDidMount = async () => {
    try {
      await this.getRatings()
      this.setState({ isLoaded: true })
    } catch (err) {
      NotificationService.showError('Cannot get ratings')
    }
  }

  getRatings = async () => {
    const { client, match } = this.props
    const result = await client.query({
      query: getRatingsQuery,
      variables: { profileId: match.params.profileId }
    })
    this.setState({ ratings: result.data.getRatings })
    return result.data.getRatings
  }

  setVisibility = async (id: string, visible: boolean) => {
    const { client } = this.props
    await client.mutate({
      mutation: setVisibilityMutation,
      variables: {
        id,
        visible
      }
    })
  }

  setRatingIgnored = async (id: string, ignored: boolean) => {
    const { client } = this.props
    await client.mutate({
      mutation: setRatingIgnoredMutation,
      variables: {
        id,
        ignored
      }
    })
  }

  handleClickVisible = async (id: string, visible: boolean) => {
    try {
      await this.setVisibility(id, visible)
      NotificationService.showSuccess('done')
      await this.getRatings()
    } catch (err) {
      NotificationService.showError(err.message)
    }
  }

  handleClickIgnored = async (id: string, ignored: boolean) => {
    try {
      await this.setRatingIgnored(id, ignored)
      NotificationService.showSuccess('done')
      await this.getRatings()
    } catch (err) {
      NotificationService.showError(err.message)
    }
  }

  render() {
    const columns = [
      {
        Header: 'ratingTotal',
        accessor: 'ratingTotal'
      },
      {
        Header: 'givingProfileId',
        accessor: 'givingProfileId',
        link: (item: any) => `/profile/${item.givingProfileId}`
      },
      {
        Header: 'createdAt',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        }
      },
      {
        Header: 'comment',
        accessor: 'comment'
      },
      {
        Header: 'visibility',
        accessor: (item: any) => {
          if (item.visible) {
            return (
              <Button
                color='secondary'
                variant='contained'
                size='small'
                onClick={async () => await this.handleClickVisible(item.id, false)}
              >
                Hide
              </Button>
            )
          }
          return (
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={async () => await this.handleClickVisible(item.id, true)}
            >
              Make visible
            </Button>
          )
        }
      },
      {
        Header: 'ignored',
        accessor: (item: any) => {
          if (item.ignored) {
            return (
              <Button
                color='primary'
                variant='contained'
                size='small'
                onClick={async () => await this.handleClickIgnored(item.id, false)}
              >
                Count in
              </Button>
            )
          }
          return (
            <Button
              color='secondary'
              variant='contained'
              size='small'
              onClick={async () => await this.handleClickIgnored(item.id, true)}
            >
              Ignore
            </Button>
          )
        }
      },
      {
        Header: 'edit',
        accessor: (item: any) => 'Edit comment',
        link: (item: any) => `/rating/${item.id}`
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return (
        <Layout>
          <CircularProgress size={20} thickness={5} />
        </Layout>
      )
    }
    return (
      <Layout>
        <Typography variant='h4'>Profile detail - list of ratings</Typography>
        <UserPagesNavigation profileId={profileId} title={UserPagesTitles.Ratings} />
        <GetProfileQuery query={getProfile} variables={{ id: profileId }}>
          {({ data, loading, error }) => {
            return (
              <div>
                {loading && <CircularProgress size={20} thickness={5} />}
                {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
                {!loading && data && (
                  <div>
                    <ProfileDetail profile={data.getProfileFixed} profileId={profileId} />
                  </div>
                )}
              </div>
            )
          }}
        </GetProfileQuery>
        <div>
          <GenericTable data={this.state.ratings} columns={columns} />
        </div>
      </Layout>
    )
  }
}

export default withApollo(withRouter(RatingsPage))
