import React, { useEffect } from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { RouteComponentProps } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Layout from '../../layouts/Layout'
import NotificationService from '../../services/NotificationService'
import { GenericTable, RouterLink } from '../../components'
import { Button } from '@material-ui/core'

import { 
  adminGetPackagesForCourseId_adminGetPackagesForCourseId as Package,
  adminGetPackagesForCourseId,
  adminGetPackagesForCourseIdVariables  
} from '../../gql/__generated__'
import adminGetPackagesForCourseIdQuery from '../../gql/queries/adminGetPackagesForCourseId'

type Params = {  
  courseId: string  
}

type Props = WithApolloClient<{}> & RouteComponentProps<Params>

const CoursePackages = ({ client, match }: Props) => { 
  const [packages, setPackages] = React.useState<Package[]>([])
  const { courseId } = match.params
  
  const fetchPackages = async () => {
    try {
      const result = await client.query<
        adminGetPackagesForCourseId,
        adminGetPackagesForCourseIdVariables
      >({
        query: adminGetPackagesForCourseIdQuery,
        variables: { courseId }
       })
      setPackages(result.data.adminGetPackagesForCourseId)
    } catch(err: any) {
      NotificationService.showError('Fetch of course packages failed', err)  
    }
  }

  useEffect(() => {
    fetchPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { 
      Header: 'Package ID',
      accessor: 'id'
    },
    {
      Header: 'Name',
      accessor: 'name',      
    },
    {
      Header: 'Tutor ID',
      accessor: 'tutorId',
      link: (row: Package) => `/profile/${row.tutorId}`,
    },
    {
      Header: 'Active', 
      accessor: (row: Package) => row.active ? 'Yes' : 'No'
    },
    {
      Header: 'Visibility', 
      accessor: (row: Package) => row.globalVisibility
    },
    {
      Header: 'Edit',
      accessor: () => 'Edit',
      link: (row: Package) => `/package/${row.id}`,       
    }
   

  ]

  return (
    <Layout>
      <Typography gutterBottom={true} variant='h4'>
        Course Packages
        <RouterLink to={'/courses/'}>
          <Button color='secondary' style={{ float: 'right' }} variant='contained'>
            Back to courses
          </Button>
        </RouterLink>
      </Typography>      
      <Typography style={{ marginBottom: '2rem'}} variant='h6'>
        Course ID: {courseId}
      </Typography> 
      <GenericTable data={packages} columns={columns} />     
    </Layout>
  )
}

export default withApollo(CoursePackages)
