import InputAdornment from '@material-ui/core/InputAdornment'
import React from 'react'
import * as Yup from 'yup'

import { GroupRole, GroupUserState } from '../../gql/__generated__'
import { FormSchema } from '../../components'

export type Values = {
  firstName: string
  lastName: string
  identifier: string
  department: string
  groupRole: string //GroupRole
  state: string //GroupUserState
  walletOptions: {
    coverage: number
    monthlyLimit: number
  }
}

const initialValues = {
  department: '',
  firstName: '',
  groupRole: GroupRole.groupAdmin,
  identifier: '',
  lastName: '',
  state: GroupUserState.Active,
  walletOptions: {
    coverage: 100,
    monthlyLimit: 0
  }
}

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  identifier: Yup.string().required('Email is required').email('Email is not valid'),
  groupRole: Yup.string().required('Role is required'),
  state: Yup.string().required('State is required'),
  walletOptions: Yup.object().shape({
    coverage: Yup.number()
      .typeError('Please enter number')
      .min(0, 'Minimum value is 0')
      .max(100, 'Maximum value is 100')
      .required('Coverage is required'),
    monthlyLimit: Yup.number()
      .typeError('Please enter number')
      .min(0, 'Minimum value is 0')
      .required('Monthly limit is required')
  })
})

const GroupMemberSchema: FormSchema = {
  fieldsets: [
    {
      fields: [
        {
          label: 'Role',
          accessor: 'groupRole',
          fieldProps: {
            select: true,
            selectOptions: [
              { value: GroupRole.groupAdmin, label: 'Administrator' },
              { value: GroupRole.groupManager, label: 'Manager' },
              { value: GroupRole.groupUser, label: 'User' }
            ]
          }
        },
        {
          label: 'First name',
          accessor: 'firstName'
        },
        {
          label: 'Last name',
          accessor: 'lastName'
        },
        {
          label: 'E-mail',
          accessor: 'identifier',
          fieldProps: {
            type: 'email'
          }
        },
        {
          label: 'Department',
          accessor: 'department'
        },
        {
          label: 'Monthly limit',
          accessor: 'walletOptions.monthlyLimit',
          fieldProps: {
            InputProps: {
              endAdornment: <InputAdornment position='end'>USD</InputAdornment>
            },
            min: 0,
            type: 'number'
          }
        },
        {
          label: 'Coverage',
          accessor: 'walletOptions.coverage',
          fieldProps: {
            select: true,
            selectOptions: [25, 33, 50, 66, 100].map((perc) => ({ value: perc, label: `${perc} %` }))
          }
        },
        {
          label: 'State',
          accessor: 'state',
          fieldProps: {
            select: true,
            selectOptions: [
              { value: GroupUserState.Active, label: 'Active' },
              { value: GroupUserState.NotRenew, label: 'Active, do not renew' },
              { value: GroupUserState.Stopped, label: 'Inactive' }
            ]
          }
        }
      ]
    }
  ]
}

export default GroupMemberSchema

export { initialValues }
