import React from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { Alert, GenericTable } from '../components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import getProfile from '../gql/queries/getProfile'
import getVideoPreviewsByTutorId from '../gql/queries/getVideoPreviewsByTutorId'
import ProfileDetail from '../components/ProfileDetail'
import { GenericForm } from '../components/GenericForm'
import { formatDate } from '../Utils'
import { withApollo, Query, WithApolloClient } from 'react-apollo'
import NotificationService, { MessageType } from '../services/NotificationService'
import { User, VideoPreview } from '../types'
import VideoPreviewSchema, { initialValues } from './formSchemas/VideoPreviewSchema'
import adminCreatePreview from '../gql/mutations/adminCreatePreview'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import adminDeletePreview from '../gql/mutations/adminDeletePreview'
import UserPagesNavigation from '../components/UserPagesNavigation'
import { UserPagesTitles } from '../enums'
import Typography from '@material-ui/core/Typography'

interface Data {
  getVideoPreviewsByTutorId: any
  getProfileFixed: any
}

class GetProfileQuery extends Query<Data> {}

type Props = WithApolloClient<{}> &
  RouteComponentProps<{
    profileId: string
    data: any
  }>

interface State {
  profileId: string
  user: User
  isLoaded: boolean
  previews: VideoPreview[]
}

class PreviewsPage extends React.Component<Props, State> {
  state = {
    profileId: '',
    user: {
      id: '',
      credentials: [],
      firstName: '',
      lastName: ''
    },
    previews: [],
    isLoaded: false
  }

  componentDidMount = async () => {
    await this.setState({ profileId: this.props.match.params.profileId })
    await this.getData()
    await this.setState({ isLoaded: true })
  }

  getData = async () => {
    await Promise.all([this.getProfile(), this.getPreviews()])
  }

  getProfile = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getProfile,
      variables: {
        id: this.state.profileId
      }
    })
    await this.setState({ user: result.data.getProfileFixed.user })
  }

  getPreviews = async () => {
    const { client } = this.props
    const result = await client.query({
      query: getVideoPreviewsByTutorId,
      variables: {
        tutorId: this.state.profileId
      }
    })
    this.setState({ previews: result.data.getVideoPreviewsByTutorId })
  }

  handleSubmit = async (values: any) => {
    const profileId = this.props.match.params.profileId
    const { client } = this.props
    await client.mutate({
      mutation: adminCreatePreview,
      variables: {
        videoPreviewInput: {
          tutorId: profileId,
          fileName: values.fileName
        }
      }
    })
    NotificationService.show(MessageType.success, 'Video preview added successfully')
    this.getData()
    return true
  }

  handleDelete = async (id: string) => {
    const { client } = this.props
    try {
      await client.mutate({
        mutation: adminDeletePreview,
        variables: { id }
      })
      await this.getPreviews()
      NotificationService.showSuccess('Video preview deleted')
    } catch (err) {
      NotificationService.showError(err.message)
    }
  }

  getInitialValues = () => initialValues

  render() {
    const columns = [
      {
        Header: 'File name or link',
        accessor: (item: any) => {
          return item.fileName.includes('http') ? <a href={item.fileName}>{item.fileName}</a> : item.fileName
        }
      },
      {
        Header: 'Created date',
        accessor: (item: any) => {
          return formatDate(item.createdAt)
        }
      },
      {
        Header: 'Delete',
        accessor: (item: any) => {
          return (
            <IconButton onClick={() => this.handleDelete(item.id)}>
              <DeleteIcon />
            </IconButton>
          )
        }
      }
    ]

    const profileId = this.props.match.params.profileId

    if (!this.state.isLoaded) {
      return null
    }

    return (
      <Layout>
        <Typography variant='h4'>Video previews</Typography>
        <UserPagesNavigation title={UserPagesTitles.Previews} profileId={profileId} />
        <GetProfileQuery query={getProfile} variables={{ id: profileId }}>
          {({ data, loading, error }) => {
            return (
              <div>
                {loading && <CircularProgress size={20} thickness={5} />}
                {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
                {!loading && data && (
                  <div>
                    <ProfileDetail profile={data.getProfileFixed} profileId={profileId} />
                  </div>
                )}
              </div>
            )
          }}
        </GetProfileQuery>
        <GenericForm
          getInitialValues={this.getInitialValues}
          schema={VideoPreviewSchema}
          onSubmit={this.handleSubmit}
        />
        <div>
          <GenericTable data={this.state.previews} columns={columns} />
        </div>
      </Layout>
    )
  }
}

export default withApollo(withRouter(PreviewsPage))
