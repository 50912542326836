import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import defaultTo from 'lodash/defaultTo'
import { withApollo, WithApolloClient } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import React, { useMemo } from 'react'

import {
  GroupDetail as GroupDetailQuery,
  GroupDetailStudent,
  GroupDetailVariables,
  GroupRole,
  GroupUserState
} from '../gql/__generated__'
import Layout from '../layouts/Layout'
import QUERY from '../gql/queries/groupDetailQuery.api'
import { Alert } from '../components/Alert'
import { formatPrice, formatPriceAmount, makePrice } from '../utils/price'
import formatNumber from '../utils/formatNumber'
import { GenericTable, RouterLink } from '../components'
import { sortGroupStudents } from './groupDetailPage/Utils'
import Button from '@material-ui/core/Button'

type Props = WithApolloClient<RouteComponentProps<Params>>

type Params = {
  groupId: string
}

const stateTexts = {
  [GroupUserState.Active]: 'Active',
  [GroupUserState.NotRenew]: 'Active, do not renew',
  [GroupUserState.Stopped]: 'Inactive'
}

const roleTexts = {
  [GroupRole.groupAdmin]: 'Admin',
  [GroupRole.groupManager]: 'Manager',
  [GroupRole.groupUser]: 'User'
}

const studentListColumns = [
  {
    Header: 'User ID',
    accessor: 'id'
  },
  {
    Header: 'Name',
    accessor: (student: GroupDetailStudent) =>
      `${student.firstName || ''} ${student.lastName || ''}`.trim() || '(user has no name)'
  },
  {
    Header: 'E-mail',
    accessor: 'identifier'
  },
  {
    Header: 'Role',
    accessor: (student: GroupDetailStudent) => (student.groupRole ? roleTexts[student.groupRole] : null)
  },
  {
    Header: 'Department',
    accessor: 'department'
  },
  {
    Header: 'Used credit / limit',
    accessor: (student: GroupDetailStudent) => {
      const monthlyUsage =
        student.wallet &&
        student.wallet.balance != null &&
        student.wallet.options &&
        student.wallet.options.monthlyLimit != null
          ? formatPriceAmount(makePrice(student.wallet.options.monthlyLimit - student.wallet.balance))
          : null
      const monthlyLimit =
        student.wallet && student.wallet.options && student.wallet.options.monthlyLimit != null
          ? formatPrice(makePrice(student.wallet.options.monthlyLimit), { international: true })
          : null

      return `${defaultTo(monthlyUsage, '–')} / ${defaultTo(monthlyLimit, '–')}`
    }
  },
  {
    Header: 'Coverage',
    accessor: (student: GroupDetailStudent) =>
      student.wallet && student.wallet.options && student.wallet.options.coverage != null
        ? `${formatNumber(student.wallet.options.coverage, 0)}\u00A0%`
        : null
  },
  {
    Header: 'Status',
    accessor: (student: GroupDetailStudent) => (student.state ? stateTexts[student.state] : null)
  }
]

const GroupDetailPage = ({ client, match }: Props) => {
  const {
    params: { groupId }
  } = match
  const { data, loading, error } = useQuery<GroupDetailQuery, GroupDetailVariables>(QUERY, {
    client,
    variables: {
      groupId
    }
  })
  const { group = null, students = [] } = data || {}
  const loaded = !loading && !error
  const sortedStudents = useMemo(() => sortGroupStudents(students), [students])

  return (
    <Layout>
      {loading && <CircularProgress size={20} thickness={5} />}
      {!loading && error && <Alert>{JSON.stringify(error)}</Alert>}
      {loaded && group && (
        <>
          <Box display='flex' justifyContent='space-between' mb={4}>
            <Typography variant='h4'>{group.name}</Typography>
            <Box ml={2}>
              <RouterLink to={`/groups/${groupId}/add`}>
                <Button color='secondary' variant='contained'>
                  Add user
                </Button>
              </RouterLink>
              <Box display='inline-block' ml={1}>
                <RouterLink to='/groups'>
                  <Button variant='contained'>Back to groups</Button>
                </RouterLink>
              </Box>
            </Box>
          </Box>
          <GenericTable data={sortedStudents} columns={studentListColumns} />
        </>
      )}
      {loaded && !group && <Alert>Group not found</Alert>}
    </Layout>
  )
}

export default withApollo(withRouter(GroupDetailPage))
