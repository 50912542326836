const themeConfig = {
  typography: {
    useNextVariants: true,
    fontWeightRegular: 300,
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.5rem'
    },
    h3: {
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.6rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300
    }
  },
  shape: {
    borderRadius: 3
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontWeight: 300
      }
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          background: 'none'
        }
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderRadius: 3
      }
    },
    MuiOutlinedInput: {
      root: {
        '& > $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.1)'
        },
        '&$disabled > $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.1)'
        }
      },
      input: {
        padding: '13px 16px'
      }
    },
    MuiInputLabel: {
      outlined: {
        fontWeight: 300,
        transform: 'translate(14px, 15px) scale(1)'
      }
    },
    MuiButton: {
      root: {
        margin: 1
      },
      sizeLarge: {
        height: 45
      },
      contained: {
        boxShadow: 'none'
      }
    }
  }
}

export default themeConfig
