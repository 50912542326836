import gql from 'graphql-tag'

export default gql`
  query adminGetActiveSubscriptions($userId: String!) {
    adminGetActiveSubscriptions(type: TutorWentOnline, userId: $userId) {
      id
      subscribedToProfileId
      createdAt
      profile {
        user {
          firstName
          lastName
        }
      }
    }
  }
`
