import React, { ChangeEvent, FormEvent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core'
import { withApollo, WithApolloClient } from 'react-apollo'
import resolveId from '../gql/queries/resolveId'
import NotificationService from '../services/NotificationService'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton/index'
import SearchIcon from '@material-ui/icons/Search'

const styles: StyleRulesCallback<any, any> = (theme) => ({
  input: {
    margin: '10px',
    backgroundColor: theme.palette.primary.light,
    width: 300
  },
  inputText: {
    padding: 10
  }
})

type Props = RouteComponentProps<{}> & WithStyles & WithApolloClient<{}>

class Search extends React.Component<Props> {
  state = {
    searchFieldValue: ''
  }

  handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const { client } = this.props
    let result
    try {
      result = await client.query({
        query: resolveId,
        variables: { id: this.state.searchFieldValue }
      })
    } catch (err) {
      NotificationService.showError('Search failed')
    }

    if (result && result.data && result.data.resolveId) {
      if (result.data.resolveId.errorMessage) {
        NotificationService.showWarning(result.data.resolveId.errorMessage)
        return
      }

      switch (result.data.resolveId.type) {
        case 'Student':
        case 'Tutor': {
          this.props.history.push(`/profile/${result.data.resolveId.id}`)
          break
        }
        default: {
          NotificationService.showError('No such type implemented')
        }
      }
    } else {
      NotificationService.showInfo(`No results for ${this.state.searchFieldValue}`)
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchFieldValue: event.target.value })
  }

  render() {
    const { classes } = this.props
    return (
      <form onSubmit={this.handleSubmit} style={{ display: 'flex' }}>
        <Input
          placeholder='Search'
          className={classes.input}
          classes={{ input: classes.inputText }}
          inputProps={{
            'aria-label': 'Description'
          }}
          type='search'
          onChange={this.handleChange}
        />
        <IconButton color='inherit' onClick={this.handleSubmit}>
          <SearchIcon />
        </IconButton>
      </form>
    )
  }
}

export default withStyles(styles)(withRouter(withApollo(Search)))
