import gql from "graphql-tag";

export default gql`
  mutation adminCreateStripeBusinessAccount($userId: String!, $tutorId: String!) {
    adminCreateStripeBusinessAccount(userId: $userId, tutorId: $tutorId) {
      id
      account {
        id
        business_type
        country
      }
      error {
        type
        code
        decline_code
        param
        message
      }       
    }
  }
`