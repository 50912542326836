import gql from 'graphql-tag'

export default gql`
  query googleLogin($code: String!, $isLocal: Boolean) {
    googleLogin(code: $code, isLocal: $isLocal) {
      token
      expiresAt
    }
  }
`
